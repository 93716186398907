import { createRouter, createWebHashHistory } from 'vue-router'

// import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import BulletinView from '@/views/BulletinView.vue';
import BulletinsListView from '@/views/BulletinsListView.vue';
import UsersView from '@/views/UsersView.vue';
import UserChangeView from '@/views/UserChangeView.vue';

import AddBulletinView from '@/views/admin/AddBulletinView.vue';
import DeleteBulletinView from '@/views/admin/DeleteBulletinView.vue';

import IzPatentView from '@/views/patents/IzPatentView.vue';
import PmPatentView from '@/views/patents/PmPatentView.vue';
import PoPatentView from '@/views/patents/PoPatentView.vue';
import NmptPatentView from '@/views/patents/NmptPatentView.vue';
import TzPatentView from '@/views/patents/TzPatentView.vue';
import SdPatentView from '@/views/patents/SdPatentView.vue';

import EkspTzPatentView from '@/views/eksp/EkspTzPatentView.vue';
import EkspSdPatentView from '@/views/eksp/EkspSdPatentView.vue';

// 

import AddUkAuthorIZView from '@/views/uk/add/UkAuthorIZView.vue';
import AddUkAuthorPMView from '@/views/uk/add/UkAuthorPMView.vue';
import AddUkAuthorPOView from '@/views/uk/add/UkAuthorPOView.vue';
import AddUkIzPatentView from '@/views/uk/add/UkIzPatentView.vue';
import AddUkPmPatentView from '@/views/uk/add/UkPmPatentView.vue';
import AddUkPoPatentView from '@/views/uk/add/UkPoPatentView.vue';
import AddUkIzPatentMPKView from '@/views/uk/add/UkIzPatentMPKView.vue';
import AddUkPmPatentMPKView from '@/views/uk/add/UkPmPatentMPKView.vue';
import AddUkPoPatentMPKView from '@/views/uk/add/UkPoPatentMPKView.vue';
import AddUkIzPatentSysView from '@/views/uk/add/UkIzPatentSysView.vue';
import AddUkPmPatentSysView from '@/views/uk/add/UkPmPatentSysView.vue';
import AddUkTzPatentSysView from '@/views/uk/add/UkTzPatentSysView.vue';
import AddUkOblIZView from '@/views/uk/add/UkOblIZView.vue';
import AddUkOblPMView from '@/views/uk/add/UkOblPMView.vue';
import AddUkOblPOView from '@/views/uk/add/UkOblPOView.vue';
import AddUkPoPatentNumView from '@/views/uk/add/UkPoPatentNumView.vue';
import AddUkTzPatentNumView from '@/views/uk/add/UkTzPatentNumView.vue';

import UpdateUkAuthorIZView from '@/views/uk/update/UkAuthorIZView.vue';
import UpdateUkAuthorPMView from '@/views/uk/update/UkAuthorPMView.vue';
import UpdateUkAuthorPOView from '@/views/uk/update/UkAuthorPOView.vue';
import UpdateUkIzPatentView from '@/views/uk/update/UkIzPatentView.vue';
import UpdateUkPmPatentView from '@/views/uk/update/UkPmPatentView.vue';
import UpdateUkPoPatentView from '@/views/uk/update/UkPoPatentView.vue';
import UpdateUkIzPatentMPKView from '@/views/uk/update/UkIzPatentMPKView.vue';
import UpdateUkPmPatentMPKView from '@/views/uk/update/UkPmPatentMPKView.vue';
import UpdateUkPoPatentMPKView from '@/views/uk/update/UkPoPatentMPKView.vue';
import UpdateUkIzPatentSysView from '@/views/uk/update/UkIzPatentSysView.vue';
import UpdateUkPmPatentSysView from '@/views/uk/update/UkPmPatentSysView.vue';
import UpdateUkTzPatentSysView from '@/views/uk/update/UkTzPatentSysView.vue';
import UpdateUkOblIZView from '@/views/uk/update/UkOblIZView.vue';
import UpdateUkOblPMView from '@/views/uk/update/UkOblPMView.vue';
import UpdateUkOblPOView from '@/views/uk/update/UkOblPOView.vue';
import UpdateUkPoPatentNumView from '@/views/uk/update/UkPoPatentNumView.vue';
import UpdateUkTzPatentNumView from '@/views/uk/update/UkTzPatentNumView.vue';
// 

import AddIzPatentView from '@/views/patents/add/IzPatentView.vue';
import AddPmPatentView from '@/views/patents/add/PmPatentView.vue';
import AddPoPatentView from '@/views/patents/add/PoPatentView.vue';
import AddNmptPatentView from '@/views/patents/add/NmptPatentView.vue';
import AddGuPatentView from '@/views/patents/add/GuPatentView.vue';
import AddTzPatentView from '@/views/patents/add/TzPatentView.vue';
import AddSdPatentView from '@/views/patents/add/SdPatentView.vue';

import AddEkspTzPatentView from '@/views/eksp/add/EkspTzPatentView.vue';
import AddEkspSdPatentView from '@/views/eksp/add/EkspSdPatentView.vue';
import AddEkspNmptPatentView from '@/views/eksp/add/EkspNmptPatentView.vue';
import AddEkspGuPatentView from '@/views/eksp/add/EkspGuPatentView.vue';

import AddIzv_1_View from '@/views/izv/add/Izv_1_View.vue';
import AddIzv_2_View from '@/views/izv/add/Izv_2_View.vue';

import AddIzvProdlIzView from '@/views/izv/add/prodl/IzvProdlIzView.vue';
import AddIzvProdlPmView from '@/views/izv/add/prodl/IzvProdlPmView.vue';
import AddIzvProdlPoView from '@/views/izv/add/prodl/IzvProdlPoView.vue';
import AddIzvProdlSdView from '@/views/izv/add/prodl/IzvProdlSdView.vue';
import AddIzvProdlTzView from '@/views/izv/add/prodl/IzvProdlTzView.vue';
import AddIzvProdlNmptView from '@/views/izv/add/prodl/IzvProdlNmptView.vue';

import AddIzvPrekIzView from '@/views/izv/add/prek/IzvPrekIzView.vue';
import AddIzvPrekPoView from '@/views/izv/add/prek/IzvPrekPoView.vue';
import AddIzvPrekPmView from '@/views/izv/add/prek/IzvPrekPmView.vue';
import AddIzvPrekSdView from '@/views/izv/add/prek/IzvPrekSdView.vue';

import AddIzvVostIzView from '@/views/izv/add/vost/IzvVostIzView.vue';
import AddIzvVostPoView from '@/views/izv/add/vost/IzvVostPoView.vue';
import AddIzvVostPmView from '@/views/izv/add/vost/IzvVostPmView.vue';
import AddIzvVostSdView from '@/views/izv/add/vost/IzvVostSdView.vue';

import AddIzvDubIzView from '@/views/izv/add/dub/IzvDubIzView.vue';
import AddIzvDubPmView from '@/views/izv/add/dub/IzvDubPmView.vue';
import AddIzvDubPoView from '@/views/izv/add/dub/IzvDubPoView.vue';
import AddIzvDubSdView from '@/views/izv/add/dub/IzvDubSdView.vue';
import AddIzvDubTzView from '@/views/izv/add/dub/IzvDubTzView.vue';
import AddIzvDubNmptView from '@/views/izv/add/dub/IzvDubNmptView.vue';

import AddIzvOblIzView from '@/views/izv/add/obl/IzvOblIzView.vue';
import AddIzvOblPoView from '@/views/izv/add/obl/IzvOblPoView.vue';
import AddIzvOblPmView from '@/views/izv/add/obl/IzvOblPmView.vue';
import AddIzvOblSdView from '@/views/izv/add/obl/IzvOblSdView.vue';

import AddIzvIzmNameAddressView from '@/views/izv/add/izm/IzvIzmNameAddressView.vue';
import AddIzvIzmAddressView from '@/views/izv/add/izm/IzvIzmAddressView.vue';
import AddIzvIzmNameView from '@/views/izv/add/izm/IzvIzmNameView.vue';
import AddIzvIzmMKTUView from '@/views/izv/add/izm/IzvIzmMKTUView.vue';
import AddIzvIzmDkView from '@/views/izv/add/IzvIzmDkView.vue';

import AddIzvCancellationView from '@/views/izv/add/IzvCancellationView.vue';
import AddIzvRlView from '@/views/izv/add/IzvRlView.vue';
import AddIzvTkView from '@/views/izv/add/IzvTkView.vue';
// 
// 

import UpdateIzv_1_View from '@/views/izv/update/Izv_1_View.vue';
import UpdateIzv_2_View from '@/views/izv/update/Izv_2_View.vue';

import UpdateIzPatentView from '@/views/patents/update/IzPatentView.vue';
import UpdatePmPatentView from '@/views/patents/update/PmPatentView.vue';
import UpdatePoPatentView from '@/views/patents/update/PoPatentView.vue';
import UpdateNmptPatentView from '@/views/patents/update/NmptPatentView.vue';
import UpdateGuPatentView from '@/views/patents/update/GuPatentView.vue';
import UpdateTzPatentView from '@/views/patents/update/TzPatentView.vue';
import UpdateSdPatentView from '@/views/patents/update/SdPatentView.vue';

import UpdateEkspTzPatentView from '@/views/eksp/update/EkspTzPatentView.vue';
import UpdateEkspSdPatentView from '@/views/eksp/update/EkspSdPatentView.vue';
import UpdateEkspNmptPatentView from '@/views/eksp/update/EkspNmptPatentView.vue';
import UpdateEkspGuPatentView from '@/views/eksp/update/EkspGuPatentView.vue';

import UpdateIzvProdlIzView from '@/views/izv/update/prodl/IzvProdlIzView.vue';
import UpdateIzvProdlPmView from '@/views/izv/update/prodl/IzvProdlPmView.vue';
import UpdateIzvProdlPoView from '@/views/izv/update/prodl/IzvProdlPoView.vue';
import UpdateIzvProdlSdView from '@/views/izv/update/prodl/IzvProdlSdView.vue';
import UpdateIzvProdlTzView from '@/views/izv/update/prodl/IzvProdlTzView.vue';
import UpdateIzvProdlNmptView from '@/views/izv/update/prodl/IzvProdlNmptView.vue';

import UpdateIzvPrekIzView from '@/views/izv/update/prek/IzvPrekIzView.vue';
import UpdateIzvPrekPoView from '@/views/izv/update/prek/IzvPrekPoView.vue';
import UpdateIzvPrekPmView from '@/views/izv/update/prek/IzvPrekPmView.vue';
import UpdateIzvPrekSdView from '@/views/izv/update/prek/IzvPrekSdView.vue';

import UpdateIzvVostIzView from '@/views/izv/update/vost/IzvVostIzView.vue';
import UpdateIzvVostPoView from '@/views/izv/update/vost/IzvVostPoView.vue';
import UpdateIzvVostPmView from '@/views/izv/update/vost/IzvVostPmView.vue';
import UpdateIzvVostSdView from '@/views/izv/update/vost/IzvVostSdView.vue';

import UpdateIzvDubIzView from '@/views/izv/update/dub/IzvDubIzView.vue';
import UpdateIzvDubPmView from '@/views/izv/update/dub/IzvDubPmView.vue';
import UpdateIzvDubPoView from '@/views/izv/update/dub/IzvDubPoView.vue';
import UpdateIzvDubSdView from '@/views/izv/update/dub/IzvDubSdView.vue';
import UpdateIzvDubTzView from '@/views/izv/update/dub/IzvDubTzView.vue';
import UpdateIzvDubNmptView from '@/views/izv/update/dub/IzvDubNmptView.vue';


import UpdateIzvOblIzView from '@/views/izv/update/obl/IzvOblIzView.vue';
import UpdateIzvOblPoView from '@/views/izv/update/obl/IzvOblPoView.vue';
import UpdateIzvOblPmView from '@/views/izv/update/obl/IzvOblPmView.vue';
import UpdateIzvOblSdView from '@/views/izv/update/obl/IzvOblSdView.vue';

import UpdateIzvIzmNameAddressView from '@/views/izv/update/izm/IzvIzmNameAddressView.vue';
import UpdateIzvIzmAddressView from '@/views/izv/update/izm/IzvIzmAddressView.vue';
import UpdateIzvIzmNameView from '@/views/izv/update/izm/IzvIzmNameView.vue';
import UpdateIzvIzmMKTUView from '@/views/izv/update/izm/IzvIzmMKTUView.vue';
import UpdateIzvIzmDkView from '@/views/izv/update/IzvIzmDkView.vue';

import UpdateIzvCancellationView from '@/views/izv/update/IzvCancellationView.vue';
import UpdateIzvRlView from '@/views/izv/update/IzvRlView.vue';
import UpdateIzvTkView from '@/views/izv/update/IzvTkView.vue';



import NotFound404View from '@/views/NotFound404View.vue';


const routes = [                                                                                    
    {
        path: '/',
        redirect: { name: 'home'},
    },         
    {
        path: '/home',
        name: 'home',
        component: BulletinsListView,
        meta: { title: 'eBulletin' }
    },         
    {
        path: '/bulletin',
        name: 'bulletin',
        props: true,
        component: BulletinView,
        meta: { title: 'eBulletin' }
    },         

    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/users',
        name: 'users',
        component: UsersView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/users/change',
        name: 'user_change',
        component: UserChangeView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-bulletin',
        name: 'add_bulletin',
        component: AddBulletinView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/delete-bulletin',
        name: 'delete_bulletin',
        component: DeleteBulletinView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/iz-patent',
        name: 'iz_patent',
        component: IzPatentView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/pm-patent',
        name: 'pm_patent',
        component: PmPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/po-patent',
        name: 'po_patent',
        component: PoPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/nmpt-patent',
        name: 'nmpt_patent',
        component: NmptPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/tz-patent',
        name: 'tz_patent',
        component: TzPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/sd-patent',
        name: 'sd_patent',
        component: SdPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/eksp-tz-patent',
        name: 'eksp_tz_patent',
        component: EkspTzPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/eksp-sd-patent',
        name: 'eksp_sd_patent',
        component: EkspSdPatentView,
        meta: { title: 'eBulletin' }
    },         
      

    // 
    // 
    
    {
        path: '/add-iz-patent',
        name: 'add_iz_patent',
        component: AddIzPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-pm-patent',
        name: 'add_pm_patent',
        component: AddPmPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-po-patent',
        name: 'add_po_patent',
        component: AddPoPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-nmpt-patent',
        name: 'add_nmpt_patent',
        component: AddNmptPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-gu-patent',
        name: 'add_gu_patent',
        component: AddGuPatentView,
        meta: { title: 'eBulletin' }
    },         
     {
        path: '/add-tz-patent',
        name: 'add_tz_patent',
        component: AddTzPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-sd-patent',
        name: 'add_sd_patent',
        component: AddSdPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-eksp-tz-patent',
        name: 'add_eksp_tz_patent',
        component: AddEkspTzPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-eksp-sd-patent',
        name: 'add_eksp_sd_patent',
        component: AddEkspSdPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-eksp-nmpt-patent',
        name: 'add_eksp_nmpt_patent',
        component: AddEkspNmptPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-eksp-gu-patent',
        name: 'add_eksp_gu_patent',
        component: AddEkspGuPatentView,
        meta: { title: 'eBulletin' }
    },         
      

    
    {
        path: '/add-izv/1',
        name: 'add_izv_1',
        component: AddIzv_1_View,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/add-izv/2',
        name: 'add_izv_2',
        component: AddIzv_2_View,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/add-izv/prodl/iz',
        name: 'add_izv_prodl_iz',
        component: AddIzvProdlIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/prodl/po',
        name: 'add_izv_prodl_po',
        component: AddIzvProdlPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/prodl/pm',
        name: 'add_izv_prodl_pm',
        component: AddIzvProdlPmView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/prodl/tz',
        name: 'add_izv_prodl_tz',
        component: AddIzvProdlTzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/prodl/sd',
        name: 'add_izv_prodl_sd',
        component: AddIzvProdlSdView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/prodl/nmpt',
        name: 'add_izv_prodl_nmpt',
        component: AddIzvProdlNmptView,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/add-izv/prek/iz',
        name: 'add_izv_prek_iz',
        component: AddIzvPrekIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/prek/po',
        name: 'add_izv_prek_po',
        component: AddIzvPrekPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/prek/pm',
        name: 'add_izv_prek_pm',
        component: AddIzvPrekPmView,
        meta: { title: 'eBulletin' }
    },         

    {
        path: '/add-izv/prek/sd',
        name: 'add_izv_prek_sd',
        component: AddIzvPrekSdView,
        meta: { title: 'eBulletin' }
    },         
 

    {
        path: '/add-izv/vost/iz',
        name: 'add_izv_vost_iz',
        component: AddIzvVostIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/vost/po',
        name: 'add_izv_vost_po',
        component: AddIzvVostPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/vost/pm',
        name: 'add_izv_vost_pm',
        component: AddIzvVostPmView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/vost/sd',
        name: 'add_izv_vost_sd',
        component: AddIzvVostSdView,
        meta: { title: 'eBulletin' }
    },         
 

    {
        path: '/add-izv/dub/iz',
        name: 'add_izv_dub_iz',
        component: AddIzvDubIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/dub/po',
        name: 'add_izv_dub_po',
        component: AddIzvDubPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/dub/pm',
        name: 'add_izv_dub_pm',
        component: AddIzvDubPmView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/dub/tz',
        name: 'add_izv_dub_tz',
        component: AddIzvDubTzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/dub/sd',
        name: 'add_izv_dub_sd',
        component: AddIzvDubSdView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/dub/nmpt',
        name: 'add_izv_dub_nmpt',
        component: AddIzvDubNmptView,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/add-izv/obl/iz',
        name: 'add_izv_obl_iz',
        component: AddIzvOblIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/obl/po',
        name: 'add_izv_obl_po',
        component: AddIzvOblPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/obl/pm',
        name: 'add_izv_obl_pm',
        component: AddIzvOblPmView,
        meta: { title: 'eBulletin' }
    },         
        
    {
        path: '/add-izv/obl/sd',
        name: 'add_izv_obl_sd',
        component: AddIzvOblSdView,
        meta: { title: 'eBulletin' }
    },         
 

    {
        path: '/add-izv/izm/name_adrress',
        name: 'add_izv_izm_name_address',
        component: AddIzvIzmNameAddressView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-izv/izm/name',
        name: 'add_izv_izm_name',
        component: AddIzvIzmNameView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-izv/izm/adrress',
        name: 'add_izv_izm_address',
        component: AddIzvIzmAddressView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-izv/izm/mktu',
        name: 'add_izv_izm_mktu',
        component: AddIzvIzmMKTUView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/add-izv/dk',
        name: 'add_izv_izm_dk',
        component: AddIzvIzmDkView,
        meta: { title: 'eBulletin' }
    },         
      
    
    {
        path: '/add-izv/cancellation',
        name: 'add_izv_cancellation',
        component: AddIzvCancellationView,
        meta: { title: 'eBulletin' }
    },         
      
    
    {
        path: '/add-izv/rl',
        name: 'add_izv_rl',
        component: AddIzvRlView,
        meta: { title: 'eBulletin' }
    },         
 

    {
        path: '/add-izv/tk',
        name: 'add_izv_tk',
        component: AddIzvTkView,
        meta: { title: 'eBulletin' }
    },         
       

    // 
    // 
    // 
    // 
    
    {
        path: '/update-izv/1',
        name: 'update_izv_1',
        component: UpdateIzv_1_View,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/update-izv/2',
        name: 'update_izv_2',
        component: UpdateIzv_2_View,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/update-iz-patent',
        name: 'update_iz_patent',
        component: UpdateIzPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-pm-patent',
        name: 'update_pm_patent',
        component: UpdatePmPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-po-patent',
        name: 'update_po_patent',
        component: UpdatePoPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-nmpt-patent',
        name: 'update_nmpt_patent',
        component: UpdateNmptPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-gu-patent',
        name: 'update_gu_patent',
        component: UpdateGuPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-tz-patent',
        name: 'update_tz_patent',
        component: UpdateTzPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-sd-patent',
        name: 'update_sd_patent',
        component: UpdateSdPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-eksp-tz-patent',
        name: 'update_eksp_tz_patent',
        component: UpdateEkspTzPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-eksp-sd-patent',
        name: 'update_eksp_sd_patent',
        component: UpdateEkspSdPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-eksp-nmpt-patent',
        name: 'update_eksp_nmpt_patent',
        component: UpdateEkspNmptPatentView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-eksp-gu-patent',
        name: 'update_eksp_gu_patent',
        component: UpdateEkspGuPatentView,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/update-izv/prodl/iz',
        name: 'update_izv_prodl_iz',
        component: UpdateIzvProdlIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/prodl/po',
        name: 'update_izv_prodl_po',
        component: UpdateIzvProdlPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/prodl/pm',
        name: 'update_izv_prodl_pm',
        component: UpdateIzvProdlPmView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/prodl/tz',
        name: 'update_izv_prodl_tz',
        component: UpdateIzvProdlTzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/prodl/sd',
        name: 'update_izv_prodl_sd',
        component: UpdateIzvProdlSdView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/prodl/nmpt',
        name: 'update_izv_prodl_nmpt',
        component: UpdateIzvProdlNmptView,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/update-izv/prek/iz',
        name: 'update_izv_prek_iz',
        component: UpdateIzvPrekIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/prek/po',
        name: 'update_izv_prek_po',
        component: UpdateIzvPrekPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/prek/pm',
        name: 'update_izv_prek_pm',
        component: UpdateIzvPrekPmView,
        meta: { title: 'eBulletin' }
    },         

    {
        path: '/update-izv/prek/sd',
        name: 'update_izv_prek_sd',
        component: UpdateIzvPrekSdView,
        meta: { title: 'eBulletin' }
    },         
 

    {
        path: '/update-izv/vost/iz',
        name: 'update_izv_vost_iz',
        component: UpdateIzvVostIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/vost/po',
        name: 'update_izv_vost_po',
        component: UpdateIzvVostPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/vost/pm',
        name: 'update_izv_vost_pm',
        component: UpdateIzvVostPmView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/vost/sd',
        name: 'update_izv_vost_sd',
        component: UpdateIzvVostSdView,
        meta: { title: 'eBulletin' }
    },         
 

    {
        path: '/update-izv/dub/iz',
        name: 'update_izv_dub_iz',
        component: UpdateIzvDubIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/dub/po',
        name: 'update_izv_dub_po',
        component: UpdateIzvDubPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/dub/pm',
        name: 'update_izv_dub_pm',
        component: UpdateIzvDubPmView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/dub/tz',
        name: 'update_izv_dub_tz',
        component: UpdateIzvDubTzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/dub/sd',
        name: 'update_izv_dub_sd',
        component: UpdateIzvDubSdView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/dub/nmpt',
        name: 'update_izv_dub_nmpt',
        component: UpdateIzvDubNmptView,
        meta: { title: 'eBulletin' }
    },         
      

    {
        path: '/update-izv/obl/iz',
        name: 'update_izv_obl_iz',
        component: UpdateIzvOblIzView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/obl/po',
        name: 'update_izv_obl_po',
        component: UpdateIzvOblPoView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/obl/pm',
        name: 'update_izv_obl_pm',
        component: UpdateIzvOblPmView,
        meta: { title: 'eBulletin' }
    },         
        
    {
        path: '/update-izv/obl/sd',
        name: 'update_izv_obl_sd',
        component: UpdateIzvOblSdView,
        meta: { title: 'eBulletin' }
    },         
 

    {
        path: '/update-izv/izm/name_adrress',
        name: 'update_izv_izm_name_address',
        component: UpdateIzvIzmNameAddressView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-izv/izm/name',
        name: 'update_izv_izm_name',
        component: UpdateIzvIzmNameView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-izv/izm/adrress',
        name: 'update_izv_izm_address',
        component: UpdateIzvIzmAddressView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-izv/izm/mktu',
        name: 'update_izv_izm_mktu',
        component: UpdateIzvIzmMKTUView,
        meta: { title: 'eBulletin' }
    },         
      
    {
        path: '/update-izv/dk',
        name: 'update_izv_izm_dk',
        component: UpdateIzvIzmDkView,
        meta: { title: 'eBulletin' }
    },         
      
    
    {
        path: '/update-izv/cancellation',
        name: 'update_izv_cancellation',
        component: UpdateIzvCancellationView,
        meta: { title: 'eBulletin' }
    },         
      
    
    {
        path: '/update-izv/rl',
        name: 'update_izv_rl',
        component: UpdateIzvRlView,
        meta: { title: 'eBulletin' }
    },         
 

    {
        path: '/update-izv/tk',
        name: 'update_izv_tk',
        component: UpdateIzvTkView,
        meta: { title: 'eBulletin' }
    },         
       

    // 
    // 

    {
        path: '/add-uk-author-iz-patent',
        name: 'add_uk_author_iz_patent',
        component: AddUkAuthorIZView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-author-pm-patent',
        name: 'add_uk_author_pm_patent',
        component: AddUkAuthorPMView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-author-po-patent',
        name: 'add_uk_author_po_patent',
        component: AddUkAuthorPOView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-obl-iz-patent',
        name: 'add_uk_obl_iz_patent',
        component: AddUkOblIZView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-obl-pm-patent',
        name: 'add_uk_obl_pm_patent',
        component: AddUkOblPMView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-obl-po-patent',
        name: 'add_uk_obl_po_patent',
        component: AddUkOblPOView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-iz-patent',
        name: 'add_uk_iz_patent',
        component: AddUkIzPatentView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-pm-patent',
        name: 'add_uk_pm_patent',
        component: AddUkPmPatentView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-po-patent',
        name: 'add_uk_po_patent',
        component: AddUkPoPatentView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-iz-patent-mpk',
        name: 'add_uk_iz_patent_mpk',
        component: AddUkIzPatentMPKView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-pm-patent-mpk',
        name: 'add_uk_pm_patent_mpk',
        component: AddUkPmPatentMPKView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-po-patent-mpk',
        name: 'add_uk_po_patent_mpk',
        component: AddUkPoPatentMPKView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-iz-sys',
        name: 'add_uk_iz_sys',
        component: AddUkIzPatentSysView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-pm-sys',
        name: 'add_uk_pm_sys',
        component: AddUkPmPatentSysView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-tz-sys',
        name: 'add_uk_tz_sys',
        component: AddUkTzPatentSysView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-po-num',
        name: 'add_uk_po_num',
        component: AddUkPoPatentNumView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/add-uk-num-ztz',
        name: 'add_uk_num_ztz',
        component: AddUkTzPatentNumView,
        meta: { title: 'eBulletin' }
    },         
  

    {
        path: '/update-uk-author-iz-patent',
        name: 'update_uk_author_iz_patent',
        component: UpdateUkAuthorIZView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-author-pm-patent',
        name: 'update_uk_author_pm_patent',
        component: UpdateUkAuthorPMView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-author-po-patent',
        name: 'update_uk_author_po_patent',
        component: UpdateUkAuthorPOView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-obl-iz-patent',
        name: 'update_uk_obl_iz_patent',
        component: UpdateUkOblIZView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-obl-pm-patent',
        name: 'update_uk_obl_pm_patent',
        component: UpdateUkOblPMView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-obl-po-patent',
        name: 'update_uk_obl_po_patent',
        component: UpdateUkOblPOView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-iz-patent',
        name: 'update_uk_iz_patent',
        component: UpdateUkIzPatentView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-pm-patent',
        name: 'update_uk_pm_patent',
        component: UpdateUkPmPatentView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-po-patent',
        name: 'update_uk_po_patent',
        component: UpdateUkPoPatentView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-iz-patent-mpk',
        name: 'update_uk_iz_patent_mpk',
        component: UpdateUkIzPatentMPKView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-pm-patent-mpk',
        name: 'update_uk_pm_patent_mpk',
        component: UpdateUkPmPatentMPKView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-po-patent-mpk',
        name: 'update_uk_po_patent_mpk',
        component: UpdateUkPoPatentMPKView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-iz-sys',
        name: 'update_uk_iz_sys',
        component: UpdateUkIzPatentSysView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-pm-sys',
        name: 'update_uk_pm_sys',
        component: UpdateUkPmPatentSysView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-tz-sys',
        name: 'update_uk_tz_sys',
        component: UpdateUkTzPatentSysView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-po-num',
        name: 'update_uk_po_num',
        component: UpdateUkPoPatentNumView,
        meta: { title: 'eBulletin' }
    },         
  
    {
        path: '/update-uk-num-ztz',
        name: 'update_uk_num_ztz',
        component: UpdateUkTzPatentNumView,
        meta: { title: 'eBulletin' }
    },         
  
    // 
    // 
    {
        path: '/:catchAll(.*)',
        name: 'notfound',
        component: NotFound404View,
        meta: { title: 'eBulletin' }
    },   
   
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title

    next()
});
export default router

