
import "bootstrap/dist/css/bootstrap.min.css"

import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router';
import "vue-toastification/dist/index.css";

import VueToast from 'vue-toast-notification';
// import VCalendar from 'v-calendar';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'v-calendar/dist/style.css';
import 'bootstrap/dist/css/bootstrap.css'

const app = createApp(App)

app.use(router)
app.use(VueToast)
// app.use(VCalendar, {})
app.mount('#app')


import "bootstrap/dist/js/bootstrap.js"


