<template>

  <div class="not-found-page">
    <div class="side-bar">
      <Menu />
    </div>
    <div class="workspace">
      <div class="add-user">
        <div class="section-header">
          Изменить данные пользователя
        </div>
        
        <div class="user-info">          
          <div class="user-info-header">
            Имя
          </div>
          <div class="user-info-field">
            <input class="w100" type="text" :value="first_name" @input="event => first_name = event.target.value">
          </div>
        </div>
        <div class="user-info">
          <div>
            Фамилия
          </div>
          <div>
            <input class="w100" type="text" :value="last_name" @input="event => last_name = event.target.value">
          </div>
        </div>
        <div class="user-info">
          <div>
            Права
          </div>
          <div>
            <input class="m4rem" id="isGuest" type="checkbox" v-model="isGuest">
            <label for="isGuest">&ensp;{{getTranslatePermission('GUEST')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isAdmin" type="checkbox" v-model="isAdmin">
            <label for="isAdmin">&ensp;{{getTranslatePermission('ADMIN')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isInvansionPublisher" type="checkbox" v-model="isInvansionPublisher">
            <label for="isInvansionPublisher">&ensp;{{getTranslatePermission('INVANSIONS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isUtilityModelPublisher" type="checkbox" v-model="isUtilityModelPublisher">
            <label for="isUtilityModelPublisher">&ensp;{{getTranslatePermission('UTILITY_MODEL_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isIndustrialDesignPublisher" type="checkbox" v-model="isIndustrialDesignPublisher">
            <label for="isIndustrialDesignPublisher">&ensp;{{getTranslatePermission('INDUSTRIAL_DESIGNS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isSelectionAchievementsPublisher" type="checkbox" v-model="isSelectionAchievementsPublisher">
            <label for="isSelectionAchievementsPublisher">&ensp;{{getTranslatePermission('SELECTION_ACHIEVEMENTS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isTrademarksPublisher" type="checkbox" v-model="isTrademarksPublisher">
            <label for="isTrademarksPublisher">&ensp;{{getTranslatePermission('TRADEMARKS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isNMPTPublisher" type="checkbox" v-model="isNMPTPublisher">
            <label for="isNMPTPublisher">&ensp;{{getTranslatePermission('NMPT_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isGUPublisher" type="checkbox" v-model="isGUPublisher">
            <label for="isGUPublisher">&ensp;{{getTranslatePermission('GU_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isNoticePublisher" type="checkbox" v-model="isNoticePublisher">
            <label for="isNoticePublisher">&ensp;{{getTranslatePermission('NOTICES_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isPointersPublisher" type="checkbox" v-model="isPointersPublisher">
            <label for="isPointersPublisher">&ensp;{{getTranslatePermission('POINTERS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isTrademarkApplicationsPublisher" type="checkbox" v-model="isTrademarkApplicationsPublisher">
            <label for="isTrademarkApplicationsPublisher">&ensp;{{getTranslatePermission('TRADEMARK_APPLICATIONS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isNMPTApplicationsPublisher" type="checkbox" v-model="isNMPTApplicationsPublisher">
            <label for="isNMPTApplicationsPublisher">&ensp;{{getTranslatePermission('NMPT_APPLICATIONS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isGUApplicationsPublisher" type="checkbox" v-model="isGUApplicationsPublisher">
            <label for="isGUApplicationsPublisher">&ensp;{{getTranslatePermission('GU_APPLICATIONS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isSelectionAchievementApplicationsPublisher" type="checkbox" v-model="isSelectionAchievementApplicationsPublisher">
            <label for="isSelectionAchievementApplicationsPublisher">&ensp;{{getTranslatePermission('SELECTION_ACHIEVEMENT_APPLICATIONIS_PUBLISHER')}}</label>
          </div>        
        </div> 
        <div class="user-info tcentered">
          <button class="add-user-action" @click="changeUser">Изменить</button>
        </div>
        <hr>
        <div class="section-header">
          Изменить пароль
        </div>
        <div class="user-info">
          <div>
            Логин
          </div>
          <div>
            &#x25cf;&ensp;{{username}}
          </div>
        </div>

        <div class="user-info">
          <div>
            Пароль
          </div>
          <div>
            <input class="w100" type="password" @input="event => password = event.target.value">
          </div>        
        </div>

        <div class="user-info tcentered">
          <button class="add-user-action" @click="updatePassword">Изменить пароль</button>
        </div>
        <hr>
        <div class="section-header">
          Удалить пользователя
        </div>
        <div class="user-info tcentered">
          <button class="delete-user-action" @click="fireRemoveUser">Удалить</button>
        </div>

      </div>
    </div>
    <DynamicModal 
      :modalId="modal" 
      :ref="modal" 
    />
  </div>

</template>


<script>
import { defineComponent } from 'vue';
import Menu from '../components/Menu.vue';
import DynamicModal from '../components/DynamicModal.vue';
import { getUser } from '../services/bulletin/requests';
import { deleteUser } from '../services/bulletin/requests';
import { changePassword } from '../services/bulletin/requests';
import { updatePermissions } from '../services/bulletin/requests';
import { updateUser } from '../services/bulletin/requests';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { translatePermission } from '../helpers/utils';

export default defineComponent({
  name: "UserChangeView",

  components: {
    Menu,
    DynamicModal,
  },

  data(){
    return {
      user: {},
      modal: uuidv4(),
      addUserSectionOn: false,
      user_id: 0,
      username: "",
      first_name: "",
      last_name: "",
      password: "",
      isAdmin: false,
      isGuest: false,
      isInvansionPublisher: false,
      isUtilityModelPublisher: false,
      isIndustrialDesignPublisher: false,
      isSelectionAchievementsPublisher: false,
      isTrademarksPublisher: false,
      isNMPTPublisher: false,
      isGUPublisher: false,
      isNoticePublisher: false,
      isPointersPublisher: false,
      isTrademarkApplicationsPublisher: false,
      isNMPTApplicationsPublisher: false,
      isGUApplicationsPublisher: false,
      isSelectionAchievementApplicationsPublisher: false,      
    }
  },

  computed:{
    ...mapGetters(["hasToken", "getUserPermissions"]),
  },

  methods: {
    hasPermission(permission_name){
      return this.getUserPermissions.includes(permission_name)

    },
    getTranslatePermission(permission_name){
      return translatePermission(permission_name)
    },    
    getPermissions(permissions){
      return permissions.join(', ') 
    },
    fireRemoveUser(){
      this.$refs[this.modal].fireModal(
        {
          header: "Удалить пользователя", 
          text: `Вы уверены что хотите удалить пользователя?`,
          isDanger: true,
          afterApprove: this.removeUser,
        }        
      )
    },
    removeUser(){
      deleteUser(this.user_id).then(()=> this.usersPage())
    },
    updatePassword(){
      let data = {
        "password": this.password,
        "user_id": this.user_id
      }
      changePassword(data).then(() => this.$router.go())
    },
    convertPermissions(){
      let userPermissions = [];
      if (this.isAdmin){
        userPermissions.push('ADMIN')
      }
      if (this.isGuest){
        userPermissions.push('GUEST')
      }
      if (this.isInvansionPublisher){
        userPermissions.push('INVANSIONS_PUBLISHER')
      }
      if (this.isUtilityModelPublisher){
        userPermissions.push('UTILITY_MODEL_PUBLISHER')
      }
      if (this.isIndustrialDesignPublisher){
        userPermissions.push('INDUSTRIAL_DESIGNS_PUBLISHER')
      }
      if (this.isSelectionAchievementsPublisher){
        userPermissions.push('SELECTION_ACHIEVEMENTS_PUBLISHER')
      }
      if (this.isTrademarksPublisher){
        userPermissions.push('TRADEMARKS_PUBLISHER')
      }
      if (this.isNMPTPublisher){
        userPermissions.push('NMPT_PUBLISHER')
      }
      if (this.isGUPublisher){
        userPermissions.push('GU_PUBLISHER')
      }
      if (this.isNoticePublisher){
        userPermissions.push('NOTICES_PUBLISHER')
      }
      if (this.isPointersPublisher){
        userPermissions.push('POINTERS_PUBLISHER')
      }
      if (this.isTrademarkApplicationsPublisher){
        userPermissions.push('TRADEMARK_APPLICATIONS_PUBLISHER')
      }
      if (this.isSelectionAchievementApplicationsPublisher){
        userPermissions.push('SELECTION_ACHIEVEMENT_APPLICATIONIS_PUBLISHER')
      }
      if (this.isNMPTApplicationsPublisher){
        userPermissions.push('NMPT_APPLICATIONS_PUBLISHER')
      }
      if (this.isGUApplicationsPublisher){
        userPermissions.push('GU_APPLICATIONS_PUBLISHER')
      }

      return userPermissions
    },

    changeUser(){
      let userData = {
        user_id: this.user_id,
        first_name: this.first_name,
        last_name: this.last_name,
        username: this.username,
      }
      let permissionsData = {
        user_id: this.user_id,
        permissions: this.convertPermissions(),
      }      
      console.log("userData", userData)
      console.log("permissionsData", permissionsData)
      updateUser(userData).then(()=>{
        updatePermissions(permissionsData).then(()=>{
        this.$router.go()
      })
      })

      
    },
    usersPage(){
      this.$router.push(
      {
        name: 'users',
      })    
    },

  },

  mounted () {
    if (!this.hasToken){
      this.$router.push("/login");
    }

    getUser(this.$route.query.userId).then((data) => {
      this.user = data
      
      this.user_id = data.id
      this.first_name = data.first_name
      this.last_name = data.last_name
      this.username = data.username
      this.isAdmin = data.permissions.includes('ADMIN')
      this.isGuest = data.permissions.includes('GUEST')
      this.isInvansionPublisher = data.permissions.includes('INVANSIONS_PUBLISHER')
      this.isUtilityModelPublisher = data.permissions.includes('UTILITY_MODEL_PUBLISHER')
      this.isIndustrialDesignPublisher = data.permissions.includes('INDUSTRIAL_DESIGNS_PUBLISHER')
      this.isSelectionAchievementsPublisher = data.permissions.includes('SELECTION_ACHIEVEMENTS_PUBLISHER')  
      this.isTrademarksPublisher = data.permissions.includes('TRADEMARKS_PUBLISHER')
      this.isNMPTPublisher = data.permissions.includes('NMPT_PUBLISHER')
      this.isGUPublisher = data.permissions.includes('GU_PUBLISHER')
      this.isNoticePublisher = data.permissions.includes('NOTICES_PUBLISHER')
      this.isPointersPublisher = data.permissions.includes('POINTERS_PUBLISHER')
      this.isTrademarkApplicationsPublisher = data.permissions.includes('TRADEMARK_APPLICATIONS_PUBLISHER')
      this.isNMPTApplicationsPublisher = data.permissions.includes('NMPT_APPLICATIONS_PUBLISHER')
      this.isGUApplicationsPublisher = data.permissions.includes('GU_APPLICATIONS_PUBLISHER')
      this.isSelectionAchievementApplicationsPublisher = data.permissions.includes('SELECTION_ACHIEVEMENT_APPLICATIONIS_PUBLISHER')  
    })
  }
})
</script>


<style scoped>

.not-found-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.users {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
}

.centered {
  text-align: center;
}


.side-bar {
  min-height: 100vh;
  width: 300px;
  border-right: rgb(211, 205, 205) solid;
}

.workspace {
  min-height: 100vh;
  width: 800px;
}

.not-found-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 52px;
}

.w300 {
  min-width: 300px;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#009da4;
  color: white;
}

.selectable {
  cursor: pointer;
}

.user-data {
  padding: 10px;
}

.add-user  {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.user-info {
  margin-bottom: 10px;
}

.delete-user-action {
  width: 220px;
  background-color: red;
  color: white;
}

.add-user-action {
  width: 220px;
}

.section-header {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}

</style>
