<template>

  <div class="home-page">
    <div class="side-bar " id='top'>
      <div class="menu-fixed">
        <div class="language-selector">
          <div :class="languageClass('kz')" @click="changeLanguage('kz')">[KZ]</div>
          <div :class="languageClass('ru')" @click="changeLanguage('ru')">[RU]</div>
        </div>
        <TableOfContents 
          :language="language"
          :bull_num="bull_num"
          :timestamp="timestamp"
          :izPatentPublished="izPatentData.length>0"
          :pmPatentPublished="pmData.length>0"
          :poPatentPublished="poData.length>0"
          :sdPatentPublished="sdSRPatentData.length>0"          
          :tzPatentPublished="tzizoData.length>0"
          :izvPublished="hasIZV"
          :ukPublished="hasUK"
          
          :ekspTZPublished="ekspTZData.length>0"
          :ekspSDPublished="ekspSDSRData.length>0"
          :ekspNMPTPublished="ekspNmptData.length>0"
          :ekspGUPublished="ekspGUData.length>0"

          :nmptPatentPublished="nmptData.length>0"
          :guPatentPublished="guData.length>0"

          :izv1_2="izv1Data.length>0 || izv2Data.length>0"
          :izvProdlIZ="izvProdlIZData.length>0"
          :izvProdlPO="izvProdlPOData.length>0"
          :izvProdlPM="izvProdlPMData.length>0"
          :izvProdlTZ="izvProdlTZData.length>0"
          :izvProdlTZMKTU="izvProdlTZMKTUData.length>0"
          :izvProdlSD="izvProdlSDData.length>0"
          :izvProdlNmpt="izvProdlNMPTData.length>0"
          :izvPrekIZ="izvPrekIZData.length>0"
          :izvPrekSD="izvPrekSDData.length>0"
          :izvPrekPM="izvPrekPMData.length>0"
          :izvPrekPO="izvPrekPOData.length>0"
          :izvVostPO="izvVostPOData.length>0"
          :izvVostPM="izvVostPMData.length>0"
          :izvVostSD="izvVostSDData.length>0"
          :izvVostIZ="izvVostIZData.length>0"
          :izvDubIZ="izvDubIZData.length>0"
          :izvDubPO="izvDubPOData.length>0"
          :izvDubPM="izvDubPMData.length>0"
          :izvDubTZ="izvDubTZData.length>0"
          :izvDubNMPT="izvDubNMPTData.length>0"
          :izvDubSD="izvDubSDData.length>0"
          :izvIZMTZNameAddress="izvIZMTZNameAddressData.length>0"
          :izvIZMTZName="izvIZMTZNameData.length>0"
          :izvIZMTZAddress="izvIZMTZAddressData.length>0"
          :izvIZMMKTU="izvIZMMKTUData.length>0"
          :izvIZMOblIZ="izvIZMOblIZData.length>0"
          :izvIZMOblPM="izvIZMOblPMData.length>0"
          :izvIZMOblPO="izvIZMOblPOData.length>0"
          :izvIZMOblSD="izvIZMOblSDData.length>0"
          :izvTK="izvTKData.length>0"
          :izvRL="izvRLData.length>0"
          :izvCancellation="izvCancellationData.length>0"
          :izvIZMDK="izvIZMDKData.length>0"
          
          
          :ptNumApplInvention="ptNumApplInvention.length>0"
          :ptNumPatInvention="ptNumPatInvention.length>0"
          :ptSysPatInvention="ptSysPatInvention.length>0"
          :ptAuthInvention="ptAuthInvention.length>0"
          :ptOwnerInvention="ptOwnerInvention.length>0"

          :ptNumApplID="ptNumApplID.length>0"
          :ptNumPatID="ptNumPatID.length>0"
          :ptSysPatID="ptSysPatID.length>0"
          :ptAuthID="ptAuthID.length>0"
          :ptOwnerID="ptOwnerID.length>0"

          :ptNumApplUM="ptNumApplUM.length>0"
          :ptNumPatUM="ptNumPatUM.length>0"
          :ptSysPatUM="ptSysPatUM.length>0"
          :ptAuthUM="ptAuthUM.length>0"
          :ptOwnerUM="ptOwnerUM.length>0"

          :ptNumApplTM="ptNumApplTM.length>0"
          :ptSysPatTM="ptSysPatTM.length>0"
          
        />

      </div>
    </div>
    <div class="workspace">
      <div  id="lvl1" class="bulletin-level">
        {{ translate("patent-info", language) }}
      </div>

      <div v-if="izPatentData.length>0" id='izo' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("iz-patent-info", language) }} [{{ izPatentLoading ? translate("download_in_progress", language) : izPatentData.length }}]
          </div>
        </div>
        <div class="bulletin-section-info">
          <div v-if="izPatentData.length>0">
            <div class="div-content" v-for="(item, index)  in izPatentData" :key="index">
              <div class="selectable" >
                <div v-if="item.code_13!=null">(13) {{ item.code_13 }}</div>
                <div v-if="item.gos_number_11!=null">(11) {{ item.gos_number_11 }}</div>
                <div v-if="item.icp_51!=null">(51) {{ item.icp_51 }}</div>
                <div v-if="item.req_number_21!=null">(21) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(22) {{ item.req_date_22 }}</div>
                <div v-if="item.pat_paro!=null">(64) {{ item.pat_paro }}</div>
                <div v-if="item.field_31!=null">(31) {{ item.field_31 }}</div>
                <div v-if="item.field_32!=null">(32) {{ item.field_32 }}</div>
                <div v-if="item.field_33!=null">(33) {{ item.field_33 }}</div>
                <div v-if="item.date_85!=null">(85) {{ item.date_85 }}</div>
                <!-- ${this.language.toLocaleLowerCase()} -->
                <!-- [`author_72_${this.language.toLocaleLowerCase()}`] -->
                <div v-if="item[`author_72_${this.language.toLocaleLowerCase()}`]!=null">(72) {{ item[`author_72_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(73) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`attorney_74_${this.language.toLocaleLowerCase()}`]!=null">(74) {{ item[`attorney_74_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(54) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>
              </div>
              <div class="download-formula" @click="downloadFormula(item.id, item.gos_number_11)">(57) {{ translate("download_formula", language) }}</div>
               
              <hr>
            </div>
          </div>
        </div>
      </div>

      <div v-if="pmData.length>0" id='pm' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("pm-patent-info", language) }} [{{ pmLoading ? translate("download_in_progress", language) : pmData.length }}]
          </div>
        </div>
        <div class="bulletin-section-info">
          <div v-if="pmData.length>0">
            <div class="div-content" v-for="(item, index)  in pmData" :key="index">
              <div class="selectable" >
                <div v-if="item.code_13!=null">(13) {{ item.code_13 }}</div>
                <div v-if="item.gos_number_11!=null">(11) {{ item.gos_number_11 }}</div>
                <div v-if="item.icp_51!=null">(51) {{ item.icp_51 }}</div>
                <div v-if="item.req_number_21!=null">(21) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(22) {{ item.req_date_22 }}</div>
                <div v-if="item.pat_paro!=null">(64) {{ item.pat_paro }}</div>
                <div v-if="item.field_31!=null">(31) {{ item.field_31 }}</div>
                <div v-if="item.field_32!=null">(32) {{ item.field_32 }}</div>
                <div v-if="item.field_33!=null">(33) {{ item.field_33 }}</div>
                <div v-if="item.date_85!=null">(85) {{ item.date_85 }}</div>
                <div v-if="item.field_86!=null">(86) {{ item.field_86 }}</div>
                <div v-if="item[`author_72_${this.language.toLocaleLowerCase()}`]!=null">(72) {{ item[`author_72_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(73) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(54) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item.ref_57!=null">(57) {{ item.ref_57 }}</div>
              </div>
              <hr>
            </div>
          </div>
          <div v-else class="mb20" >
            <i>&#60;Сведения отсутствуют&#62;</i>
          </div>
        </div>
      </div>

      <div  v-if="poData.length>0" id='po' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("po-patent-info", language) }} [{{ poLoading ? translate("download_in_progress", language) : poData.length }}]
          </div>
        </div>
        <div class="bulletin-section-info">       
          <div v-if="poData.length>0">
            <div class="div-content" v-for="(item, index)  in poData" :key="index">
              <div class="selectable" >
                <div v-if="item.code_13!=null">(13) {{ item.code_13 }}</div>
                <div v-if="item.gos_number_11!=null">(11) {{ item.gos_number_11 }}</div>
                <div v-if="item.icp_51!=null">(51) {{ item.icp_51 }}</div>
                <div v-if="item.req_number_21!=null">(21) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(22) {{ item.req_date_22 }}</div>
                <div v-if="item.field_31!=null">(31) {{ item.field_31 }}</div>
                <div v-if="item.field_32!=null">(32) {{ item.field_32 }}</div>
                <div v-if="item.field_33!=null">(33) {{ item.field_33 }}</div>
                <div v-if="item.date_85!=null">(85) {{ item.date_85 }}</div>
                <div v-if="item.field_86!=null">(86) {{ item.field_86 }}</div>
                <div v-if="item[`author_72_${this.language.toLocaleLowerCase()}`]!=null">(72) {{ item[`author_72_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(73) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`attorney_74_${this.language.toLocaleLowerCase()}`]!=null">(74) {{ item[`attorney_74_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(54) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>
                <div class="image-place">(55) 
                  <img class="image-container" :src="`data:image/png;base64, ${item.pat_image}`" alt=""/>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>
      </div>

      <div v-if="sdSRPatentData.length>0" id='sd-sr' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("sd-patent-info", language) }} [{{ sdSRPatentLoading ? translate("download_in_progress", language) : sdSRPatentData.length }}]
          </div>
        </div>
        <div class="bulletin-section-info">       
          <div v-if="sdSRPatentData.length>0">
            <div class="div-content" v-for="(item, index)  in sdSRPatentData" :key="index">
              <div class="selectable" >
                <div v-if="item.code_13!=null">(13) {{ item.code_13 }}</div>
                <div v-if="item.sub_type!=null">{{ item.sub_type }}</div>
                <div v-if="item.gos_number_11!=null">(11) {{ item.gos_number_11 }}</div>
                <div v-if="item.req_number_21!=null">(21) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(22) {{ item.req_date_22 }}</div>
                <div v-if="item[`author_72_${this.language.toLocaleLowerCase()}`]!=null">(72) {{ item[`author_72_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(73) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`attorney_74_${this.language.toLocaleLowerCase()}`]!=null">(74) {{ item[`attorney_74_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(54) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>
              </div>
              <div class="download-formula" @click="downloadTable(item.id, item.gos_number_11)">(57) Скачать таблицу признаков</div>
              <hr>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tzizoData.length>0" id='tz' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("tz-patent-info", language) }} [{{ tzizoLoading ? translate("download_in_progress", language) : tzizoData.length }}]
          </div>
        </div>
        <div v-if="tzizoData.length>0">
          <div class="div-content" v-for="(item, index)  in tzizoData" :key="index">
            <div class="selectable" >
              <div v-if="item.gos_number_11!=null">(111) {{ item.gos_number_11 }}</div>
              <div v-if="item.gos_date_11!=null">(151) {{ item.gos_date_11 }}</div>
              <div v-if="item.field_181!=null">(181) {{ item.field_181 }}</div>
              <div v-if="item.req_number_21!=null">(210) {{ item.req_number_21 }}</div>
              <div v-if="item.req_date_22!=null">(220) {{ item.req_date_22 }}</div>
              <div v-if="item.field_31!=null">(31) {{ item.field_31 }}</div>
              <div v-if="item.field_32!=null">(32) {{ item.field_32 }}</div>
              <div v-if="item.field_33!=null">(33) {{ item.field_33 }}</div>
              <div v-if="item[`field_730_${this.language.toLocaleLowerCase()}`]!=null">(730) {{ item[`field_730_${this.language.toLocaleLowerCase()}`] }}</div>
              <div class="image-place">(540) 
                <img class="image-container" :src="item.id in tzImages ? tzImages[item.id] : imgPlaceholder" alt=""/>
              </div>
              <div v-if="item[`field_526_${this.language.toLocaleLowerCase()}`]!=null">(526) {{ translate("526", language) }}: {{ item[`field_526_${this.language.toLocaleLowerCase()}`] }}</div>
              <div v-if="item.field_591!=null">(591) {{ translate("591", language) }}: {{ item.field_591 }}</div>
            </div>
            <div 
              v-if="item.field_510_511_short!=null"
              @click="tzToggleClasses(item.id)" 
              class="tz-classes"
            >
            (511)(510): {{ item.field_510_511_short }} {{ !this.showTZClassesOn.has(item.id) ? translate("shortly", language) : translate("hide", language) }}
            </div>              
            <div 
              v-if="item.field_510_511_short!=null"
              :class="tzShowClasses(item.id)" 
              @click="printLog(item.field_510_511)"
            >
              (511)(510) {{ item.field_510_511.replace(/[\n\r]/g, '0x245').replace(/;0x2450x245/g, ';').replace(/0x2450x245/g, '\n\r') }} 
              
            </div>
            <hr>
          </div>
        </div>
      

      </div>

      <div v-if="nmptData.length>0"  id='nmpt' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("nmpt-patent-info", language) }} [{{ nmptLoading ? translate("download_in_progress", language) : nmptData.length }}]
          </div>
        </div>
          <div v-if="nmptData.length>0">
            <div class="div-content" v-for="(item, index)  in nmptData" :key="index">
              <div class="selectable" >
                <div v-if="item.gos_number_11!=null">(111) {{ item.gos_number_11 }}</div>
                <div v-if="item.gos_date_11!=null">(151) {{ item.gos_date_11 }}</div>
                <div v-if="item.field_181!=null">(181) {{ item.field_181 }}</div>
                <div v-if="item.req_number_21!=null">(210) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(220) {{ item.req_date_22 }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(730) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(540) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item.selection_family!=null">{{ translate("product-type", language) }}{{  item.selection_family }}</div>
              </div>
              <hr>
            </div>
          </div>

      </div>

      <div v-if="guData.length>0" id='gu' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("gu-patent-info", language) }} [{{ guLoading ? translate("download_in_progress", language) : guData.length }}]
          </div>
        </div>
          <div v-if="guData.length>0">
            <div class="div-content" v-for="(item, index)  in guData" :key="index">
              <div class="selectable">
                <div v-if="item.gos_number_11!=null">(111) {{ item.gos_number_11 }}</div>
                <div v-if="item.gos_date_11!=null">(151) {{ item.gos_date_11 }}</div>
                <div v-if="item.field_181!=null">(181) {{ item.field_181 }}</div>
                <div v-if="item.req_number_21!=null">(210) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(220) {{ item.req_date_22 }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(730) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(540) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item.selection_family!=null">{{ translate("product-type", language) }}{{  item.selection_family }}</div>
                <div class="image-place">(55) 
                  <img class="image-container" :src="`data:image/png;base64, ${item.pat_image}`" alt=""/>
                </div>
              </div>
              <hr>
            </div>
          </div>

      </div>


      <div v-if="hasIZV" id='izv' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("izv-info", language) }}
          </div>
        </div>
        <div  class="bulletin-section-info">
          <div v-if="izv1Data.length || izv2Data.length>0" class="bulletin-subsection-header" id="pc4y">
            {{ translate("izv_12", language) }} [{{ izv1Loading || izv2Loading ? translate("download_in_progress", language) : izv1Data.length + izv2Data.length }}]
          </div>
          <div v-if="izv1Data.length>0">
            <div class="div-content"  v-for="(item, index)  in izv1Data" :key="index">
              <div v-for="(tmp_data, tmp_index) in item[`izv_${this.language.toLocaleLowerCase()}`].split('\n')" :key="tmp_index" >
                <div v-if="tmp_index==0">
                  <b>{{tmp_data}}</b>
                </div>
                <div v-else>
                  {{ tmp_data }}
                </div>
              </div> 
              <hr>             
            </div>
          </div>
          <div v-if="izv2Data.length>0">
            <div class="div-content"  v-for="(item, index)  in izv2Data" :key="index">
              <div v-for="(tmp_data, tmp_index) in item[`izv_${this.language.toLocaleLowerCase()}`].split('\n')" :key="tmp_index" >
                <div v-if="tmp_index==0">
                  <b>{{tmp_data}}</b>
                </div>
                <div v-else>
                  {{ tmp_data }}
                </div>
              </div> 
              <hr>             
            </div>
          </div>

          <div v-if="izvProdlIZData.length>0" class="bulletin-subsection-header" id="nd4a">
            {{ translate("izv_prodl_iz", language) }} [{{ izvProdlIZLoading ? translate("download_in_progress", language) : izvProdlIZData.length }}]
          </div>
          <div class="flex-centered" v-if="izvProdlIZData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-exp-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvProdlIZData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.field_181 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvProdlPMData.length>0" class="bulletin-subsection-header" id="nd4k">
            {{ translate("izv_prodl_pm", language) }} [{{ izvProdlPMLoading ? translate("download_in_progress", language) :  izvProdlPMData.length }}]
          </div>
          <div class="flex-centered"  v-if="izvProdlPMData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-exp-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvProdlPMData" :key="index" class="selectable">
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.field_181 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvProdlPOData.length>0" class="bulletin-subsection-header" id="prodl-po">
            {{ translate("izv_prodl_po", language) }} [{{ izvProdlPOLoading ? translate("download_in_progress", language) :  izvProdlPOData.length }}]
          </div>
          <div class="flex-centered" v-if="izvProdlPOData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-exp-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvProdlPOData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.field_181 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvProdlSDData.length>0" class="bulletin-subsection-header" id="prodl-sd">
            {{ translate("izv_prodl_po", language) }} [{{ izvProdlSDLoading ? translate("download_in_progress", language) :  izvProdlSDData.length }}]
          </div>
          <div class="flex-centered" v-if="izvProdlSDData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-exp-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvProdlSDData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.field_181 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvProdlTZData.length>0" class="bulletin-subsection-header" id="prodl-tz">
            {{ translate("izv_prodl_tz", language) }} [{{ izvProdlTZLoading ? translate("download_in_progress", language) :  izvProdlTZData.length }}]
          </div>
          <div class="flex-centered " v-if="izvProdlTZData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num2", language) }}</th>
                <th  class="centered">{{ translate("tbl-tz-owner-fio", language) }}</th>
                <th  class="centered">{{ translate("tbl-tz-exp-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvProdlTZData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item[`cus_name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
                <td class="centered">{{ item.field_181 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvProdlTZMKTUData.length>0" class="bulletin-subsection-header" id="prodl-tz-mktu">
            {{ translate("izv_prodl_tz_mktu", language) }} [{{ izvProdlTZMKTULoading ? translate("download_in_progress", language) :  izvProdlTZMKTUData.length }}]
          </div>
          <div class="flex-centered " v-if="izvProdlTZMKTUData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num2", language) }}</th>
                <th  class="centered">{{ translate("tbl-tz-owner-fio", language) }}</th>
                <th  class="centered">{{ translate("tbl-mktu", language) }}</th>
                <th  class="centered">{{ translate("tbl-tz-exp-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvProdlTZMKTUData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item[`cus_name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
                <td class="centered">{{ item.mktu }}</td>
                <td class="centered">{{ item.field_181 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvProdlNMPTData.length>0" class="bulletin-subsection-header" id="prodl-nmpt">
            {{ translate("izv_prodl_nmpt", language) }} [{{ izvProdlNMPTLoading ? translate("download_in_progress", language) :  izvProdlNMPTData.length }}]
          </div>
          <div class="flex-centered" v-if="izvProdlNMPTData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-exp-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvProdlNMPTData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.field_181 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvPrekIZData.length>0" class="bulletin-subsection-header" id="mm4a">
            {{ translate("izv_prekr_iz", language) }} [{{ izvPrekIZLoading ? translate("download_in_progress", language) :  izvPrekIZData.length }}]
          </div>
          <div class="flex-centered" v-if="izvPrekIZData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-finish-date", language) }}</th>
                <th  class="centered">{{ translate("tbl-reason", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvPrekIZData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.datpdpat }}</td>
                <td>{{ item[`reason_${this.language.toLocaleLowerCase()}`] }}</td>
              </tr>

            </table>          
          </div>

          <div v-if="izvPrekPMData.length>0" class="bulletin-subsection-header" id="mm4k">
            {{ translate("izv_prekr_pm", language) }} [{{ izvPrekPMLoading ? translate("download_in_progress", language) :  izvPrekPMData.length }}]
          </div>
          <div class="flex-centered" v-if="izvPrekPMData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-finish-date", language) }}</th>
                <th  class="centered">{{ translate("tbl-reason", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvPrekPMData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.datpdpat }}</td>
                <td>{{ item[`reason_${this.language.toLocaleLowerCase()}`] }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvPrekPOData.length>0" class="bulletin-subsection-header" id="prekr-po">
            {{ translate("izv_prekr_po", language) }} [{{ izvPrekPOLoading ? translate("download_in_progress", language) :  izvPrekPOData.length }}]
          </div>
          <div class="flex-centered" v-if="izvPrekPOData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-finish-date", language) }}</th>
                <th  class="centered">{{ translate("tbl-reason", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvPrekPOData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.datpdpat }}</td>
                <td>{{ item[`reason_${this.language.toLocaleLowerCase()}`] }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvPrekSDData.length>0" class="bulletin-subsection-header" id="prekr-sd">
            {{ translate("izv_prekr_sd", language) }} [{{ izvPrekSDLoading ? translate("download_in_progress", language) :  izvPrekSDData.length }}]
          </div>
          <div class="flex-centered" v-if="izvPrekSDData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-finish-date", language) }}</th>
                <th  class="centered">{{ translate("tbl-reason", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvPrekSDData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.datpdpat }}</td>
                <td>{{ item[`reason_${this.language.toLocaleLowerCase()}`] }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvVostIZData.length>0" class="bulletin-subsection-header" id="vost-iz">
            {{ translate("izv_vost_iz", language) }} [{{ izvVostIZLoading ? translate("download_in_progress", language) :  izvVostIZData.length }}]
          </div>
          <div class="flex-centered" v-if="izvVostIZData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-restore-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvVostIZData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.pat_dby }}</td>
              </tr>

            </table>          
          </div>

          <div v-if="izvVostPMData.length>0" class="bulletin-subsection-header" id="vost-pm">
            {{ translate("izv_vost_pm", language) }} [{{ izvVostPMLoading ? translate("download_in_progress", language) :  izvVostPMData.length }}]
          </div>
          <div class="flex-centered" v-if="izvVostPMData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-restore-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvVostPMData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.pat_dby }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvVostPOData.length>0" class="bulletin-subsection-header" id="vost-po">
            {{ translate("izv_vost_po", language) }} [{{ izvVostPOLoading ? translate("download_in_progress", language) :  izvVostPOData.length }}]
          </div>
          <div class="flex-centered" v-if="izvVostPOData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-restore-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvVostPOData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.pat_dby }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvVostSDData.length>0" class="bulletin-subsection-header" id="vost-sd">
            {{ translate("izv_vost_sd", language) }} [{{ izvVostSDLoading ? translate("download_in_progress", language) :  izvVostSDData.length }}]
          </div>
          <div class="flex-centered" v-if="izvVostSDData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-restore-date", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvVostSDData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.pat_dby }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvDubIZData.length>0" class="bulletin-subsection-header" id="dub-iz">
            {{ translate("izv_dub_iz", language) }} [{{ izvDubIZLoading ? translate("download_in_progress", language) :  izvDubIZData.length }}]
          </div>
          <div class="flex-centered" v-if="izvDubIZData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvDubIZData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
              </tr>

            </table>          
          </div>

          <div v-if="izvDubPMData.length>0" class="bulletin-subsection-header" id="dub-pm">
            {{ translate("izv_dub_pm", language) }} [{{ izvDubPMLoading ? translate("download_in_progress", language) :  izvDubPMData.length }}]
          </div>
          <div class="flex-centered" v-if="izvDubPMData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvDubPMData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvDubPOData.length>0" class="bulletin-subsection-header" id="dub-po">
            {{ translate("izv_dub_po", language) }} [{{ izvDubPOLoading ? translate("download_in_progress", language) :  izvDubPOData.length }}]
          </div>
          <div class="flex-centered" v-if="izvDubPOData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvDubPOData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvDubNMPTData.length>0" class="bulletin-subsection-header" id="dub-nmpt">
            {{ translate("izv_dub_nmpt", language) }} [{{ izvDubNMPTLoading ? translate("download_in_progress", language) :  izvDubNMPTData.length }}]
          </div>
          <div class="flex-centered" v-if="izvDubNMPTData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvDubNMPTData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvDubSDData.length>0" class="bulletin-subsection-header" id="dub-sd">
            {{ translate("izv_dub_sd", language) }} [{{ izvDubSDLoading ? translate("download_in_progress", language) :  izvDubSDData.length }}]
          </div>
          <div class="flex-centered" v-if="izvDubSDData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvDubSDData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
              </tr>
            </table>          
          </div>

          <div v-if="izvDubTZData.length>0" class="bulletin-subsection-header" id="dub-tz">
            {{ translate("izv_dub_tz", language) }} [{{ izvDubTZLoading ? translate("download_in_progress", language) :  izvDubTZData.length }}]
          </div>
          <div class="flex-centered" v-if="izvDubTZData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvDubTZData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
              </tr>
            </table>          
          </div>

          <div  v-if="izvIZMOblIZData.length>0" class="bulletin-subsection-header" id="izv-izm-iz">
            {{ translate("izv_obl_iz", language) }} [{{ izvIZMOblIZLoading ? translate("download_in_progress", language) :  izvIZMOblIZData.length }}]
          </div>
          <div class="flex-centered" v-if="izvIZMOblIZData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-obl", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMOblIZData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.owner }}</td>
              </tr>

            </table>
          </div>

          <div  v-if="izvIZMOblPMData.length>0" class="bulletin-subsection-header" id="izv-izm-pm">
            {{ translate("izv_obl_pm", language) }} [{{ izvIZMOblPMLoading ? translate("download_in_progress", language) :  izvIZMOblPMData.length }}]
          </div>
          <div class="flex-centered" v-if="izvIZMOblPMData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-obl", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMOblPMData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.owner }}</td>
              </tr>
            </table>
          </div>

          <div  v-if="izvIZMOblPOData.length>0" class="bulletin-subsection-header" id="izv-izm-po">
            {{ translate("izv_obl_po", language) }} [{{ izvIZMOblPOLoading ? translate("download_in_progress", language) :  izvIZMOblPOData.length }}]
          </div>
          <div class="flex-centered" v-if="izvIZMOblPOData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-obl", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMOblPOData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.owner }}</td>
              </tr>
            </table>
          </div>

          <div  v-if="izvIZMOblSDData.length>0" class="bulletin-subsection-header" id="izv-izm-sd">
            {{ translate("izv_obl_sd", language) }} [{{ izvIZMOblSDLoading ? translate("download_in_progress", language) :  izvIZMOblSDData.length }}]
          </div>
          <div class="flex-centered" v-if="izvIZMOblSDData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-obl", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMOblSDData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td class="centered">{{ item.owner }}</td>
              </tr>
            </table>
          </div>

          <div  v-if="izvIZMTZNameAddressData.length>0" class="bulletin-subsection-header" id="izv-tz-na">
            {{ translate("izv_izm_name_address", language) }} [{{ izvIZMTZNameAddressLoading ? translate("download_in_progress", language) :  izvIZMTZNameAddressData.length }}]
          </div>
          <div class="flex-centered" v-if="izvIZMTZNameAddressData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-obl-name-address", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMTZNameAddressData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.owner_address }}</td>
              </tr>

            </table>
          </div>

          <div  v-if="izvIZMTZAddressData.length>0" class="bulletin-subsection-header" id="izv-tz-a">
            {{ translate("izv_izm_address", language) }} [{{ izvIZMTZAddressLoading ? translate("download_in_progress", language) :  izvIZMTZAddressData.length }}]
          </div>
          <div class="flex-centered" v-if="izvIZMTZAddressData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-obl-address", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMTZAddressData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.owner_address }}</td>
              </tr>
            </table>
          </div>

          <div  v-if="izvIZMTZNameData.length>0" class="bulletin-subsection-header" id="izv-tz-n">
            {{ translate("izv_izm_name", language) }} [{{ izvIZMTZNameLoading ? translate("download_in_progress", language) :  izvIZMTZNameData.length }}]
          </div>
          <div class="flex-centered" v-if="izvIZMTZNameData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-obl-name", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMTZNameData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.owner_address }}</td>
              </tr>
            </table>
          </div>

          <div  v-if="izvIZMMKTUData.length>0" class="bulletin-subsection-header" id="izv-tz-mktu">
            {{ translate("izv_izm_mktu", language) }} [{{ izvIZMMKTULoading ? translate("download_in_progress", language) :  izvIZMMKTUData.length }}]
          </div>
          <div class="flex-centered" v-if="izvIZMMKTUData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-description", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMMKTUData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.should_read }}</td>
              </tr>
            </table>
          </div>

          <div  v-if="izvTKData.length>0" class="bulletin-subsection-header" id="izv-tk">
            {{ translate("izv_tk", language) }} [{{ izvTKLoading ? translate("download_in_progress", language) :  izvTKData.length }}]
            <!-- TK1A  Поправка к публикации сведений об изобретениях.  -->
            <!-- TK1A  Поправки к описанию изобретений. 
            TK1L  Поправка к публикации сведений о промышленных образцах.  
            ТК Поправка к публикации сведений о товарных знаках. [{{ izvTKData.length }}] -->
          </div>
          <div v-if="izvTKData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-pat-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num-app", language) }}</th>
                <th  class="centered">{{ translate("tbl-type", language) }}</th>
                <th  class="centered">{{ translate("tbl-changes", language) }}</th>
                <th  class="centered">{{ translate("tbl-description", language) }}</th>                
              </tr>
              <tr v-for="(item, index) in izvTKData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td class="centered">{{ item.req_number_21 }}</td>
                <td >{{ item[`name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
                <td >{{ item.should_read }}</td>
                <td >{{ item.printed }}</td>
              </tr>

            </table>
          </div>

          <div  v-if="izvRLData.length>0" class="bulletin-subsection-header" id="izv-rl">
            {{ translate("izv_rl", language) }} [{{ izvRLLoading ? translate("download_in_progress", language) :  izvRLData.length }}]
          </div>
          <div v-if="izvRLData.length>0">
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-req-num2", language) }}</th>
                <th  class="centered">{{ translate("tbl-description", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvRLData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td >{{ item.full_desc }}</td>
              </tr>

            </table>
          </div>

          <div  v-if="izvCancellationData.length>0" class="bulletin-subsection-header" id="izv-cancellation">
            {{ translate("izv_cancel", language) }} [{{ izvCancellationLoading ? translate("download_in_progress", language) :  izvCancellationData.length }}]
          </div>
          <div v-if="izvCancellationData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-type", language) }}</th>
                <th  class="centered">{{ translate("tbl-description", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvCancellationData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td >{{ item[`name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
                <td >{{ item.full_desc }}</td>
              </tr>

            </table>
          </div>

          <div  v-if="izvIZMDKData.length>0" class="bulletin-subsection-header" id="izv-izm-dk">
            {{ translate("izv_dk", language) }} [{{ izvIZMDKLoading ? translate("download_in_progress", language) :  izvIZMDKData.length }}]
          </div>
          <div v-if="izvIZMDKData.length>0" >
            <table class="table-content">
              <tr>
                <th  class="centered">{{ translate("tbl-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-doc-num", language) }}</th>
                <th  class="centered">{{ translate("tbl-type", language) }}</th>
                <th  class="centered">{{ translate("tbl-description", language) }}</th>
              </tr>
              <tr v-for="(item, index) in izvIZMDKData" :key="index" class="selectable" >
                <td>{{ index+1 }}</td>
                <td class="centered">{{ item.gos_number_11 }}</td>
                <td >{{ item[`name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
                <td >{{ item.full_desc }}</td>
              </tr>

            </table>
          </div>

        </div>
      </div>

      <div v-if="hasUK" id='uk' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("uk-info", language) }}
          </div>
        </div>


        <div  v-if="ptNumApplInvention.length>0" class="bulletin-subsection-header" id="uk-iz-z">
          {{ translate("uk-iz", language) }} [{{ ptNumApplInvention.length }}]
        </div>
        <div class="flex-centered" v-if="ptNumApplInvention.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptNumApplInvention" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.req_number_21 }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>


        <div  v-if="ptNumPatInvention.length>0" class="bulletin-subsection-header" id="uk-iz-pat">
          {{ translate("uk-iz-pat", language) }} [{{ ptNumPatInvention.length }}]
        </div>
        <div class="flex-centered" v-if="ptNumPatInvention.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>              
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptNumPatInvention" :key="index" class="selectable">
              <td>{{ index+1 }}</td>              
              <td class="centered">{{ items.gos_number_11 }}</td>
              <td class="centered">{{ items.req_number_21 }}</td>
            </tr>
          </table>
        </div>


        <div  v-if="ptSysPatInvention.length>0" class="bulletin-subsection-header" id="uk-iz-mpk">
          {{ translate("uk-iz-sys", language) }}  [{{ ptSysPatInvention.length }}]
        </div>
        <div class="flex-centered" v-if="ptSysPatInvention.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-mpk", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>              
            </tr>
            <tr v-for="(items, index) in ptSysPatInvention" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.mpk }}</td>
              <td class="centered">{{ items.gos_number_11}}</td>
            </tr>
          </table>
        </div>


        <div  v-if="ptAuthInvention.length>0" class="bulletin-subsection-header" id="uk-author-iz">
          {{ translate("uk-iz-auth", language) }} [{{ ptAuthInvention.length }}]
        </div>
        <div class="flex-centered" v-if="ptAuthInvention.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-auththor-fio", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptAuthInvention" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td>{{ items[`cus_name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>

        
        <div  v-if="ptOwnerInvention.length>0" class="bulletin-subsection-header" id="uk-obl-iz">
          {{ translate("uk-iz-obl", language) }} [{{ ptOwnerInvention.length }}]
        </div>
        <div class="flex-centered" v-if="ptOwnerInvention.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-owner-fio", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptOwnerInvention" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td>{{ items[`cus_name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>


        <div  v-if="ptNumApplUM.length>0" class="bulletin-subsection-header" id="uk-pm-z">
          {{ translate("uk-pm-num", language) }} [{{ ptNumApplUM.length }}]
        </div>
        <div class="flex-centered" v-if="ptNumApplUM.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptNumApplUM" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.req_number_21 }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>


        <div  v-if="ptNumPatUM.length>0" class="bulletin-subsection-header" id="uk-pm-pat">
          {{ translate("uk-pm-num-pat", language) }} [{{ ptNumPatUM.length }}]
        </div>
        <div class="flex-centered" v-if="ptNumPatUM.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptNumPatUM" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.gos_number_11}}</td>
              <td class="centered">{{ items.req_number_21 }}</td>
            </tr>
          </table>
        </div>


        <div  v-if="ptSysPatUM.length>0" class="bulletin-subsection-header" id="uk-pm-mpk">
          {{ translate("uk-pm-sys", language) }} [{{ ptSysPatUM.length }}]
        </div>
        <div class="flex-centered" v-if="ptSysPatUM.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-mpk", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptSysPatUM" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.mpk }}</td>
              <td class="centered">{{ items.gos_number_11}}</td>              
            </tr>
          </table>
        </div>


        <div  v-if="ptAuthUM.length>0" class="bulletin-subsection-header" id="uk-author-pm">
          {{ translate("uk-pm-auth", language) }} [{{ ptAuthUM.length }}]
        </div>
        <div class="flex-centered" v-if="ptAuthUM.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-auththor-fio", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptAuthUM" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td>{{ items[`cus_name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>

        
        <div  v-if="ptOwnerUM.length>0" class="bulletin-subsection-header" id="uk-obl-pm">
          {{ translate("uk-pm-obl", language) }} [{{ ptOwnerUM.length }}]
        </div>
        <div class="flex-centered" v-if="ptOwnerUM.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-owner-fio", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptOwnerUM" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td>{{ items[`cus_name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>



       <div  v-if="ptNumApplID.length>0" class="bulletin-subsection-header" id="uk-po-z">
        {{ translate("uk-po-num", language) }} [{{ ptNumApplID.length }}]
        </div>
        <div class="flex-centered" v-if="ptNumApplID.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptNumApplID" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.req_number_21 }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>


       <div  v-if="ptNumPatID.length>0" class="bulletin-subsection-header" id="uk-po-pat">
        {{ translate("uk-po-num-pat", language) }} [{{ ptNumPatID.length }}]
        </div>
        <div class="flex-centered" v-if="ptNumPatID.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptNumPatID" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
              <td class="centered">{{ items.req_number_21 }}</td>
            </tr>
          </table>
        </div>


        <div  v-if="ptSysPatID.length>0" class="bulletin-subsection-header" id="uk-po-mpk">
          {{ translate("uk-po-sys", language) }} [{{ ptSysPatID.length }}]
        </div>
        <div class="flex-centered" v-if="ptSysPatID.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-mkpo", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>              
            </tr>
            <tr v-for="(items, index) in ptSysPatID" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.mkpo }}</td>
              <td class="centered">{{ items.gos_number_11}}</td>              
            </tr>
          </table>
        </div>


        <div  v-if="ptAuthID.length>0" class="bulletin-subsection-header" id="uk-author-po">
          {{ translate("uk-po-auth", language) }} [{{ ptAuthID.length }}]
        </div>
        <div class="flex-centered" v-if="ptAuthID.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-auththor-fio", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptAuthID" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td>{{ items[`cus_name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>



        <div  v-if="ptOwnerID.length>0" class="bulletin-subsection-header" id="uk-obl-po">
          {{ translate("uk-po-obl", language) }} [{{ ptOwnerID.length }}]
        </div>
        <div class="flex-centered" v-if="ptOwnerID.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-owner-fio", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptOwnerID" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td>{{ items[`cus_name_ml_${this.language.toLocaleLowerCase()}`] }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>

        <div  v-if="ptNumApplTM.length>0" class="bulletin-subsection-header" id="uk-num-ztz">
          {{ translate("uk-tz-num", language) }} [{{ ptNumApplTM.length }}]
        </div>
        <div class="flex-centered" v-if="ptNumApplTM.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
              <th  class="centered">{{ translate("tbl-gos-num", language) }}</th>              
            </tr>
            <tr v-for="(items, index) in ptNumApplTM" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items.req_number_21 }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
            </tr>
          </table>
        </div>


        <div  v-if="ptSysPatTM.length>0" class="bulletin-subsection-header" id="uk-tz-sys">
          {{ translate("uk-tz-sys", language) }} [{{ ptSysPatTM.length }}]
        </div>
        <div class="flex-centered" v-if="ptSysPatTM.length>0">
          <table class="table-content">
            <tr>
              <th  class="centered">{{ translate("tbl-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-class", language) }}</th>
              <th  class="centered">{{ translate("tbl-cert-num", language) }}</th>
              <th  class="centered">{{ translate("tbl-pat-uid", language) }}</th>
            </tr>
            <tr v-for="(items, index) in ptSysPatTM" :key="index" class="selectable">
              <td>{{ index+1 }}</td>
              <td>{{ items.clc_code }}</td>
              <td class="centered">{{ items.gos_number_11 }}</td>
              <td class="centered">{{ items.req_number_21 }}</td>
            </tr>
          </table>
        </div>

      </div>
      <hr>

      <div v-if="hasEksp" id="lvl2" class="bulletin-level">
        {{ translate("eksp-info", language) }}
      </div>
      <div v-if="ekspTZData.length>0" id='eksp-tz' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("eksp-tz-patent-info", language) }} [{{ ekspTZLoading ? translate("download_in_progress", language) :  ekspTZData.length }}]
          </div>
        </div>
        <div v-if="ekspTZData.length>0">
          <div class="div-content" v-for="(item, index)  in ekspTZData" :key="index">
            <div class="selectable" >
              <div v-if="item.req_number_21!=null">(210) {{ item.req_number_21 }}</div>
              <div v-if="item.req_date_22!=null">(220) {{ item.req_date_22 }}</div>              
              <div v-if="item.field_31!=null">(310) {{ item.field_31 }}</div>
              <div v-if="item.field_32!=null">(320) {{ item.field_32 }}</div>
              <div v-if="item.field_33!=null">(330) {{ item.field_33 }}</div>
              <div v-if="item[`field_731_${this.language.toLocaleLowerCase()}`]!=null">(731) {{ item[`field_731_${this.language.toLocaleLowerCase()}`] }}</div>
              <div v-if="item.declarant_address!=null">({{ item.declarant_address }})</div>
              <div class="image-place">(540) 
                <img class="image-container" :src="item.id in ekspTZImages ? ekspTZImages[item.id] : imgPlaceholder" alt=""/>
              </div>
              <div v-if="item[`field_526_${this.language.toLocaleLowerCase()}`]!=null">(526) {{ translate("526", language) }}: {{ item[`field_526_${this.language.toLocaleLowerCase()}`] }}</div>
              <div v-if="item.field_591!=null">(591) {{ translate("591", language) }}: {{ item.field_591 }}</div>
            </div>
            <div 
              v-if="item.field_510_511_short!=null"
              @click="tzEKSPToggleClasses(item.id)" 
              class="tz-classes"
            >
              (511)(510): {{ item.field_510_511_short }} {{ !this.showEKSPTZClassesOn.has(item.id) ? translate("shortly", language) : translate("hide", language) }}
            </div>              
            <div 
              v-if="item.field_510_511_short!=null"
              :class="tzEKSPShowClasses(item.id)" 
              @click="printLog(item.field_510_511)"
            >        
              (511)(510) {{ item.field_510_511.replace(/[\n\r]/g, '0x245').replace(/;0x2450x245/g, ';').replace(/0x2450x245/g, '\n\r') }} 
            </div>

            <hr>
          </div>
        </div>
      

      </div>

      <div v-if="ekspSDSRData.length>0" id='eksp-sd-sr' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("eksp-sd-patent-info", language) }} [{{ ekspSDSRLoading ? translate("download_in_progress", language) :  ekspSDSRData.length }}]
          </div>
        </div>
        <div v-if="ekspSDSRData.length>0">
            <div class="div-content" v-for="(item, index)  in ekspSDSRData" :key="index">
              <div class="selectable" >
                <div v-if="item.code_13!=null">(13) {{ item.code_13 }}</div>
                <div v-if="item.sub_type!=null">{{ item.sub_type }}</div>
                <div v-if="item.req_number_21!=null">(21) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(22) {{ item.req_date_22 }}</div>
                <div v-if="item[`author_72_${this.language.toLocaleLowerCase()}`]!=null">(72) {{ item[`author_72_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(73) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`application_${this.language.toLocaleLowerCase()}`]!=null">(71) {{ item[`application_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(54) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>
              </div>
              <!-- <div class="download-formula" @click="downloadEkspTable(item.id, item.req_number_21)">(57) Скачать таблицу признаков</div> -->
              <hr>
            </div>
          </div>
        </div>

        <div v-if="ekspNmptData.length>0" id='eksp-nmpt' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("eksp-nmpt-patent-info", language) }} [{{ ekspNmptLoading ? translate("download_in_progress", language) : ekspNmptData.length }}]
          </div>
        </div>
          <div v-if="ekspNmptData.length>0">
            <div class="div-content" v-for="(item, index)  in ekspNmptData" :key="index">
              <div class="selectable" >
                <div v-if="item.gos_date_11!=null">(151) {{ item.gos_date_11 }}</div>
                <div v-if="item.field_181!=null">(181) {{ item.field_181 }}</div>
                <div v-if="item.req_number_21!=null">(210) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(220) {{ item.req_date_22 }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(730) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item.declarant_address!=null">{{ item.declarant_address }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(540) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>                
                <div v-if="item.selection_family!=null">{{ translate("product-type", language) }}{{  item.selection_family }}</div>
              </div>
              <hr>
            </div>
          </div>

      </div>

      <div v-if="ekspGUData.length>0" id='eksp-gu' class="bulletin-section" >
        <div class="bulletin-section-header">
          <div class="bulletin-section-title" @click="scrollMeTo('top')">
            {{ translate("eksp-gu-patent-info", language) }} [{{ ekspGULoading ? translate("download_in_progress", language) : ekspGUData.length }}]
          </div>
        </div>
          <div v-if="ekspGUData.length>0">
            <div class="div-content" v-for="(item, index)  in ekspGUData" :key="index">
              <div class="selectable" >
                <div v-if="item.gos_date_11!=null">(151) {{ item.gos_date_11 }}</div>
                <div v-if="item.field_181!=null">(181) {{ item.field_181 }}</div>
                <div v-if="item.req_number_21!=null">(210) {{ item.req_number_21 }}</div>
                <div v-if="item.req_date_22!=null">(220) {{ item.req_date_22 }}</div>
                <div v-if="item[`owner_73_${this.language.toLocaleLowerCase()}`]!=null">(730) {{ item[`owner_73_${this.language.toLocaleLowerCase()}`] }}</div>
                <div v-if="item.declarant_address!=null">{{ item.declarant_address }}</div>
                <div v-if="item[`name_540_${this.language.toLocaleLowerCase()}`]!=null">(540) {{ item[`name_540_${this.language.toLocaleLowerCase()}`] }}</div>                
                <div v-if="item.ref_57!=null"><b>{{ translate("feature-info", language) }}:</b> {{ item.ref_57 }}</div>
                <div v-if="item.pat_ddok!=null"><b>{{ translate("nmpt-info", language) }}:</b> {{ item.pat_ddok }}</div>
                <div v-if="item.selection_family!=null"><b>{{ translate("product-type", language) }}</b> {{  item.selection_family }}</div>
                <div class="image-place">(55) 
                  <img class="image-container" :src="`data:image/png;base64, ${item.pat_image}`" alt=""/>
                </div>
              </div>
              <hr>
            </div>
          </div>

      </div>
      

    </div>
    <DynamicModal 
      :modalId="modal" 
      :ref="modal" 
    />    
  </div>

</template>


<script>
import { defineComponent } from '@vue/runtime-core';
import TableOfContents from '../components/TableOfContents.vue';
import DynamicModal from '../components/DynamicModal.vue';
import { abortAllRequests } from '../services/bulletin/requests';
import { pubBullSection } from '../services/bulletin/requests';
import { refBullSection } from '../services/bulletin/requests';
import { getTZPatentImage } from '../services/bulletin/requests';
import { getSDSRPatentInfo } from '../services/bulletin/requests';
import { getPMInfo } from '../services/bulletin/requests';
import { getPOInfo } from '../services/bulletin/requests';
import { getIZPatentInfo } from '../services/bulletin/requests';
import { getTZIZOInfo } from '../services/bulletin/requests';
import { getIZV_1_Info } from '../services/bulletin/requests';
import { getIZV_2_Info } from '../services/bulletin/requests';
import { getIZVProdlIZInfo } from '../services/bulletin/requests';
import { getIZVProdlPOInfo } from '../services/bulletin/requests';
import { getIZVProdlPMInfo } from '../services/bulletin/requests';
import { getIZVProdlTZInfo } from '../services/bulletin/requests';
import { getIZVProdlTZMKTUInfo } from '../services/bulletin/requests';
import { getIZVProdlSDInfo } from '../services/bulletin/requests';
import { getIZVProdlNMPTInfo } from '../services/bulletin/requests';
import { getIZVPrekIZInfo } from '../services/bulletin/requests';
import { getIZVPrekPMInfo } from '../services/bulletin/requests';
import { getIZVPrekSDInfo } from '../services/bulletin/requests';
import { getIZVPrekPOInfo } from '../services/bulletin/requests';
import { getIZVVostPOInfo } from '../services/bulletin/requests';
import { getIZVVostSDInfo } from '../services/bulletin/requests';
import { getIZVVostPMInfo } from '../services/bulletin/requests';
import { getIZVVostIZInfo } from '../services/bulletin/requests';
import { getIZVDubPOInfo } from '../services/bulletin/requests';
import { getIZVDubIZInfo } from '../services/bulletin/requests';
import { getIZVDubPMInfo } from '../services/bulletin/requests';
import { getIZVDubTZInfo } from '../services/bulletin/requests';
import { getIZVDubSDInfo } from '../services/bulletin/requests';
import { getIZVDubNMPTInfo } from '../services/bulletin/requests';
import { getIZVTZIZMNameAdressInfo } from '../services/bulletin/requests';
import { getIZVTZIZMAdressInfo } from '../services/bulletin/requests';
import { getIZVTZIZMNameInfo } from '../services/bulletin/requests';
import { getIZVTZIZMMKTUInfo } from '../services/bulletin/requests';
import { getIZVTKInfo } from '../services/bulletin/requests';
import { getIZVIZMDKInfo } from '../services/bulletin/requests';
import { getIZVRLInfo } from '../services/bulletin/requests';
import { getIZVZIZMOblIZInfo } from '../services/bulletin/requests';
import { getIZVZIZMOblPMInfo } from '../services/bulletin/requests';
import { getIZVZIZMOblPOInfo } from '../services/bulletin/requests';
import { getIZVZIZMOblSDInfo } from '../services/bulletin/requests';
import { getIZVCancellationInfo } from '../services/bulletin/requests';


import { getPtNumApplInvention } from '../services/bulletin/requests';
import { getPtNumPatInvention } from '../services/bulletin/requests';
import { getPtSysPatInvention } from '../services/bulletin/requests';
import { getPtAuthInvention } from '../services/bulletin/requests';
import { getPtOwnerInvention } from '../services/bulletin/requests';


import { getPtNumApplID } from '../services/bulletin/requests';
import { getPtNumPatID } from '../services/bulletin/requests';
import { getPtSysPatID } from '../services/bulletin/requests';
import { getPtAuthID } from '../services/bulletin/requests';
import { getPtOwnerID } from '../services/bulletin/requests';

import { getPtNumApplUM } from '../services/bulletin/requests';
import { getPtNumPatUM } from '../services/bulletin/requests';
import { getPtSysPatUM } from '../services/bulletin/requests';
import { getPtAuthUM } from '../services/bulletin/requests';
import { getPtOwnerUM } from '../services/bulletin/requests';

import { getPtNumApplTM } from '../services/bulletin/requests';
import { getPtSysPatTM } from '../services/bulletin/requests';


import { getEKSPTZInfo } from '../services/bulletin/requests';
import { getEKSPSDSRInfo } from '../services/bulletin/requests';
import { izFormulaDownload } from '../services/bulletin/requests';
import { sdTableDownload } from '../services/bulletin/requests';
import { ekspSdTableDownload } from '../services/bulletin/requests';
import { getNMPTInfo } from '../services/bulletin/requests';
import { getGUInfo } from '../services/bulletin/requests';
import { getEKSPNMPTInfo } from '../services/bulletin/requests';
import { getEKSPGUInfo } from '../services/bulletin/requests';
import { izHasFormula } from '../services/bulletin/requests';
import { ekspSdHasTable } from '../services/bulletin/requests';
import { sdHasTable } from '../services/bulletin/requests';
import { v4 as uuidv4 } from 'uuid';
import { translate } from '../helpers/translator';

export default defineComponent({
  name: "BulletinView",


  components: {
    TableOfContents,
    DynamicModal,
  },

  data(){
    return {
      imgPlaceholder: './assets/loading_img.png',
      modal: uuidv4(),
      language: 'ru',
      showTZClassesOn: new Set(),
      showEKSPTZClassesOn: new Set(),
      timestamp: '',
      data_source: '',
      bull_num: 0,
      pmData: [],
      sdSRPatentData: [],
      poData: [],
      izPatentData: [],
      tzizoData: [],
      izv1Data: [],
      izv2Data: [],
      izvProdlIZData: [],
      izvProdlPOData: [],
      izvProdlPMData: [],
      izvProdlTZData: [],
      izvProdlTZMKTUData: [],
      izvProdlNMPTData: [],
      izvProdlSDData: [],
      izvProdlSNMPTata: [],
      izvPrekIZData: [],
      izvPrekSDData: [],
      izvPrekPMData: [],
      izvPrekPOData: [],
      izvVostPOData: [],
      izvVostPMData: [],
      izvVostSDData: [],
      izvVostIZData: [],
      izvDubIZData: [],
      izvDubPOData: [],
      izvDubPMData: [],
      izvDubTZData: [],
      izvDubSDData: [],
      izvDubNMPTData: [],
      izvIZMTZNameAddressData: [],
      izvIZMTZNameData: [],
      izvIZMMKTUData: [],
      izvIZMTZAddressData: [],
      izvIZMOblIZData: [],
      izvIZMOblPMData: [],
      izvIZMOblPOData: [],
      izvIZMOblSDData: [],
      izvTKData: [],
      izvRLData: [],
      izvCancellationData: [],
      izvIZMDKData: [],

      ptNumApplInvention: [],
      ptNumPatInvention: [],
      ptSysPatInvention: [],
      ptAuthInvention: [],
      ptOwnerInvention: [],

      ptNumApplUM: [],
      ptNumPatUM: [],
      ptSysPatUM: [],
      ptAuthUM: [],
      ptOwnerUM: [],

      ptNumApplID: [],
      ptNumPatID: [],
      ptSysPatID: [],
      ptAuthID: [],
      ptOwnerID: [],

      ptNumApplTM: [],
      ptSysPatTM: [],


      ekspTZData: [],
      ekspSDSRData: [],
      nmptData: [],
      guData: [],
      ekspNmptData: [],
      ekspGUData: [],
      tzImages: {},
      ekspTZImages: {},
      pmLoading: true,
      poLoading: true,
      sdSRPatentLoading: true,
      izPatentLoading: true,
      tzizoLoading: true,
      izv1Loading: true,
      izv2Loading: true,
      izvProdlIZLoading: true,
      izvProdlPOLoading: true,
      izvProdlPMLoading: true,
      izvProdlTZLoading: true,
      izvProdlTZMKTULoading: true,
      izvProdlSDLoading: true,
      izvProdlNMPTLoading: true,
      izvPrekIZLoading: true,
      izvPrekSDLoading: true,
      izvPrekPMLoading: true,
      izvPrekPOLoading: true,
      izvVostPOLoading: true,
      izvVostPMLoading: true,
      izvVostSDLoading: true,
      izvVostIZLoading: true,
      izvDubIZLoading: true,
      izvDubPOLoading: true,
      izvDubPMLoading: true,
      izvDubNMPTLoading: true,
      izvDubTZLoading: true,
      izvDubSDLoading: true,
      izvIZMTZNameAddressLoading: true,
      izvIZMTZNameLoading: true,
      izvIZMTZAddressLoading: true,
      izvIZMOblIZLoading: true,
      izvIZMOblPMLoading: true,
      izvIZMOblPOLoading: true,
      izvIZMOblSDLoading: true,
      izvIZMMKTULoading: true,
      izvTKLoading: true,
      izvRLLoading: true,
      izvCancellationLoading: true,
      izvIZMDKLoading: true,
      ekspTZLoading: true,
      ekspSDSRLoading: true,

      ptNumApplInventionLoading: true,
      ptNumPatInventionLoading: true,
      ptSysPatInventionLoading: true,
      ptAuthInventionLoading: true,
      ptOwnerInventionLoading: true,

      ptNumApplIDLoading: true,
      ptNumPatIDLoading: true,
      ptSysPatIDLoading: true,
      ptAuthIDLoading: true,
      ptOwnerIDLoading: true,

      ptNumApplUMLoading: true,
      ptNumPatUMLoading: true,
      ptSysPatUMLoading: true,
      ptAuthUMLoading: true,
      ptOwnerUMLoading: true,
      
      ptNumApplTMLoading: true,
      ptSysPatTMLoading: true,

      nmptLoading: true,      
      guLoading: true,      
      ekspNmptLoading: true,      
      ekspGULoading: true,      

      pmPatentPublished: false,
      poPatentPublished: false,
      sdPatentPublished: false,
      izPatentPublished: false,
      tzPatentPublished: false,
      izvPublished: false,
      ukPublished: false,
      ekspTZPublished: false,
      ekspSDPublished: false,
      ekspNMPTPublished: false,
      ekspGUPublished: false,
      nmptPatentPublished: false,          
      guPatentPublished: false,          
    }
  },

  computed:{
    hasUK(){
      return this.ptAuthInvention.length > 0 ||
        this.ptAuthUM.length > 0 ||
        this.ptAuthID.length > 0 ||
        this.ptNumApplTM.length > 0 ||
        this.ptNumPatID.length > 0 ||
        this.ptSysPatID.length > 0 ||
        this.ptNumPatInvention.length > 0 ||
        this.ptSysPatInvention.length > 0 ||
        this.ptOwnerInvention.length > 0 ||
        this.ptOwnerUM.length > 0 ||
        this.ptOwnerID.length > 0 ||
        this.ptSysPatTM.length > 0 ||
        this.ptNumApplInvention.length > 0 ||
        this.ptNumApplID.length > 0 ||
        this.ptNumApplID.length > 0
    },
    hasIZV(){
      return this.izv1Data.length > 0 ||
        this.izv2Data.length > 0 ||
        this.izvProdlIZData.length > 0 ||
        this.izvProdlPOData.length > 0 ||
        this.izvProdlPMData.length > 0 ||
        this.izvProdlTZData.length > 0 ||
        this.izvProdlTZMKTUData.length > 0 ||
        this.izvProdlNMPTData.length > 0 ||
        this.izvProdlSDData.length > 0 ||
        this.izvProdlSNMPTata.length > 0 ||
        this.izvPrekIZData.length > 0 ||
        this.izvPrekSDData.length > 0 ||
        this.izvPrekPMData.length > 0 ||
        this.izvPrekPOData.length > 0 ||
        this.izvVostPOData.length > 0 ||
        this.izvVostPMData.length > 0 ||
        this.izvVostSDData.length > 0 ||
        this.izvVostIZData.length > 0 ||
        this.izvDubIZData.length > 0 ||
        this.izvDubPOData.length > 0 ||
        this.izvDubPMData.length > 0 ||
        this.izvDubTZData.length > 0 ||
        this.izvDubSDData.length > 0 ||
        this.izvDubNMPTData.length > 0 ||
        this.izvIZMTZNameAddressData.length > 0 ||
        this.izvIZMTZNameData.length > 0 ||
        this.izvIZMMKTUData.length > 0 ||
        this.izvIZMTZAddressData.length > 0 ||
        this.izvIZMOblIZData.length > 0 ||
        this.izvIZMOblPMData.length > 0 ||
        this.izvIZMOblPOData.length > 0 ||
        this.izvIZMOblSDData.length > 0 ||
        this.izvTKData.length > 0 ||
        this.izvRLData.length > 0 ||
        this.izvCancellationData.length > 0 ||
        this.izvIZMDKData.length > 0
    },

    hasEksp(){
      return this.ekspGUData.length > 0 ||
        this.ekspNmptData.length > 0 ||
        this.ekspTZData.length > 0 ||
        this.ekspSDSRData.length > 0 
    }
  },

  methods: {
    translate,
    scrollMeTo(elementId) {
      var element = document.getElementById(elementId);
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    doPublish(data) {
      console.log("bulletinSectionName", data.bulletinSectionName.toUpperCase())
      console.log("isPublished", data.isPublished)
      console.log("timestamp", this.timestamp)
      console.log("bull_num", this.bull_num)

      abortAllRequests()
      pubBullSection(this.timestamp, this.bull_num, data.bulletinSectionName.toUpperCase()).then(() => this.$router.go())
    },

    resetPointers(){
      this.ptAuthInvention=[];
      this.ptAuthUM=[];
      this.ptAuthID=[];
      this.ptNumApplTM=[];
      this.ptNumPatID=[];
      this.ptSysPatID=[];
      this.ptNumPatInvention=[];
      this.ptSysPatInvention=[];
      this.ptOwnerInvention=[];
      this.ptOwnerUM=[];
      this.ptOwnerID=[];
      this.ptSysPatTM=[];
      this.ptNumApplInvention=[];
      this.ptNumApplID=[];
      this.ptNumApplID=[];
      this.izvIZMDKData= [],
      this.ptAuthInventionLoading = true;
      this.ptAuthUMLoading = true;
      this.ptAuthIDLoading = true;
      this.ptNumApplTMLoading = true;
      this.ukPMMKPLoading = true;
      this.ukPOMKPLoading = true;
      this.ukIZMKPLoading = true;
      this.ptOwnerInventionLoading = true;
      this.ukOblPMLoading = true;
      this.ukOblPOLoading = true;
      this.ukTZSYSLoading = true;
      this.ptApplInventionLoading = true;
      this.ukPMPatLoading = true;
      this.ukPOPatLoading = true;
      this.izvIZMDKLoading = true;
    },
    resetNotices(){
      this.izv1Loading= true;
      this.izv2Loading= true;
      this.izvProdlIZLoading= true;
      this.izvProdlPOLoading= true;
      this.izvProdlPMLoading= true;
      this.izvProdlTZLoading= true;
      this.izvProdlTZMKTULoading= true;
      this.izvProdlSDLoading= true;
      this.izvProdlNMPTLoading= true;
      this.izvPrekIZLoading= true;
      this.izvPrekSDLoading= true;
      this.izvPrekPMLoading= true;
      this.izvPrekPOLoading= true;
      this.izvVostPOLoading= true;
      this.izvVostPMLoading= true;
      this.izvVostSDLoading= true;
      this.izvVostIZLoading= true;
      this.izvDubIZLoading= true;
      this.izvDubPOLoading= true;
      this.izvDubPMLoading= true;
      this.izvDubNMPTLoading= true;
      this.izvDubTZLoading= true;
      this.izvDubSDLoading= true;
      this.izvIZMTZNameAddressLoading= true;
      this.izvIZMTZNameLoading= true;
      this.izvIZMTZAddressLoading= true;
      this.izvIZMOblIZLoading= true;
      this.izvIZMOblPMLoading= true;
      this.izvIZMOblPOLoading= true;
      this.izvIZMOblSDLoading= true;
      this.izvIZMMKTULoading= true;
      this.izvTKLoading= true;
      this.izvRLLoading= true;
      this.izv1Data= [];
      this.izv2Data= [];
      this.izvProdlIZData= [];
      this.izvProdlPOData= [];
      this.izvProdlPMData= [];
      this.izvProdlTZData= [];
      this.izvProdlTZMKTUData= [];
      this.izvProdlNMPTData= [];
      this.izvProdlSDData= [];
      this.izvProdlSNMPTata= [];
      this.izvPrekIZData= [];
      this.izvPrekSDData= [];
      this.izvPrekPMData= [];
      this.izvPrekPOData= [];
      this.izvVostPOData= [];
      this.izvVostPMData= [];
      this.izvVostSDData= [];
      this.izvVostIZData= [];
      this.izvDubIZData= [];
      this.izvDubPOData= [];
      this.izvDubPMData= [];
      this.izvDubTZData= [];
      this.izvDubSDData= [];
      this.izvDubNMPTData= [];
      this.izvIZMTZNameAddressData= [];
      this.izvIZMTZNameData= [];
      this.izvIZMMKTUData= [];
      this.izvIZMTZAddressData= [];
      this.izvIZMOblIZData= [];
      this.izvIZMOblPMData= [];
      this.izvIZMOblPOData= [];
      this.izvIZMOblSDData= [];
      this.izvTKData= [];
      this.izvRLData= [];
      this.izvIZMDKData= [];
    },

    languageClass(lng){
      return lng==this.language.toLocaleLowerCase() ? 'selected-language' : ''
    },
    changeLanguage(lng){
      this.language = lng
    },

    doRefresh(data) {
      console.log("bulletinSectionName", data.bulletinSectionName.toUpperCase())
      console.log("isPublished", data.isPublished)
      console.log("timestamp", this.timestamp)
      console.log("bull_num", this.bull_num)

      abortAllRequests()

      if (data.bulletinSectionName.toUpperCase() == 'IZV'){
        this.resetNotices()
      }
      if (data.bulletinSectionName.toUpperCase() == 'UK'){
        this.resetPointers()
      }

      refBullSection(this.timestamp, this.bull_num, data.bulletinSectionName.toUpperCase()).then(() => this.$router.go())
    },

    firePublish(bulletinSectionName, isPublished){
      this.$refs[this.modal].fireModal(
        {
          header: `Опубликовывание раздела бюллетня за ${this.timestamp} под номером ${this.bull_num}`, 
          text: `Вы уверены, что хотите опубликовать данный раздел?`,
          isDanger: true,
          data: {bulletinSectionName, isPublished},
          afterApprove: this.doPublish,
        }        
      )
    },

    fireRefresh(bulletinSectionName, isPublished){
      this.$refs[this.modal].fireModal(
        {
          header: `Обновление раздела бюллетня за ${this.timestamp} под номером ${this.bull_num}`, 
          text: `Этот раздел будет снят с публикации в общедоступной версии после обновления. Вам необходимо после проверки, его повторно опубликовать. Вы уверены, что хотите обновить данный раздел?`,
          isDanger: true,
          data: {bulletinSectionName, isPublished},
          afterApprove: this.doRefresh,
        }        
      )
    },

    tzToggleHeader(tzIndex){
      if (!this.showTZClassesOn.has(tzIndex)){
        return 'Раскрыть классы'
      }
      return 'Скрыть классы'
    },

    tzToggleClasses(tzIndex){
      if (this.showTZClassesOn.has(tzIndex)){
        this.showTZClassesOn.delete(tzIndex)
        return false
      }
      this.showTZClassesOn.add(tzIndex)
      return true
    },

    tzShowClasses(tzIndex){
      if (!this.showTZClassesOn.has(tzIndex)){
        return 'hiden'
      }
      return ''
    },

    tzEKSPToggleHeader(tzIndex){
      if (!this.showEKSPTZClassesOn.has(tzIndex)){
        return 'Раскрыть классы'
      }
      return 'Скрыть классы'
    },

    tzEKSPToggleClasses(tzIndex){
      if (this.showEKSPTZClassesOn.has(tzIndex)){
        this.showEKSPTZClassesOn.delete(tzIndex)
        return false
      }
      this.showEKSPTZClassesOn.add(tzIndex)
      return true
    },

    tzEKSPShowClasses(tzIndex){
      if (!this.showEKSPTZClassesOn.has(tzIndex)){
        return 'hiden'
      }
      return ''
    },

    showImg(data){
          return `data:image/png;base64, ${data}`
    },

    loadImg(patUID, eksp=false){
      getTZPatentImage(patUID, eksp).then((data) => {
          return `data:image/png;base64, ${data}`
      })
      
    },
    
    downloadFormula(pat_id, gos_number_11){
      izHasFormula(pat_id).then((hasFormula) => {
        if (hasFormula){
          izFormulaDownload(pat_id, this.data_source).then(resp => {
            var blob = new Blob([resp.data], { type: resp.data.type });
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            console.log("AA resp.data.type", resp.data.type)
            // set the name of the file
            let fileFormat = 'doc'
            if (resp.data.type=='application/msword'){
              fileFormat = 'doc'
            }
            if (resp.data.type=='application/vnd.oasis.opendocument.text'){
              fileFormat = 'odt'
            }
            if (resp.data.type=='application/pdf'){
              fileFormat = 'pdf'
            }

            link.download =  `${gos_number_11}.${fileFormat}`;
            // clicking the anchor element will download the file
            link.click();    
          });
        } else {
          this.$refs[this.modal].fireModal(
            {
              header: `Ошибка!`, 
              text: `Файл для скачивания не найден! Этот объект не имеет вложения!`,
              isDanger: true,
              noAction: true,
            }        
          )
        }
      })
    },
    downloadEkspTable(pat_id, req_number_21){
      ekspSdHasTable(pat_id).then((hasFormula) => {
        if (hasFormula){
          ekspSdTableDownload(pat_id, this.data_source).then(resp => {
            var blob = new Blob([resp.data], { type: resp.data.type });
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            // set the name of the file
            let fileFormat = 'doc'
            if (resp.data.type=='application/pdf'){
              fileFormat = 'pdf'
            }
            if (resp.data.type=='application/msword'){
              fileFormat = 'doc'
            }
            if (resp.data.type=='application/vnd.oasis.opendocument.text'){
              fileFormat = 'odt'
            }

            link.download =  `Таблица признаков ${req_number_21}.${fileFormat}`;
            // clicking the anchor element will download the file
            link.click();    
          });
        } else {
          this.$refs[this.modal].fireModal(
            {
              header: `Ошибка!`, 
              text: `Файл для скачивания не найден! Этот объект не имеет вложения!`,
              isDanger: true,
              noAction: true,
            }        
          )
        }
      })
    },
    downloadTable(gosNum){
      console.log("downloadTable(gosNum)", gosNum)
      sdHasTable(gosNum).then((hasFormula) => {
        if (hasFormula){
          sdTableDownload(this.timestamp, this.bull_num, gosNum, this.data_source).then(resp => {
            var blob = new Blob([resp.data], { type: resp.data.type });
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            // set the name of the file
            let fileFormat = 'doc'
            if (resp.data.type=='application/pdf'){
              fileFormat = 'pdf'
            }
            if (resp.data.type=='application/msword'){
              fileFormat = 'doc'
            }
            if (resp.data.type=='application/vnd.oasis.opendocument.text'){
              fileFormat = 'odt'
            }
            link.download =  `Таблица признаков ${gosNum}.${fileFormat}`;
            // clicking the anchor element will download the file
            link.click();    
          });
        } else {
          this.$refs[this.modal].fireModal(
            {
              header: `Ошибка!`, 
              text: `Файл для скачивания не найден! Этот объект не имеет вложения!`,
              isDanger: true,
              noAction: true,
            }        
          )
        }
      })
    }
  },

  mounted () {
  },

  beforeRouteLeave(to, from, next){
    abortAllRequests()
    next();
  },

  created() {
    this.timestamp = this.$route.query.timestamp;
    this.bull_num = this.$route.query.bull_num;
    this.data_source = this.$route.query.data_source;
    this.language = this.$route.query.language


     
    getIZPatentInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izPatentData = data;
        this.izPatentLoading = false;
      });
      



      getTZIZOInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.tzizoData = data;
        this.tzizoLoading = false;
        for(let tz of data){
          getTZPatentImage(tz.id, this.data_source).then((data) => {
            this.tzImages[tz.id] = `data:image/png;base64, ${data}`
          })
        }
      });
      



      getPMInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.pmData = data;
        this.pmLoading = false;
      });

   
      getSDSRPatentInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.sdSRPatentData = data;
        this.sdSRPatentLoading = false;
      });


      getPOInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.poData = data;
        this.poLoading = false;
      });
      
  
      getNMPTInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        console.log("nmptData", data)
        this.nmptData = data;
        this.nmptLoading = false;
      });
      


            
      getGUInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        console.log("guData", data)
        this.guData = data;
        this.guLoading = false;
      });
      


      getIZV_1_Info(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izv1Data = data;
        this.izv1Loading = false;
      });
      getIZV_2_Info(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izv2Data = data;
        this.izv2Loading = false;
      });
      getIZVProdlIZInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvProdlIZData = data;
        this.izvProdlIZLoading = false;
      });
      getIZVProdlPOInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvProdlPOData = data;
        this.izvProdlPOLoading = false;
      });
      getIZVProdlPMInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvProdlPMData = data;
        this.izvProdlPMLoading = false;
      });
      getIZVProdlTZInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvProdlTZData = data;
        this.izvProdlTZLoading = false;
      });
      getIZVProdlTZMKTUInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvProdlTZMKTUData = data;
        this.izvProdlTZMKTULoading = false;
      });
      getIZVProdlSDInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvProdlSDData = data;
        this.izvProdlSDLoading = false;
      });
      getIZVProdlNMPTInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvProdlNMPTData = data;
        this.izvProdlNMPTLoading = false;
      });
      getIZVPrekIZInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvPrekIZData = data;
        this.izvPrekIZLoading = false;
      });
      getIZVPrekPMInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvPrekPMData = data;
        this.izvPrekPMLoading = false;
      });
      getIZVPrekPOInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvPrekPOData = data;
        this.izvPrekPOLoading = false;
      });
      getIZVPrekSDInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvPrekSDData = data;
        this.izvPrekSDLoading = false;
      });
      getIZVVostPMInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvVostPMData = data;
        this.izvVostPMLoading = false;
      });
      getIZVVostPOInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvVostPOData = data;
        this.izvVostPOLoading = false;
      });
      getIZVVostIZInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvVostIZData = data;
        this.izvVostIZLoading = false;
      });
      getIZVVostSDInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvVostSDData = data;
        this.izvVostSDLoading = false;
      });
      getIZVDubIZInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvDubIZData = data;
        this.izvDubIZLoading = false;
      });
      getIZVDubPOInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvDubPOData = data;
        this.izvDubPOLoading = false;
      });
      getIZVDubPMInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvDubPMData = data;
        this.izvDubPMLoading = false;
      });
      getIZVDubNMPTInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvDubNMPTData = data;
        this.izvDubNMPTLoading = false;
      });
      getIZVDubSDInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvDubSDData = data;
        this.izvDubSDLoading = false;
      });
      getIZVDubTZInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvDubTZData = data;
        this.izvDubTZLoading = false;
      });
      getIZVTZIZMNameAdressInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMTZNameAddressData = data;
        this.izvIZMTZNameAddressLoading = false;
      });
      getIZVTZIZMAdressInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMTZAddressData = data;
        this.izvIZMTZAddressLoading = false;
      });
      getIZVTZIZMNameInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMTZNameData = data;
        this.izvIZMTZNameLoading = false;
      });
      getIZVTZIZMNameInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMTZNameData = data;
        this.izvIZMTZNameLoading = false;
      });
      getIZVTZIZMMKTUInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMMKTUData = data;
        // console.log("izvIZMMKTUData", this.izvIZMMKTUData)
        this.izvIZMMKTULoading = false;
      });
      getIZVIZMDKInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMDKData = data;
        // console.log("izvIZMDKData", this.izvIZMDKData)
        this.izvIZMDKLoading = false;
      });
      getIZVTKInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvTKData = data;
        this.izvTKLoading = false;
      });
      getIZVCancellationInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvCancellationData = data;
        this.izvCancellationLoading = false;
      });
      getIZVRLInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvRLData = data;
        this.izvRLLoading = false;
      });
      getIZVZIZMOblIZInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMOblIZData = data;
        this.izvIZMOblIZLoading = false;
      });
      getIZVZIZMOblPMInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMOblPMData = data;
        this.izvIZMOblPMLoading = false;
      });
      getIZVZIZMOblPOInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMOblPOData = data;
        // console.log("izvIZMOblPOData", this.izvIZMOblPOData)
        this.izvIZMOblPOLoading = false;
      });
      getIZVZIZMOblSDInfo(this.timestamp, this.bull_num, this.data_source).then((data) => {
        this.izvIZMOblSDData = data;
        this.izvIZMOblSDLoading = false;
      });      

      getPtNumApplInvention(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptNumApplInvention = data;
          this.ptNumApplInventionLoading = false;
        });
        getPtNumPatInvention(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptNumPatInvention = data;
          this.ptNumPatInventionLoading = false;
        });
        getPtSysPatInvention(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptSysPatInvention = data;
          this.ptSysPatInventionLoading = false;
        });
        getPtAuthInvention(this.timestamp, this.bull_num, this.data_source).then((data) => {
          let sortBy = `cus_name_ml_ru`
          this.ptAuthInvention = data.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
          this.ptAuthInventionLoading = false;
        });
        getPtOwnerInvention(this.timestamp, this.bull_num, this.data_source).then((data) => {
          let sort_key = `cus_name_ml_ru`
          this.ptOwnerInvention = data.sort((a, b) => a[sort_key].localeCompare(b[sort_key]));
          this.ptOwnerInventionLoading = false;
        });

        getPtNumApplID(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptNumApplID = data;
          this.ptNumApplIDLoading = false;
        });
        getPtNumPatID(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptNumPatID = data;
          this.ptNumPatIDLoading = false;
        });
        getPtSysPatID(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptSysPatID = data;
          this.ptSysPatIDLoading = false;
        });
        getPtAuthID(this.timestamp, this.bull_num, this.data_source).then((data) => {
          let sortBy = `cus_name_ml_ru`
          this.ptAuthID = data.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
          this.ptAuthIDLoading = false;
        });
        getPtOwnerID(this.timestamp, this.bull_num, this.data_source).then((data) => {
          let sort_key = `cus_name_ml_ru`
          this.ptOwnerID = data.sort((a, b) => a[sort_key].localeCompare(b[sort_key]));
          this.ptOwnerIDLoading = false;
        });

        getPtNumApplUM(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptNumApplUM = data;
          this.ptNumApplUMLoading = false;
        });
        getPtNumPatUM(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptNumPatUM = data;
          this.ptNumPatUMLoading = false;
        });
        getPtSysPatUM(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptSysPatUM = data;
          this.ptSysPatUMLoading = false;
        });
        getPtAuthUM(this.timestamp, this.bull_num, this.data_source).then((data) => {
          let sortBy = `cus_name_ml_ru`
          this.ptAuthUM = data.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
          this.ptAuthUMLoading = false;
        });
        getPtOwnerUM(this.timestamp, this.bull_num, this.data_source).then((data) => {
          let sort_key = `cus_name_ml_ru`
          this.ptOwnerUM = data.sort((a, b) => a[sort_key].localeCompare(b[sort_key]));
          this.ptOwnerUMLoading = false;
        });
        
        getPtNumApplTM(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptNumApplTM = data;
          this.ptNumApplTMLoading = false;
        });
        getPtSysPatTM(this.timestamp, this.bull_num, this.data_source).then((data) => {
          this.ptSysPatTM = data;
          this.ptSysPatTMLoading = false;
        });
   



      getEKSPTZInfo(this.timestamp, this.data_source).then((data) => {
        this.ekspTZData = data;
        // console.log("ekspTZData", this.ekspTZData)
        this.ekspTZLoading = false;
        for(let tz of data){
          getTZPatentImage(tz.id, this.data_source, true).then((data) => {
            this.ekspTZImages[tz.id] = `data:image/png;base64, ${data}`
          })
        }

      });      


      getEKSPSDSRInfo(this.timestamp, this.data_source).then((data) => {
        this.ekspSDSRData = data;
        this.ekspSDSRLoading = false;
      });      
    



            
      getEKSPNMPTInfo(this.timestamp, this.data_source).then((data) => {
        // console.log("nmptData", data)
        this.ekspNmptData = data;
        this.ekspNmptLoading = false;
      });
      

 
      getEKSPGUInfo(this.timestamp, this.data_source).then((data) => {
        // console.log("nmptData", data)
        this.ekspGUData = data;
        this.ekspGULoading = false;
      });
      
  }
})
</script>


<style scoped>

.home-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.side-bar {
  min-height: 100vh;
  width: 300px;
}

.workspace {
  min-height: 100vh;
  width: 800px;
  padding: 15px;
  border-left: rgb(211, 205, 205) solid;
}

.div-content {
  margin-bottom: 20px;
  text-align: justify;
  white-space: pre-wrap;
  padding: 5px;
  font-size: 12px;
}

.div-content:hover {
  cursor: pointer;
  background-color: white;
}


.table-content {
  margin-bottom: 20px;
  text-align: justify;
  white-space: pre-wrap;
  padding: 5px;
  font-size: 12px;
  width: 100%;
}


.mb20 {
  margin-bottom: 20px;
}
.image-place{
  margin-bottom: 20px;
  width: 300px;
  height: 300px;
}

.bulletin-section-header {
  display: flex;
  justify-content: space-between;
  background-color: rgb(181, 209, 218);
  border-left: 10px solid rgb(1, 113, 121);
  padding-left: 10px;
  margin-bottom: 10px;
}

.bulletin-section-info {
  font-size: 14px;
}

.menu-fixed {
  position: fixed;
  top: 0px;
  bottom: 0;
  width: 300px;
  overflow: auto;
}

.bulletin-section {
  margin-bottom: 20px;
}

.bulletin-subsection-header {
  padding: 10px;
  margin-bottom: 20px;
  background-color: #a7cad1;
}

.bulletin-subsection-header:hover {
  cursor: pointer;
}

.bulletin-section-header:hover {
  cursor: pointer;
}

.bulletin-section-action {
  display: flex;
  justify-content: space-between;
  width: 300px;
  padding-right: 5px;
}

.bulletin-section-action-2 {
  display: flex;
  justify-content: space-between;
  width: 220px;
  padding-right: 5px;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.centered {
  text-align: center;
} 

.hiden {
  display: none;
}

.tz-classes {
  font-weight: 600;
  margin-top: 10px;
}

.tz-classes:hover {
  cursor: pointer;
}

.image-container {
  text-align: center;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  padding: 20px;
}

img {
    width:100%;
    height:100%;
}

.bulletin-level {
  font-weight: 600;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 30px;
  color: rgb(1, 113, 121);
}

.download-formula {
  cursor: pointer;
}

.download-formula:hover {
  font-weight: 600;
}

.selectable:hover {
  cursor: pointer;
  /* font-weight: 600;
   */
   background-color: white;
}

.flex-centered{
  /* display: flex;
  justify-content: center; */
}

.add-item {
  margin: 10px;
  border: solid 1px gray;
  width: 120px;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  background-color: white;
}

.add-item:hover {
  background-color: whitesmoke;
  cursor: pointer;
}
.language-selector {
  display: flex;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.language-selector:hover {
  cursor:pointer;
}

.selected-language {
  font-weight: 800;
}
</style>
