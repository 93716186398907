import { api } from '../api';

export const EP_AUTH = '/sso/auth'
export const EP_USERS = '/sso/users'
export const EP_USER = '/sso/user'
export const EP_CREATE_USER = '/sso/create-user'
export const EP_UPDATE_USER = '/sso/update-user'
export const EP_CHANGE_PASSWORD = '/sso/change-password'
export const EP_UPDATE_PERMISSIONS = '/sso/update-permissions'

export const EP_PUBLISHED_YEARS ='select_bull_years'
export const EP_BULLETIN_INFO ='select_bull_info'

export const EP_NIIS_BULL_LIST = '/niis/get_bull_list'
export const EP_BULL_BULL_LIST = '/select_bull_list_all'
export const EP_PUBLISHED_BULLS_LIST = 'select_bull_list_published'
export const EP_UNPUBLISHED_BULLS_LIST = 'select_bull_list_unpublished'
export const EP_BULL_IS_PUBLISHED ='select_bull_is_published'

export const EP_SD_PATENT_INFO = '/published/select_sd_patent'
export const EP_SD_PZH_PATENT_INFO = '/published/select_sd_pzh_patent'
export const EP_SD_PATENT_REQ_INFO = '/published/select_sd_patent_reg'
export const EP_SD_PZH_PATENT_REQ_INFO = '/published/select_sd_pzh_patent_reg'

export const EP_IZ_PATENT_INFO = '/published/select_iz_patent'
export const EP_PM_PATENT_INFO = '/published/select_pm_patent'
export const EP_PO_PATENT_INFO = '/published/select_po_patent'
export const EP_IZ_INOV_PATENT_INFO = '/published/select_iz_inov_patent'
export const EP_TZIZO_INFO = '/published/select_tzizo'
export const EP_NMPT = '/published/select_nmpt'
export const EP_GU = '/published/select_gu'
export const EP_EKSP_NMPT = '/published/select_eksp_nmpt'
export const EP_EKSP_GU = '/published/select_eksp_gu'

export const EP_IZ_PATENT_DATA = '/select_iz_patent_data'
export const EP_PM_PATENT_DATA = '/select_pm_patent_data'
export const EP_PO_PATENT_DATA = '/select_po_patent_data'
export const EP_NMPT_DATA = '/select_nmpt_patent_data'
export const EP_GU_DATA = '/select_gu_patent_data'
export const EP_TZIZO_DATA = '/select_tz_patent_data'
export const EP_SD_PATENT_DATA = '/select_sd_patent_data'

export const EP_EKSP_TZIZO_DATA = '/select_eksp_tz_patent_data'
export const EP_EKSP_SD_DATA = '/select_eksp_sd_patent_data'
export const EP_EKSP_NMPT_DATA = '/select_eksp_nmpt_patent_data'
export const EP_EKSP_GU_DATA = '/select_eksp_gu_patent_data'


export const EP_TZ_PATENT_IMAGE = '/select_tz_patent_image'
export const EP_PO_PATENT_IMAGE = '/select_po_patent_image'

export const EP_EKSP_TZIZO_INFO = '/published/select_eksp_tzizo'
export const EP_EKSP_SD_INFO = '/published/select_eksp_sd_sr'

export const EP_IZV_1 = '/published/select_izv_1'
export const EP_IZV_2 = '/published/select_izv_2'
export const EP_IZV_PRODL_IZ = '/published/select_izv_prodl_iz'
export const EP_IZV_PRODL_PM = '/published/select_izv_prodl_pm'
export const EP_IZV_PRODL_PO = '/published/select_izv_prodl_po'
export const EP_IZV_PRODL_TZ = '/published/select_izv_prodl_tz'
export const EP_IZV_PRODL_TZ_MKTU = '/published/select_izv_prodl_tz_mktu'
export const EP_IZV_PRODL_SD = '/published/select_izv_prodl_sd'
export const EP_IZV_PRODL_NMPT = '/published/select_izv_prodl_nmpt'
export const EP_IZV_PREK_IZ = '/published/select_izv_prek_iz'
export const EP_IZV_PREK_PO = '/published/select_izv_prek_po'
export const EP_IZV_PREK_PM = '/published/select_izv_prek_pm'
export const EP_IZV_PREK_SD = '/published/select_izv_prek_sd'
export const EP_IZV_VOST_PM = '/published/select_izv_vost_pm'
export const EP_IZV_VOST_PO = '/published/select_izv_vost_po'
export const EP_IZV_VOST_IZ = '/published/select_izv_vost_iz'
export const EP_IZV_VOST_SD = '/published/select_izv_vost_sd'
export const EP_IZV_DUB_PM = '/published/select_izv_dub_pm'
export const EP_IZV_DUB_IZ = '/published/select_izv_dub_iz'
export const EP_IZV_DUB_PO = '/published/select_izv_dub_po'
export const EP_IZV_DUB_TZ = '/published/select_izv_dub_tz'
export const EP_IZV_DUB_SD = '/published/select_izv_dub_sd'
export const EP_IZV_DUB_NMPT = '/published/select_izv_dub_nmpt'
export const EP_IZV_TZ_IZM_NAME_ADDRESS = '/published/select_izv_tz_izm_name_address'
export const EP_IZV_TZ_IZM_NAME = '/published/select_izv_tz_izm_name'
export const EP_IZV_TZ_IZM_MKTU = '/published/select_izv_tz_izm_mktu'
export const EP_IZV_TZ_IZM_ADDRESS = '/published/select_izv_tz_izm_address'
export const EP_IZV_IZM_OBL_IZ = '/published/select_izv_izm_obl_iz'
export const EP_IZV_IZM_OBL_PO = '/published/select_izv_izm_obl_po'
export const EP_IZV_IZM_OBL_PM = '/published/select_izv_izm_obl_pm'
export const EP_IZV_IZM_OBL_SD = '/published/select_izv_izm_obl_sd'
export const EP_IZV_TK = '/published/select_izv_tk'
export const EP_IZV_RL = '/published/select_izv_rl'
export const EP_IZV_CANCELLATION = '/published/select_izv_cancellation'
export const EP_IZV_IZM_DK = '/published/select_izv_izm_dk'

export const EP_PT_NUM_APPL_INVENTION = '/published/pt_num_appl_invention'
export const EP_PT_NUM_PAT_INVENTION = '/published/pt_num_pat_invention'
export const EP_PT_SYS_PAT_INVENTION = '/published/pt_sys_pat_invention'
export const EP_PT_AUTH_INVENTION = '/published/pt_auth_invention'
export const EP_PT_OWNER_INVENTION = '/published/pt_owner_invention'

export const EP_PT_NUM_APPL_ID = '/published/pt_num_appl_id'
export const EP_PT_NUM_PAT_ID = '/published/pt_num_pat_id'
export const EP_PT_SYS_PAT_ID = '/published/pt_sys_pat_id'
export const EP_PT_AUTH_ID = '/published/pt_auth_id'
export const EP_PT_OWNER_ID = '/published/pt_owner_id'

export const EP_PT_NUM_APPL_UM = '/published/pt_num_appl_um'
export const EP_PT_NUM_PAT_UM = '/published/pt_num_pat_um'
export const EP_PT_SYS_PAT_UM = '/published/pt_sys_pat_um'
export const EP_PT_AUTH_UM = '/published/pt_auth_um'
export const EP_PT_OWNER_UM = '/published/pt_owner_um'

export const EP_PT_NUM_APPL_TM = '/published/pt_num_appl_tm'
export const EP_PT_SYS_PAT_TM = '/published/pt_sys_pat_tm'


export const EP_UK_AUTHOR_IZ_NOV = '/published/select_uk_author_iz_nov'
export const EP_UK_AUTHOR_PM_PATENT = '/published/select_uk_author_pm_patent'
export const EP_UK_AUTHOR_PO_PATENT = '/published/select_uk_author_po_patent'
export const EP_UK_IZ_NOV_MPK = '/published/select_uk_iz_inov_mpk'
export const EP_UK_PO_PATENT = '/published/select_uk_po_patent'
export const EP_UK_PM_PATENT = '/published/select_uk_pm_patent'
export const EP_UK_PM_PATENT_MPK = '/published/select_uk_pm_patent_mpk'
export const EP_UK_PO_PATENT_MPK = '/published/select_uk_po_patent_mpk'
export const EP_UK_TZ_SYS = '/published/select_uk_tz_sys'
export const EP_UK_NUM_ZTZ = '/published/select_uk_num_ztz'
export const EP_UK_OBL_IZ_NOV = '/published/select_uk_obl_iz_nov'
export const EP_UK_OBL_PM_PATENT = '/published/select_uk_obl_pm_patent'
export const EP_UK_OBL_PO_PATENT = '/published/select_uk_obl_po_patent'

export const EP_UK_AUTHOR_IZ_PATENT_DATA = '/select_uk_author_iz_patent_data'
export const EP_UK_AUTHOR_PM_PATENT_DATA = '/select_uk_author_pm_patent_data'
export const EP_UK_AUTHOR_PO_PATENT_DATA = '/select_uk_author_po_patent_data'
export const EP_UK_IZ_NOV_MPK_DATA = '/select_uk_iz_inov_mpk_data'
export const EP_UK_IZ_PATENT_MPK_DATA = '/select_uk_iz_patent_mpk_data'
export const EP_UK_IZ_PATENT_DATA = '/select_uk_iz_patent_data'
export const EP_UK_PO_PATENT_DATA = '/select_uk_po_patent_data'
export const EP_UK_PM_PATENT_DATA = '/select_uk_pm_patent_data'
export const EP_UK_PM_PATENT_MPK_DATA = '/select_uk_pm_patent_mpk_data'
export const EP_UK_PO_PATENT_MPK_DATA = '/select_uk_po_patent_mpk_data'
export const EP_UK_TZ_SYS_DATA = '/select_uk_tz_sys_data'
export const EP_UK_NUM_ZTZ_DATA = '/select_uk_num_ztz_data'
export const EP_UK_OBL_IZ_PATENT_DATA = '/select_uk_obl_iz_patent_data'
export const EP_UK_OBL_PM_PATENT_DATA = '/select_uk_obl_pm_patent_data'
export const EP_UK_OBL_PO_PATENT_DATA = '/select_uk_obl_po_patent_data'

export const EP_UK_DELETE_AUTHOR_IZ_PATENT = '/delete_uk_author_iz_patent'
export const EP_UK_DELETE_AUTHOR_PM_PATENT = '/delete_uk_author_pm_patent'
export const EP_UK_DELETE_AUTHOR_PO_PATENT = '/delete_uk_author_po_patent'
export const EP_UK_DELETE_IZ_NOV_MPK = '/delete_uk_iz_inov_mpk'
export const EP_UK_DELETE_IZ_PATENT_MPK = '/delete_uk_iz_patent_mpk'
export const EP_UK_DELETE_IZ_PATENT = '/delete_uk_iz_patent'
export const EP_UK_DELETE_PO_PATENT = '/delete_uk_po_patent'
export const EP_UK_DELETE_PM_PATENT = '/delete_uk_pm_patent'
export const EP_UK_DELETE_PM_PATENT_MPK = '/delete_uk_pm_patent_mpk'
export const EP_UK_DELETE_PO_PATENT_MPK = '/delete_uk_po_patent_mpk'
export const EP_UK_DELETE_TZ_SYS = '/delete_uk_tz_sys'
export const EP_UK_DELETE_NUM_ZTZ = '/delete_uk_num_ztz'
export const EP_UK_DELETE_OBL_IZ_PATENT = '/delete_uk_obl_iz_patent'
export const EP_UK_DELETE_OBL_PM_PATENT = '/delete_uk_obl_pm_patent'
export const EP_UK_DELETE_OBL_PO_PATENT = '/delete_uk_obl_po_patent'

export const EP_UK_ADD_AUTHOR_IZ_PATENT = '/add_pt_auth_invention'
export const EP_UK_ADD_AUTHOR_PM_PATENT = '/add_pt_auth_um'
export const EP_UK_ADD_AUTHOR_PO_PATENT = '/add_pt_auth_id'
export const EP_UK_ADD_IZ_NOV_MPK = '/add_uk_iz_inov_mpk'
export const EP_UK_ADD_IZ_PATENT_MPK = '/add_pt_num_pat_invention'
export const EP_UK_ADD_IZ_PATENT = '/add_pt_num_appl_invention'
export const EP_UK_ADD_PO_PATENT = '/add_pt_num_pat_id'
export const EP_UK_ADD_PM_PATENT = '/add_pt_num_pat_um'
export const EP_UK_ADD_PM_PATENT_MPK = '/add_pt_num_appl_um'
export const EP_UK_ADD_PO_PATENT_MPK = '/add_pt_num_appl_id'
export const EP_UK_ADD_TZ_SYS = '/add_pt_sys_pat_tm'
export const EP_UK_ADD_NUM_ZTZ = '/add_pt_num_appl_tm'
export const EP_UK_ADD_OBL_IZ_PATENT = '/add_pt_owner_invention'
export const EP_UK_ADD_OBL_PM_PATENT = '/add_pt_owner_um'
export const EP_UK_ADD_OBL_PO_PATENT = '/add_pt_owner_id'

export const EP_UK_UPDATE_AUTHOR_IZ_PATENT = '/change_uk_author_iz_patent'
export const EP_UK_UPDATE_AUTHOR_PM_PATENT = '/change_uk_author_pm_patent'
export const EP_UK_UPDATE_AUTHOR_PO_PATENT = '/change_uk_author_po_patent'
export const EP_UK_UPDATE_IZ_NOV_MPK = '/change_uk_iz_inov_mpk'
export const EP_UK_UPDATE_IZ_PATENT_MPK = '/change_uk_iz_patent_mpk'
export const EP_UK_UPDATE_IZ_PATENT = '/change_uk_iz_patent'
export const EP_UK_UPDATE_PO_PATENT = '/change_uk_po_patent'
export const EP_UK_UPDATE_PM_PATENT = '/change_uk_pm_patent'
export const EP_UK_UPDATE_PM_PATENT_MPK = '/change_uk_pm_patent_mpk'
export const EP_UK_UPDATE_PO_PATENT_MPK = '/change_uk_po_patent_mpk'
export const EP_UK_UPDATE_TZ_SYS = '/change_uk_tz_sys'
export const EP_UK_UPDATE_NUM_ZTZ = '/change_uk_num_ztz'
export const EP_UK_UPDATE_OBL_IZ_PATENT = '/change_uk_obl_iz_patent'
export const EP_UK_UPDATE_OBL_PM_PATENT = '/change_uk_obl_pm_patent'
export const EP_UK_UPDATE_OBL_PO_PATENT = '/change_uk_obl_po_patent'


export const EP_IZ_FORMULA_DOWNLOAD = '/select_iz_patent_formula'
export const EP_IZ_HAS_FORMULA = '/select_iz_patent_has_formula'
export const EP_IZ_FORMULA_EXT = '/select_iz_patent_data_file_ext'
export const EP_IZ_FORMULA_DELETE = '/delete_iz_patent_formula'


export const EP_SD_TABLE_DOWNLOAD = '/select_sd_patent_table'
export const EP_SD_HAS_TABLE = '/select_sd_patent_has_table'
export const EP_SD_TABLE_EXT = '/select_sd_patent_table_ext'
export const EP_SD_TABLE_DELETE = '/delete_sd_patent_table'

export const EP_EKSP_SD_TABLE_DOWNLOAD = '/select_eksp_sd_patent_table'
export const EP_EKSP_SD_TABLE_EXT = '/select_eksp_sd_patent_table_ext'
export const EP_EKSP_SD_HAS_TABLE = '/select_eksp_sd_patent_has_table'
export const EP_EKSP_SD_TABLE_DELETE = '/delete_eksp_sd_patent_table'

export const EP_IZ_FORMULA_UPLOAD = '/upload_iz_patent_formula'
export const EP_PO_IMAGE_UPLOAD = '/upload_po_patent_image'
export const EP_TZ_IMAGE_UPLOAD = '/upload_tz_patent_image'
export const EP_TZ_PATENT_IMAGE_DELETE = '/delete_tz_patent_image'
export const EP_EKSP_TZ_IMAGE_DELETE = '/delete_eksp_tz_patent_image'
export const EP_SD_TABLE_UPLOAD = '/upload_sd_patent_table'

export const EP_EKSP_TZ_IMAGE_UPLOAD = '/upload_eksp_tz_patent_image'
export const EP_EKSP_SD_TABLE_UPLOAD = '/upload_eksp_sd_patent_table'

export const EP_BULL_BULL_LIST_MISSED = '/select_bull_list_missed'
export const EP_BULL_BULL_LIST_UPLOADED = '/select_bull_list_uploaded'
export const EP_IS_PUBLISHED = '/select_is_published'
export const EP_PUB_BULL_SECTION = '/pub_bull_section'
export const EP_REF_BULL_SECTION = '/ref_bull_section'
export const EP_UPLOAD_BULL = '/upload_bull'
export const EP_DELETE_BULL = '/delete_bull'

// 
// 
export const EP_ADD_IZ_PATENT = '/add_iz_patent'
export const EP_ADD_PM_PATENT = '/add_pm_patent'
export const EP_ADD_PO_PATENT = '/add_po_patent'
export const EP_ADD_NMPT_PATENT = '/add_nmpt_patent'
export const EP_ADD_GU_PATENT = '/add_gu_patent'
export const EP_ADD_EKSP_NMPT_PATENT = '/add_eksp_nmpt_patent'
export const EP_ADD_EKSP_GU_PATENT = '/add_eksp_gu_patent'
export const EP_ADD_EKSP_SD_PATENT = '/add_eksp_sd_patent'
export const EP_ADD_TZ_PATENT = '/add_tz_patent'
export const EP_ADD_SD_PATENT = '/add_sd_patent'

export const EP_ADD_EKSP_TZ_PATENT = '/add_eksp_tz_patents'
export const EP_ADD_EKSP_SD = '/add_eksp_sd_patents'

export const EP_ADD_PATENT_IMAGE = '/add_patent_image'
export const EP_ADD_PO_PATENT_IMAGE = '/add_po_patent_image'

export const EP_ADD_IZV_1 = '/add_izv_1'
export const EP_ADD_IZV_2 = '/add_izv_2'
export const EP_ADD_IZV_PRODL_IZ = '/add_izv_prodl_iz'
export const EP_ADD_IZV_PRODL_PM = '/add_izv_prodl_pm'
export const EP_ADD_IZV_PRODL_PO = '/add_izv_prodl_po'
export const EP_ADD_IZV_PRODL_TZ = '/add_izv_prodl_tz'
export const EP_ADD_IZV_PRODL_SD = '/add_izv_prodl_sd'
export const EP_ADD_IZV_PRODL_NMPT = '/add_izv_prodl_nmpt'
export const EP_ADD_IZV_PREK_IZ = '/add_izv_prek_iz'
export const EP_ADD_IZV_PREK_PO = '/add_izv_prek_po'
export const EP_ADD_IZV_PREK_PM = '/add_izv_prek_pm'
export const EP_ADD_IZV_PREK_SD = '/add_izv_prek_sd'
export const EP_ADD_IZV_VOST_PM = '/add_izv_vost_pm'
export const EP_ADD_IZV_VOST_PO = '/add_izv_vost_po'
export const EP_ADD_IZV_VOST_IZ = '/add_izv_vost_iz'
export const EP_ADD_IZV_VOST_SD = '/add_izv_vost_sd'
export const EP_ADD_IZV_DUB_PM = '/add_izv_dub_pm'
export const EP_ADD_IZV_DUB_IZ = '/add_izv_dub_iz'
export const EP_ADD_IZV_DUB_PO = '/add_izv_dub_po'
export const EP_ADD_IZV_DUB_TZ = '/add_izv_dub_tz'
export const EP_ADD_IZV_DUB_SD = '/add_izv_dub_sd'
export const EP_ADD_IZV_DUB_NMPT = '/add_izv_dub_nmpt'
export const EP_ADD_IZV_TZ_IZM_NAME_ADDRESS = '/add_izv_izm_name_address'
export const EP_ADD_IZV_TZ_IZM_NAME = '/add_izv_izm_name'
export const EP_ADD_IZV_TZ_IZM_MKTU = '/add_izv_izm_mktu'
export const EP_ADD_IZV_TZ_IZM_ADDRESS = '/add_izv_izm_address'
export const EP_ADD_IZV_IZM_OBL_IZ = '/add_izv_obl_iz'
export const EP_ADD_IZV_IZM_OBL_PO = '/add_izv_obl_po'
export const EP_ADD_IZV_IZM_OBL_PM = '/add_izv_obl_pm'
export const EP_ADD_IZV_IZM_OBL_SD = '/add_izv_obl_sd'
export const EP_ADD_IZV_TK = '/add_izv_tk'
export const EP_ADD_IZV_RL = '/add_izv_rl'
export const EP_ADD_IZV_CANCELLATION = '/add_izv_cancellation'
export const EP_ADD_IZV_IZM_DK = '/add_izv_izm_dk'
// 
// 
export const EP_GET_IZV_1 = '/select_izv_1_data'
export const EP_GET_IZV_2 = '/select_izv_2_data'
export const EP_GET_IZV_PRODL_IZ = '/select_izv_prodl_iz_data'
export const EP_GET_IZV_PRODL_PM = '/select_izv_prodl_pm_data'
export const EP_GET_IZV_PRODL_PO = '/select_izv_prodl_po_data'
export const EP_GET_IZV_PRODL_TZ = '/select_izv_prodl_tz_data'
export const EP_GET_IZV_PRODL_SD = '/select_izv_prodl_sd_data'
export const EP_GET_IZV_PRODL_NMPT = '/select_izv_prodl_nmpt_data'
export const EP_GET_IZV_PREK_IZ = '/select_izv_prek_iz_data'
export const EP_GET_IZV_PREK_PO = '/select_izv_prek_po_data'
export const EP_GET_IZV_PREK_PM = '/select_izv_prek_pm_data'
export const EP_GET_IZV_PREK_SD = '/select_izv_prek_sd_data'
export const EP_GET_IZV_VOST_PM = '/select_izv_vost_pm_data'
export const EP_GET_IZV_VOST_PO = '/select_izv_vost_po_data'
export const EP_GET_IZV_VOST_IZ = '/select_izv_vost_iz_data'
export const EP_GET_IZV_VOST_SD = '/select_izv_vost_sd_data'
export const EP_GET_IZV_DUB_PM = '/select_izv_dub_pm_data'
export const EP_GET_IZV_DUB_IZ = '/select_izv_dub_iz_data'
export const EP_GET_IZV_DUB_PO = '/select_izv_dub_po_data'
export const EP_GET_IZV_DUB_TZ = '/select_izv_dub_tz_data'
export const EP_GET_IZV_DUB_SD = '/select_izv_dub_sd_data'
export const EP_GET_IZV_DUB_NMPT = '/select_izv_dub_nmpt_data'
export const EP_GET_IZV_TZ_IZM_NAME_ADDRESS = '/select_izv_tz_izm_name_address_data'
export const EP_GET_IZV_TZ_IZM_NAME = '/select_izv_tz_izm_name_data'
export const EP_GET_IZV_TZ_IZM_MKTU = '/select_izv_tz_izm_mktu_data'
export const EP_GET_IZV_TZ_IZM_ADDRESS = '/select_izv_tz_izm_address_data'
export const EP_GET_IZV_IZM_OBL_IZ = '/select_izv_izm_obl_iz_data'
export const EP_GET_IZV_IZM_OBL_PO = '/select_izv_izm_obl_po_data'
export const EP_GET_IZV_IZM_OBL_PM = '/select_izv_izm_obl_pm_data'
export const EP_GET_IZV_IZM_OBL_SD = '/select_izv_izm_obl_sd_data'
export const EP_GET_IZV_TK = '/select_izv_tk_data'
export const EP_GET_IZV_RL = '/select_izv_rl_data'
export const EP_GET_IZV_CANCELLATION = '/select_izv_cancellation_data'
export const EP_GET_IZV_IZM_DK = '/select_izv_izm_dk_data'
// 
// 

// 
// 
export const EP_DELETE_IZ_PATENT = '/delete_iz_patent'
export const EP_DELETE_PM_PATENT = '/delete_pm_patent'
export const EP_DELETE_PO_PATENT = '/delete_po_patent'
export const EP_DELETE_NMPT_PATENT = '/delete_nmpt_patent'
export const EP_DELETE_GU_PATENT = '/delete_gu_patent'
export const EP_DELETE_EKSP_NMPT_PATENT = '/delete_eksp_nmpt_patent'
export const EP_DELETE_EKSP_GU_PATENT = '/delete_eksp_gu_patent'
export const EP_DELETE_EKSP_SD_PATENT = '/delete_eksp_sd_patent'
export const EP_DELETE_TZ_PATENT = '/delete_tz_patent'
export const EP_DELETE_SD_PATENT = '/delete_sd_patent'



export const EP_DELETE_EKSP_TZ_PATENT = '/delete_eksp_tz_patent'
export const EP_DELETE_EKSP_SD = '/delete_eksp_sd_patent'

export const EP_DELETE_PATENT_IMAGE = '/delete_patent_image'
export const EP_DELETE_PO_PATENT_IMAGE = '/delete_po_patent_image'

export const EP_DELETE_IZV_1 = '/delete_izv_1'
export const EP_DELETE_IZV_2 = '/delete_izv_2'
export const EP_DELETE_IZV_PRODL_IZ = '/delete_izv_prodl_iz'
export const EP_DELETE_IZV_PRODL_PM = '/delete_izv_prodl_pm'
export const EP_DELETE_IZV_PRODL_PO = '/delete_izv_prodl_po'
export const EP_DELETE_IZV_PRODL_TZ = '/delete_izv_prodl_tz'
export const EP_DELETE_IZV_PRODL_SD = '/delete_izv_prodl_sd'
export const EP_DELETE_IZV_PRODL_NMPT = '/delete_izv_prodl_nmpt'
export const EP_DELETE_IZV_PREK_IZ = '/delete_izv_prek_iz'
export const EP_DELETE_IZV_PREK_PO = '/delete_izv_prek_po'
export const EP_DELETE_IZV_PREK_PM = '/delete_izv_prek_pm'
export const EP_DELETE_IZV_PREK_SD = '/delete_izv_prek_sd'
export const EP_DELETE_IZV_VOST_PM = '/delete_izv_vost_pm'
export const EP_DELETE_IZV_VOST_PO = '/delete_izv_vost_po'
export const EP_DELETE_IZV_VOST_IZ = '/delete_izv_vost_iz'
export const EP_DELETE_IZV_VOST_SD = '/delete_izv_vost_sd'
export const EP_DELETE_IZV_DUB_PM = '/delete_izv_dub_pm'
export const EP_DELETE_IZV_DUB_IZ = '/delete_izv_dub_iz'
export const EP_DELETE_IZV_DUB_PO = '/delete_izv_dub_po'
export const EP_DELETE_IZV_DUB_TZ = '/delete_izv_dub_tz'
export const EP_DELETE_IZV_DUB_SD = '/delete_izv_dub_sd'
export const EP_DELETE_IZV_DUB_NMPT = '/delete_izv_dub_nmpt'
export const EP_DELETE_IZV_TZ_IZM_NAME_DELETERESS = '/delete_izv_tz_izm_name_address'
export const EP_DELETE_IZV_TZ_IZM_NAME = '/delete_izv_tz_izm_name'
export const EP_DELETE_IZV_TZ_IZM_MKTU = '/delete_izv_tz_izm_mktu'
export const EP_DELETE_IZV_TZ_IZM_DELETERESS = '/delete_izv_tz_izm_address'
export const EP_DELETE_IZV_IZM_OBL_IZ = '/delete_izv_izm_obl_iz'
export const EP_DELETE_IZV_IZM_OBL_PO = '/delete_izv_izm_obl_po'
export const EP_DELETE_IZV_IZM_OBL_PM = '/delete_izv_izm_obl_pm'
export const EP_DELETE_IZV_IZM_OBL_SD = '/delete_izv_izm_obl_sd'
export const EP_DELETE_IZV_TK = '/delete_izv_tk'
export const EP_DELETE_IZV_RL = '/delete_izv_rl'
export const EP_DELETE_IZV_CANCELLATION = '/delete_izv_cancellation'
export const EP_DELETE_IZV_IZM_DK = '/delete_izv_izm_dk'

// 
// 
export const EP_UPDATE_IZ_PATENT = '/change_iz_patent'
export const EP_UPDATE_PM_PATENT = '/change_pm_patent'
export const EP_UPDATE_PO_PATENT = '/change_po_patent'
export const EP_UPDATE_NMPT_PATENT = '/change_nmpt_patent'
export const EP_UPDATE_GU_PATENT = '/change_gu_patent'
export const EP_UPDATE_EKSP_NMPT_PATENT = '/change_eksp_nmpt_patent'
export const EP_UPDATE_EKSP_GU_PATENT = '/change_eksp_gu_patent'
export const EP_UPDATE_TZ_PATENT = '/change_tz_patent'
export const EP_UPDATE_SD_PATENT = '/change_sd_patent'

export const EP_UPDATE_EKSP_TZ_PATENT = '/change_eksp_tz_patents'
export const EP_UPDATE_EKSP_SD = '/change_eksp_sd_patents'

export const EP_UPDATE_PATENT_IMAGE = '/change_patent_image'
export const EP_UPDATE_PO_PATENT_IMAGE = '/change_po_patent_image'

export const EP_UPDATE_IZV_1 = '/change_izv_1'
export const EP_UPDATE_IZV_2 = '/change_izv_2'
export const EP_UPDATE_IZV_PRODL_IZ = '/change_izv_prodl_iz'
export const EP_UPDATE_IZV_PRODL_PM = '/change_izv_prodl_pm'
export const EP_UPDATE_IZV_PRODL_PO = '/change_izv_prodl_po'
export const EP_UPDATE_IZV_PRODL_TZ = '/change_izv_prodl_tz'
export const EP_UPDATE_IZV_PRODL_SD = '/change_izv_prodl_sd'
export const EP_UPDATE_IZV_PRODL_NMPT = '/change_izv_prodl_nmpt'
export const EP_UPDATE_IZV_PREK_IZ = '/change_izv_prek_iz'
export const EP_UPDATE_IZV_PREK_PO = '/change_izv_prek_po'
export const EP_UPDATE_IZV_PREK_PM = '/change_izv_prek_pm'
export const EP_UPDATE_IZV_PREK_SD = '/change_izv_prek_sd'
export const EP_UPDATE_IZV_VOST_PM = '/change_izv_vost_pm'
export const EP_UPDATE_IZV_VOST_PO = '/change_izv_vost_po'
export const EP_UPDATE_IZV_VOST_IZ = '/change_izv_vost_iz'
export const EP_UPDATE_IZV_VOST_SD = '/change_izv_vost_sd'
export const EP_UPDATE_IZV_DUB_PM = '/change_izv_dub_pm'
export const EP_UPDATE_IZV_DUB_IZ = '/change_izv_dub_iz'
export const EP_UPDATE_IZV_DUB_PO = '/change_izv_dub_po'
export const EP_UPDATE_IZV_DUB_TZ = '/change_izv_dub_tz'
export const EP_UPDATE_IZV_DUB_SD = '/change_izv_dub_sd'
export const EP_UPDATE_IZV_DUB_NMPT = '/change_izv_dub_nmpt'
export const EP_UPDATE_IZV_TZ_IZM_NAME_UPDATERESS = '/change_izv_izm_name_address'
export const EP_UPDATE_IZV_TZ_IZM_NAME = '/change_izv_izm_name'
export const EP_UPDATE_IZV_TZ_IZM_MKTU = '/change_izv_izm_mktu'
export const EP_UPDATE_IZV_TZ_IZM_UPDATERESS = '/change_izv_izm_address'
export const EP_UPDATE_IZV_IZM_OBL_IZ = '/change_izv_obl_iz'
export const EP_UPDATE_IZV_IZM_OBL_PO = '/change_izv_obl_po'
export const EP_UPDATE_IZV_IZM_OBL_PM = '/change_izv_obl_pm'
export const EP_UPDATE_IZV_IZM_OBL_SD = '/change_izv_obl_sd'
export const EP_UPDATE_IZV_TK = '/change_izv_tk'
export const EP_UPDATE_IZV_RL = '/change_izv_rl'
export const EP_UPDATE_IZV_CANCELLATION = '/change_izv_cancellation'
export const EP_UPDATE_IZV_IZM_DK = '/change_izv_izm_dk'

// 
// 

export const EP_DOWNLOAD = 'download'

let controllers = [];

export function abortAllRequests(){
    controllers.forEach((controller)=> controller.abort())
}

export function getHeaders(){
    const conf = {
    }
    return conf;
}
export function getConfigs(formData=false){
    let conf = {}

    conf['headers'] = {}

    if(formData){
        conf['headers']['Content-Type'] = 'multipart/form-data'
    }    
    const controller = new AbortController();
    controllers.push(controller)
    conf['signal'] = controller.signal

    return conf;
}


export async function getPublishedBullsList(targetYear=undefined) {
    
    const response = await api.get(`/bulletin/${EP_PUBLISHED_BULLS_LIST}${targetYear != undefined ? '?year=' + targetYear : ''}`);
    return response.data;
}

export async function getBullsInfo(dby, nby) {
    
    const response = await api.get(`/bulletin/${EP_BULLETIN_INFO}/${nby}/${dby}`);
    return response.data;
}

export async function getUnpublishedBullsList(targetYear=undefined) {
    
    const response = await api.get(`/bulletin/${EP_UNPUBLISHED_BULLS_LIST}${targetYear != undefined ? '?year=' + targetYear : ''}`);
    return response.data;
}

export async function getBullListMissed(data_source='bulletin') {
    
    const response = await api.get(`/${data_source}${EP_BULL_BULL_LIST_MISSED}`);
    return response.data;
}

export async function getBullListUploaded(data_source='bulletin') {
    
    const response = await api.get(`/${data_source}${EP_BULL_BULL_LIST_UPLOADED}`);
    return response.data;
}

export async function isPublished(dby, nby, sectionName, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IS_PUBLISHED}/${nby}/${dby}/${sectionName}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function bullIsPublished(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}/${EP_BULL_IS_PUBLISHED}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function pubBullSection(dby, nby, sectionName, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PUB_BULL_SECTION}/${nby}/${dby}/${sectionName}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function refBullSection(dby, nby, sectionName, data_source='bulletin') {
    
    const url = `/${data_source}${EP_REF_BULL_SECTION}/${nby}/${dby}/${sectionName}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function uploadBull(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPLOAD_BULL}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteBull(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_BULL}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


export async function getSDSRPatentInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_SD_PATENT_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getSDPZHPatentInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_SD_PZH_PATENT_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getSDSRPatentREQInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_SD_PATENT_REQ_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getSDPZHPatentREQInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_SD_PZH_PATENT_REQ_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZPatentInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZ_PATENT_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getPMInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PM_PATENT_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getPOInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PO_PATENT_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZINovPatentInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZ_INOV_PATENT_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getTZIZOInfo(dby, nby, data_source='bulletin') {

    
    const url = `/${data_source}${EP_TZIZO_INFO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZV_1_Info(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_1}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZV_2_Info(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_2}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlIZInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PRODL_IZ}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlPOInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PRODL_PO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlPMInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PRODL_PM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlTZInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PRODL_TZ}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlTZMKTUInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PRODL_TZ_MKTU}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlSDInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PRODL_SD}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlNMPTInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PRODL_NMPT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVPrekIZInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PREK_IZ}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVPrekPMInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PREK_PM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVPrekPOInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PREK_PO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVPrekSDInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_PREK_SD}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVVostPMInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_VOST_PM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVVostPOInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_VOST_PO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVVostIZInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_VOST_IZ}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVVostSDInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_VOST_SD}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubPMInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_DUB_PM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubIZInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_DUB_IZ}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubPOInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_DUB_PO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubTZInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_DUB_TZ}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubSDInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_DUB_SD}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubNMPTInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_DUB_NMPT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVTZIZMNameAdressInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_TZ_IZM_NAME_ADDRESS}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVTZIZMNameInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_TZ_IZM_NAME}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}
export async function getIZVTZIZMMKTUInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_TZ_IZM_MKTU}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVTZIZMAdressInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_TZ_IZM_ADDRESS}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVZIZMOblIZInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_IZM_OBL_IZ}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVZIZMOblPMInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_IZM_OBL_PM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVZIZMOblPOInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_IZM_OBL_PO}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVZIZMOblSDInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_IZM_OBL_SD}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVTKInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_TK}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVRLInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_RL}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVCancellationInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_CANCELLATION}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVIZMDKInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZV_IZM_DK}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


// ----------------------------------------------------------------------
// POINTERS ALL
// ----------------------------------------------------------------------


export async function getUKAuthorPMInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_AUTHOR_PM_PATENT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKAuthorPOInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_AUTHOR_PO_PATENT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKIZNOVMPKInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_IZ_NOV_MPK}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


export async function getUKPMPatInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_PM_PATENT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKPOPatInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_PO_PATENT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

// ====================================================================
// ============================ POINTERS ==============================
// ====================================================================

export async function getPtNumApplInvention(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_NUM_APPL_INVENTION}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtNumPatInvention(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_NUM_PAT_INVENTION}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtSysPatInvention(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_SYS_PAT_INVENTION}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtAuthInvention(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_AUTH_INVENTION}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtOwnerInvention(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_OWNER_INVENTION}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}


export async function getPtNumApplID(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_NUM_APPL_ID}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtNumPatID(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_NUM_PAT_ID}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtSysPatID(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_SYS_PAT_ID}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtAuthID(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_AUTH_ID}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtOwnerID(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_OWNER_ID}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}


export async function getPtNumApplUM(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_NUM_APPL_UM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtNumPatUM(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_NUM_PAT_UM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtSysPatUM(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_SYS_PAT_UM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtAuthUM(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_AUTH_UM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtOwnerUM(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_OWNER_UM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtNumApplTM(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_NUM_APPL_TM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

export async function getPtSysPatTM(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PT_SYS_PAT_TM}/${nby}/${dby}`
    const response = await api.get(url, getConfigs(true));
    return response.data;
}

// ====================================================================


export async function getUKPMMPKInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_PM_PATENT_MPK}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKPOMPKInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_PO_PATENT_MPK}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKTZSYSInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_TZ_SYS}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKNumZTZInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_NUM_ZTZ}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


export async function getUKOblPMInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_OBL_PM_PATENT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKOblPOInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_OBL_PO_PATENT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

// ----------------------------------------------------------------------
// POINTERS SINGLE
// ----------------------------------------------------------------------

export async function getUKAuthorIZData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_AUTHOR_IZ_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKAuthorPMData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_AUTHOR_PM_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKAuthorPOData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_AUTHOR_PO_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKIZNOVMPKData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_IZ_NOV_MPK_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKIZMPKData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_IZ_PATENT_MPK_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKPMPatData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_PM_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKPOPatData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_PO_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKIZPatData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_IZ_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKPMMPKData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_PM_PATENT_MPK_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKPOMPKData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_PO_PATENT_MPK_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKTZSYSData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_TZ_SYS_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKNumZTZData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_NUM_ZTZ_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKOblIZData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_OBL_IZ_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKOblPMData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_OBL_PM_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getUKOblPOData(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_OBL_PO_PATENT_DATA}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

// ----------------------------------------------------------------------
// POINTERS DELETE
// ----------------------------------------------------------------------

export async function deleteUKAuthorIZ(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_AUTHOR_IZ_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKAuthorPM(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_AUTHOR_PM_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKAuthorPO(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_AUTHOR_PO_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKIZNOVMPK(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_IZ_NOV_MPK}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKIZMPK(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_IZ_PATENT_MPK}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKPMPat(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_PM_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKPOPat(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_PO_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKIZPat(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_IZ_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKPMMPK(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_PM_PATENT_MPK}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKPOMPK(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_PO_PATENT_MPK}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKTZSYS(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_TZ_SYS}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKNumZTZ(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_NUM_ZTZ}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKOblIZ(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_OBL_IZ_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKOblPM(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_OBL_PM_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUKOblPO(uk_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_DELETE_OBL_PO_PATENT}/${uk_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

// ----------------------------------------------------------------------
// POINTERS ADD
// ----------------------------------------------------------------------

export async function addPtAuthInvention(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_AUTHOR_IZ_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtAuthUm(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_AUTHOR_PM_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtAuthId(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_AUTHOR_PO_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addUKIZMPK(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_IZ_PATENT_MPK}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addUKPMPat(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_PM_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addUKPOPat(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_PO_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtNumApplInvention(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_IZ_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtNumApplUm(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_PM_PATENT_MPK}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtNumApplId(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_PO_PATENT_MPK}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtSysPatTm(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_TZ_SYS}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtNumApplTm(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_NUM_ZTZ}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtOwnerInvention(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_OBL_IZ_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtOwnerUm(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_OBL_PM_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPtOwnerID(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_ADD_OBL_PO_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}


// ----------------------------------------------------------------------
// POINTERS UPDATE
// ----------------------------------------------------------------------

export async function changeUKAuthorIZ(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_AUTHOR_IZ_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKAuthorPM(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_AUTHOR_PM_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKAuthorPO(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_AUTHOR_PO_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKIZNOVMPK(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_IZ_NOV_MPK}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKIZMPK(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_IZ_PATENT_MPK}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKPMPat(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_PM_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKPOPat(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_PO_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKIZPat(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_IZ_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKPMMPK(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_PM_PATENT_MPK}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKPOMPK(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_PO_PATENT_MPK}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKTZSYS(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_TZ_SYS}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKNumZTZ(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_NUM_ZTZ}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKOblIZ(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_OBL_IZ_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKOblPM(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_OBL_PM_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function changeUKOblPO(uk_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UK_UPDATE_OBL_PO_PATENT}/${uk_id}`
    const response = await api.post(url, data);
    return response.data;
}

// ----------------------------------------------------------------------
// 
// ----------------------------------------------------------------------
export async function getNMPTInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_NMPT}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getGUInfo(dby, nby, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GU}/${nby}/${dby}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getEKSPNMPTInfo(pubDate, data_source='bulletin') {
    
    const url = `/${data_source}${EP_EKSP_NMPT}/${pubDate}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getEKSPGUInfo(pubDate, data_source='bulletin') {
    
    const url = `/${data_source}${EP_EKSP_GU}/${pubDate}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getTZPatentImage(pat_id, data_source='bulletin', eksp=false) {
    
    const url = `/${data_source}${EP_TZ_PATENT_IMAGE}/${pat_id}?eksp=${eksp}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getPOPatentImage(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_PO_PATENT_IMAGE}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getEKSPTZInfo(pubDate, data_source='bulletin') {
    
    const url = `/${data_source}${EP_EKSP_TZIZO_INFO}/${pubDate}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getEKSPSDSRInfo(pubDate, data_source='bulletin') {
    
    const url = `/${data_source}${EP_EKSP_SD_INFO}/${pubDate}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function izFormulaDownload(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_IZ_FORMULA_DOWNLOAD}/${pat_id}`
    let conf = getConfigs(true)
    conf['responseType'] = 'blob'
    const response = await api.get(url, conf);
    console.log("resp.data.type", response.data.type)
    return response;
}

export async function izFormulaUpload(dby, nby, pat_uid, gos_number_11, file, data_source='bulletin') {
    console.log("file", file)
    let formData = new FormData();  
    formData.append('file', file);  
    console.log("formData", formData)
    const url = `/${data_source}${EP_IZ_FORMULA_UPLOAD}?dby=${dby}&nby=${nby}&pat_uid=${pat_uid}&gos_number_11=${gos_number_11}`
    let conf = getConfigs(true, true)
    const response = await api.post(url, formData, conf);
    return response;
}

export async function sdTableUpload(dby, nby, pat_uid, gos_number_11, file, data_source='bulletin') {
    let formData = new FormData();  
    formData.append('file', file);  
    console.log("formData", formData)
    const url = `/${data_source}${EP_SD_TABLE_UPLOAD}?dby=${dby}&nby=${nby}&pat_uid=${pat_uid}&gos_number_11=${gos_number_11}`
    let conf = getConfigs(true, true)
    const response = await api.post(url, formData, conf);
    return response;
}

export async function ekspSDTableUpload(dby, nby, pat_uid, req_number_21, file, data_source='bulletin') {
    console.log("req_number_21", req_number_21)
    let formData = new FormData();  
    formData.append('file', file);  
    console.log("formData", formData)
    const url = `/${data_source}${EP_EKSP_SD_TABLE_UPLOAD}?dby=${dby}&nby=${nby}&pat_uid=${pat_uid}&req_number_21=${req_number_21}`
    let conf = getConfigs(true, true)
    const response = await api.post(url, formData, conf);
    return response;
}

export async function poImageUpload(dby, nby, pat_uid, gos_number_11, file, data_source='bulletin') {
    let formData = new FormData();  
    formData.append('file', file);  
    console.log("formData", formData)
    const url = `/${data_source}${EP_PO_IMAGE_UPLOAD}?dby=${dby}&nby=${nby}&pat_uid=${pat_uid}&gos_number_11=${gos_number_11}`
    let conf = getConfigs(true, true)
    const response = await api.post(url, formData, conf);
    return response;
}

export async function tzImageUpload(dby, nby, pat_uid, gos_number_11, file, data_source='bulletin') {
    let formData = new FormData();  
    formData.append('file', file);  
    console.log("formData", formData)
    const url = `/${data_source}${EP_TZ_IMAGE_UPLOAD}?dby=${dby}&nby=${nby}&pat_uid=${pat_uid}&gos_number_11=${gos_number_11}`
    let conf = getConfigs(true, true)
    const response = await api.post(url, formData, conf);
    return response;
}

export async function ekspTzImageUpload(publication_date, pat_uid, req_number_21, file, data_source='bulletin') {
    let formData = new FormData();  
    formData.append('file', file);  
    console.log("formData", formData)
    const url = `/${data_source}${EP_EKSP_TZ_IMAGE_UPLOAD}?publication_date=${publication_date}&pat_uid=${pat_uid}&req_number_21=${req_number_21}`
    let conf = getConfigs(true, true)
    const response = await api.post(url, formData, conf);
    return response;
}


export async function sdTableDownload(pat_dby, pat_nby, gosNum, data_source='bulletin') {
    
    const url = `/${data_source}${EP_SD_TABLE_DOWNLOAD}/${gosNum}?pat_dby=${pat_dby}&pat_nby=${pat_nby}`
    let conf = getConfigs(true)
    conf['responseType'] = 'blob'
    const response = await api.get(url, conf);
    return response;
}

export async function ekspSdTableDownload(gosNum, data_source='bulletin') {
    
    const url = `/${data_source}${EP_EKSP_SD_TABLE_DOWNLOAD}/${gosNum}`
    let conf = getConfigs(true)
    conf['responseType'] = 'blob'
    const response = await api.get(url, conf);
    return response;
}

// SSO


export async function getToken(username, password) {
    const url = `${EP_AUTH}`;
    const data = { username, password };
    const response = await api.post(url, data, getConfigs());
    return response;
}

export async function getUsers() {
    const url = `${EP_USERS}`;
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteUser(userId) {
    const url = `${EP_USERS}/${userId}`;
    const response = await api.delete(url, getConfigs());
    return response.data;
}

export async function getUser(userId) {
    const url = `${EP_USER}?user_id=${userId}`;
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getCreateUser(data) {
    const url = `${EP_CREATE_USER}`;
    const response = await api.post(url, data);
    return response.data;
}

export async function updateUser(data) {
    const url = `${EP_UPDATE_USER}`;
    const response = await api.post(url, data);
    return response.data;
}

export async function updatePermissions(data) {
    const url = `${EP_UPDATE_PERMISSIONS}`;
    const response = await api.post(url, data);
    return response.data;
}

export async function changePassword(data) {
    const url = `${EP_CHANGE_PASSWORD}`;
    const response = await api.post(url, data);
    return response.data;
}


// =====================================================================
// BULLETIN CHAPTERS EDIT INFO=[] DATA={}
// =====================================================================

export async function getPatentSingleData(subUrl, pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${subUrl}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


export async function getIZPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_IZ_PATENT_DATA, pat_id)
}

export async function getPMPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_PM_PATENT_DATA, pat_id)
}

export async function getPOPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_PO_PATENT_DATA, pat_id)
}

export async function getNMPTPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_NMPT_DATA, pat_id)
}

export async function getGUPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_GU_DATA, pat_id)
}

export async function getEKSPNMPTPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_EKSP_NMPT_DATA, pat_id)
}

export async function getEKSPGUPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_EKSP_GU_DATA, pat_id)
}

export async function getSDPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_SD_PATENT_DATA, pat_id)
}

export async function getTZPatentData(pat_id='bulletin') {
    return getPatentSingleData(EP_TZIZO_DATA, pat_id)
}

export async function getEkspSDPatentData(pat_id, data_source='bulletin') {
    return getPatentSingleData(EP_EKSP_SD_DATA, pat_id, data_source)
}

export async function getEkspTZPatentData(pat_id, data_source='bulletin') {
    return getPatentSingleData(EP_EKSP_TZIZO_DATA, pat_id, data_source)
}


// =====================================================================
// BULLETIN CHAPTERS ADD
// =====================================================================




export async function addIZPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZ_PATENT}`
    const response = await api.post(url, data);
    return response;
}

export async function addPMPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_PM_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addPOPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_PO_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addNMPTPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_NMPT_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addGUPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_GU_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addEKSPNMPTPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_EKSP_NMPT_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addEKSPGUPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_EKSP_GU_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addSDPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_SD_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}


export async function addTZPatent(data, data_source='bulletin') {

    
    const url = `/${data_source}${EP_ADD_TZ_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}


export async function addEKSPTZPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_EKSP_TZ_PATENT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addEKSPSDPatent(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_EKSP_SD}`
    const response = await api.post(url, data);
    return response.data;
}

// ===============================================================
// NOTICE ADD 
// ===============================================================

export async function addIZV_1(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_1}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZV_2(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_2}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVProdlIZ(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PRODL_IZ}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVProdlPO(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PRODL_PO}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVProdlPM(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PRODL_PM}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVProdlTZ(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PRODL_TZ}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVProdlSD(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PRODL_SD}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVProdlNMPT(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PRODL_NMPT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVPrekIZ(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PREK_IZ}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVPrekPM(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PREK_PM}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVPrekPO(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PREK_PO}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVPrekSD(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_PREK_SD}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVVostPM(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_VOST_PM}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVVostPO(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_VOST_PO}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVVostIZ(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_VOST_IZ}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVVostSD(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_VOST_SD}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVDubPM(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_DUB_PM}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVDubIZ(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_DUB_IZ}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVDubPO(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_DUB_PO}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVDubTZ(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_DUB_TZ}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVDubSD(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_DUB_SD}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVDubNMPT(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_DUB_NMPT}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVTZIZMNameAdress(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_TZ_IZM_NAME_ADDRESS}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVTZIZMName(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_TZ_IZM_NAME}`
    const response = await api.post(url, data);
    return response.data;
}
export async function addIZVTZIZMMKTU(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_TZ_IZM_MKTU}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVTZIZMAdress(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_TZ_IZM_ADDRESS}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVZIZMOblIZ(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_IZM_OBL_IZ}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVZIZMOblPM(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_IZM_OBL_PM}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVZIZMOblPO(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_IZM_OBL_PO}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVZIZMOblSD(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_IZM_OBL_SD}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVTK(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_TK}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVRL(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_RL}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVCancellation(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_CANCELLATION}`
    const response = await api.post(url, data);
    return response.data;
}

export async function addIZVIZMDK(data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_ADD_IZV_IZM_DK}`
    const response = await api.post(url, data);
    return response.data;
}

// ===============================================================
// NOTICE ADD 
// ===============================================================


export async function deleteIZV_1(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_1}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZV_2(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_2}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVProdlIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PRODL_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVProdlPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PRODL_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVProdlPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PRODL_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVProdlTZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PRODL_TZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVProdlSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PRODL_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVProdlNMPT(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PRODL_NMPT}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVPrekIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PREK_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVPrekPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PREK_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVPrekPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PREK_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVPrekSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_PREK_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVVostPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_VOST_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVVostPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_VOST_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVVostIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_VOST_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVVostSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_VOST_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVDubPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_DUB_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVDubIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_DUB_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVDubPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_DUB_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVDubTZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_DUB_TZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVDubSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_DUB_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVDubNMPT(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_DUB_NMPT}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVTZIZMNameAdress(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_TZ_IZM_NAME_DELETERESS}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVTZIZMName(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_TZ_IZM_NAME}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}
export async function deleteIZVTZIZMMKTU(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_TZ_IZM_MKTU}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVTZIZMAdress(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_TZ_IZM_DELETERESS}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVZIZMOblIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_IZM_OBL_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVZIZMOblPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_IZM_OBL_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVZIZMOblPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_IZM_OBL_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVZIZMOblSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_IZM_OBL_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVTK(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_TK}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVRL(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_RL}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVCancellation(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_CANCELLATION}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZVIZMDK(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZV_IZM_DK}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

// ===============================================================
// NOTICE GET 
// ===============================================================

export async function getIZV_1(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_1}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZV_2(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_2}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PRODL_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PRODL_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PRODL_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlTZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PRODL_TZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PRODL_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVProdlNMPT(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PRODL_NMPT}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVPrekIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PREK_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVPrekPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PREK_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVPrekPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PREK_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVPrekSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_PREK_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVVostPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_VOST_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVVostPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_VOST_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVVostIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_VOST_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVVostSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_VOST_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_DUB_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_DUB_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_DUB_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubTZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_DUB_TZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_DUB_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVDubNMPT(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_DUB_NMPT}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVTZIZMNameAdress(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_TZ_IZM_NAME_ADDRESS}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVTZIZMName(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_TZ_IZM_NAME}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}
export async function getIZVTZIZMMKTU(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_TZ_IZM_MKTU}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVTZIZMAdress(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_TZ_IZM_ADDRESS}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVZIZMOblIZ(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_IZM_OBL_IZ}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVZIZMOblPM(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_IZM_OBL_PM}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVZIZMOblPO(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_IZM_OBL_PO}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVZIZMOblSD(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_IZM_OBL_SD}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVTK(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_TK}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVRL(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_RL}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVCancellation(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_CANCELLATION}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getIZVIZMDK(izv_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_GET_IZV_IZM_DK}/${izv_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

// ===============================================================
// NOTICE UPDATE 
// ===============================================================



export async function updateIZV_1(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_1}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZV_2(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_2}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVProdlIZ(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PRODL_IZ}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVProdlPO(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PRODL_PO}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVProdlPM(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PRODL_PM}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVProdlTZ(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PRODL_TZ}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVProdlSD(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PRODL_SD}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVProdlNMPT(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PRODL_NMPT}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVPrekIZ(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PREK_IZ}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVPrekPM(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PREK_PM}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVPrekPO(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PREK_PO}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVPrekSD(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_PREK_SD}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVVostPM(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_VOST_PM}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVVostPO(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_VOST_PO}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVVostIZ(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_VOST_IZ}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVVostSD(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_VOST_SD}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVDubPM(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_DUB_PM}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVDubIZ(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_DUB_IZ}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVDubPO(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_DUB_PO}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVDubTZ(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_DUB_TZ}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVDubSD(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_DUB_SD}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVDubNMPT(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_DUB_NMPT}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVTZIZMNameAdress(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_TZ_IZM_NAME_UPDATERESS}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVTZIZMName(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_TZ_IZM_NAME}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}
export async function updateIZVTZIZMMKTU(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_TZ_IZM_MKTU}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVTZIZMAdress(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_TZ_IZM_UPDATERESS}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVZIZMOblIZ(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_IZM_OBL_IZ}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVZIZMOblPM(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_IZM_OBL_PM}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVZIZMOblPO(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_IZM_OBL_PO}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVZIZMOblSD(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_IZM_OBL_SD}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVTK(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_TK}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVRL(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_RL}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVCancellation(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_CANCELLATION}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateIZVIZMDK(izv_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZV_IZM_DK}/${izv_id}`
    const response = await api.post(url, data);
    return response.data;
}


// =====================================================================
// BULLETIN CHAPTERS UPDATE
// =====================================================================




export async function updateIZPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_IZ_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response;
}

export async function updatePMPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_PM_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updatePOPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_PO_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateNMPTPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_NMPT_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateGUPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_GU_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateEKSPNMPTPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_EKSP_NMPT_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateEKSPGUPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_EKSP_GU_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateSDPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_SD_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}


export async function updateTZPatent(item_id, data, data_source='bulletin') {

    
    const url = `/${data_source}${EP_UPDATE_TZ_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}


export async function updateEKSPTZPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_EKSP_TZ_PATENT}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}

export async function updateEKSPSDPatent(item_id, data, data_source='bulletin') {
    
    const url = `/${data_source}${EP_UPDATE_EKSP_SD}/${item_id}`
    const response = await api.post(url, data);
    return response.data;
}



export async function getFormulaExt(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_IZ_FORMULA_EXT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function sdHasTable(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_SD_HAS_TABLE}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function ekspSdHasTable(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_EKSP_SD_HAS_TABLE}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function izHasFormula(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_IZ_HAS_FORMULA}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getTableExt(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_SD_TABLE_EXT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function getEkspTableExt(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_EKSP_SD_TABLE_EXT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteIZFormula(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_IZ_FORMULA_DELETE}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response;
}

export async function deleteTZImage(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_TZ_PATENT_IMAGE_DELETE}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response;
}

export async function deleteEkspTZImage(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_EKSP_TZ_IMAGE_DELETE}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response;
}

export async function deleteSDTable(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_SD_TABLE_DELETE}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response;
}

export async function deleteEKSPSDTable(pat_id, data_source='bulletin') {
    const url = `/${data_source}${EP_EKSP_SD_TABLE_DELETE}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response;
}

// =====================================================================
// BULLETIN CHAPTERS DELETE
// =====================================================================




export async function deleteIZPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_IZ_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response;
}

export async function deletePMPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_PM_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deletePOPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_PO_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteNMPTPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_NMPT_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteGUPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_GU_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteEKSPNMPTPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_EKSP_NMPT_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteEKSPGUPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_EKSP_GU_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteSDPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_SD_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


export async function deleteTZPatent(pat_id, data_source='bulletin') {

    
    const url = `/${data_source}${EP_DELETE_TZ_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


export async function deleteEkspTZPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_EKSP_TZ_PATENT}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}

export async function deleteEKSPSDPatent(pat_id, data_source='bulletin') {
    
    const url = `/${data_source}${EP_DELETE_EKSP_SD}/${pat_id}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


export async function getPublishedBullsYears(data_source='bulletin') {
    
    const url = `/${data_source}/${EP_PUBLISHED_YEARS}`
    const response = await api.get(url, getConfigs());
    return response.data;
}


// DOWNLOAD

export async function getBulletinPDF(dby, nby, language) {
    let conf = getConfigs(true)
    conf['responseType'] = 'blob'
    const response = await api.get(`/bulletin/${EP_DOWNLOAD}/${language}/${nby}/${dby}`, conf);
    return response;
}
