<template>
  <div :id="modalId" class="aitu-modal">
    <div class="aitu-modal-content">
      <div v-if="header!==''" :class="headerCssClasses">
        <div>{{ header }}</div>
        <div @click="close"><span class="close">&times;</span></div>
      </div>
      <div v-if="text!==''" class="aitu-modal-body">
        {{ text }}
      </div>
      <div v-if="!noAction" class="aitu-modal-footer">
        <div class="actions">
          <div class="action">
            <button @click="approve" type="button" :class="actionCssClasses">да</button>
          </div>
          <div class="action">
            <button @click="cancel" type="button" class="full-width btn btn-primary btn-sm">отмена</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  name: 'DynamicModal',

  props: {
    modalId: {
      type: String,
      required: true
    },
  },

  data(){
    return {
      modal: document.getElementById(this.modalId),
      header: "",
      text: "",
      isDanger: false,
      afterApprove: () => {},
      afterClose: () => {},
      afterCancel: () => {},
      noAction: false,
      data: {}
    }
  },

  computed: {
    headerCssClasses(){
      return `aitu-modal-header ${ !this.isDanger ? "info" : "danger" }`
    },
    actionCssClasses(){ 
      return  `full-width btn btn-sm ${ !this.isDanger ? 'btn-success' : 'btn-danger'}` 
    }
  },

  methods: {
    approve(){
      this.softClose()
      this.afterApprove(this.data)
    },
    show(){
      this.modal.style.display = "block";
    },
    softClose(){
      this.modal.style.display = "none";
    },
    close(){
      this.modal.style.display = "none";
      this.afterClose(this.data)
    },
    cancel(){
      this.modal.style.display = "none";
      this.afterCancel(this.data)
    },

    toggleModal(){
      this.show();
    },
    fireModal({ header, text, isDanger=false, afterApprove=() => {}, afterClose=() => {}, afterCancel=() => {}, data = {}, noAction=false }){
      this.header = header
      this.text = text
      this.isDanger = isDanger
      this.afterApprove = afterApprove
      this.afterClose = afterClose
      this.afterCancel = afterCancel
      this.data = data
      this.noAction = noAction
      this.toggleModal();
    }
  },
  mounted(){
    this.modal = document.getElementById(this.modalId);
    
    window.onclick = (event) => {
      if (event.target.id == this.modal.id) {
        this.close();
      }
    }    
  }

})

</script>

<style scoped>
/* The Modal (background) */
.aitu-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}


/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Modal Header */
.aitu-modal-header {
  padding: 2px 16px;
  font-size: 16px;
  background-color: #0d6efd;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.danger {
  background-color: #dc3545;
}


/* Modal Body */
.aitu-modal-body {
  margin: 20px;
}

/* Modal Footer */
.aitu-modal-footer {
  color: white;
}

/* Modal Content */
.aitu-modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  top: 30px;
  padding: 0;
  border: 1px solid #888;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}

.action {
  width: 220px;
}

.full-width {
  width: 100%;
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 30px; opacity: 1}
}
</style>
