<template>

  <div class="not-found-page">
    <div class="side-bar">
      <Menu />
    </div>
    <div class="workspace">
      <div class="not-found-header">
        <div>404</div>
        <div>PAGE NOT FOUND</div>
      </div>
    </div>

  </div>

</template>


<script>
import { defineComponent } from 'vue';
import Menu from '../components/Menu.vue';


export default defineComponent({
  name: "NotFound404View",

  components: {
    Menu,
  },
 
})
</script>


<style scoped>

.not-found-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.side-bar {
  min-height: 100vh;
  width: 300px;
  border-right: rgb(211, 205, 205) solid;
}

.workspace {
  min-height: 100vh;
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 52px;
}

</style>
