<template>

  <div class="not-found-page">
    <div class="side-bar">
      <Menu />
    </div>
    <div class="workspace">
      <div v-if="loading">
        {{ loadingText }}
      </div>
      <div v-else class="add-user">
        <div class="section-header">
          Список бюллетней которые есть в &#60;&#60;eBULLETIN&#62;&#62; за год {{ new Date().getFullYear() }} год [{{this.uploadedBulls.length}}]
        </div>
        
        
        <div class="user-info">
          <table class="pm-doc">
            <tr>
              <th class="centered">#</th>
              <th class="centered">Дата бюллетня</th>
              <th class="centered">Номер бюллетня</th>
              <th class="centered"></th>
              <th class="centered"></th>
            </tr>
            <tr v-for="(items, index) in uploadedBulls" :key="index">
              <td>{{ index+1 }}</td>
              <td class="centered">{{ items[0] }}</td>
              <td class="centered">{{ items[1] }}</td>
              <td class="centered clickable" @click="fireDelteBull(items[0], items[1])">Удалить</td>
              <td class="centered clickable" @click="openBull(items[0], items[1])">Открыть</td>
            </tr>
          </table>             
        </div>

      </div>
    </div>
    <DynamicModal 
      :modalId="modal" 
      :ref="modal" 
    />
  </div>

</template>


<script>
import { defineComponent } from 'vue';
import Menu from '../../components/Menu.vue';
import DynamicModal from '../../components/DynamicModal.vue';
import { mapGetters } from 'vuex';
import { getBullListUploaded } from '../../services/bulletin/requests';
import { deleteBull } from '../../services/bulletin/requests';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  name: "DeleteBulletinView",

  components: {
    Menu,
    DynamicModal,
  },

  data(){
    return {
      modal: uuidv4(),
      uploadedBulls: [],
      loading: false,
      dot_counter: 0,
      loadingText: '',
      bullRefreshIntervalId: 0,

    }
  },

  computed:{
    ...mapGetters(["hasToken", "hasAdminPermission"]),
  },

  methods: {

    fireDelteBull(bull_date, bull_num){
      this.$refs[this.modal].fireModal(
        {
          header: "Удалить бюллетень", 
          text: `Вы уверены что хотите удалить бюллетень номер ${bull_num} за ${bull_date}? При необходимости его возможно повторно загрузить в систему из квладки ЗАГРУЗИТЬ БЮЛЛЕТЕНЬ, при условии что данные сохранились в системе АИС НИИС`,
          isDanger: true,
          data: { bull_date,  bull_num},
          afterApprove: this.deleteBull,
        }        
      )
    },
    deleteBull(data){
      this.loading = true
      this.bullRefreshIntervalId = setInterval(this.setLoadingText, 1000);
      deleteBull(data.bull_date, data.bull_num).then(() => {
        this.loading = false
        clearInterval(this.bullRefreshIntervalId);
        this.$router.go()
      })
    },

    openBull(timestamp, bull_num, data_source='bulletin'){
      this.$router.push(
      {
        name: 'bulletin',
        query: {timestamp, bull_num, data_source}

      })
    },

    setLoadingText(){
      this.dot_counter = this.dot_counter + 1;

      if (this.dot_counter>3){
        this.dot_counter = 0;
      }

      this.loadingText = `Загрузка ${ '.'.repeat(this.dot_counter) }`
    },


  },

  mounted () {
    if (!this.hasToken){
      this.$router.push("/login");
    }
    if (!this.hasAdminPermission){
      this.$router.push("/home");
    }

    getBullListUploaded().then((data)=> {
      this.uploadedBulls = data
      console.log("uploadedBulls", this.uploadedBulls)
    })
   
  },

})
</script>


<style scoped>

.not-found-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.users {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
}

.centered {
  text-align: center;
}


.side-bar {
  min-height: 100vh;
  width: 300px;
  border-right: rgb(211, 205, 205) solid;
}

.workspace {
  min-height: 100vh;
  width: 800px;
}

.not-found-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 52px;
}

.w300 {
  min-width: 300px;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#009da4;
  color: white;
}

.selectable {
  cursor: pointer;
}

.user-data {
  padding: 10px;
}

.add-user  {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.user-info {
  margin-bottom: 10px;
}

.delete-user-action {
  width: 220px;
  background-color: red;
  color: white;
}

.add-user-action {
  width: 220px;
}

.section-header {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}

.clickable {
  width: 200px;
}
.clickable:hover {
  cursor: pointer;
  font-weight: 800;
}
</style>
