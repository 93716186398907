export function translate(word_sequence, language='ru')
{
    var words_dictionary = {
        "ru_published": "Опубликовано",
        "kz_published": "Жарияланды",
        "ru_patent-info":"Сведения о зарегистрированных объектах промышленной собственности",
        "kz_patent-info":"Өнеркәсіптік меншік объектілері бойынша тіркелген мәліметтер",
        "ru_iz-patent-info": "Изобретения",
        "kz_iz-patent-info": "Өнертабыстар",
        "ru_pm-patent-info": "Полезная модель",
        "kz_pm-patent-info": "Пайдалы модель",
        "ru_po-patent-info": "Промышленные образцы",
        "kz_po-patent-info": "Өнеркәсіптік үлгілер",
        "ru_sd-patent-info": "Селекционное достижение",
        "kz_sd-patent-info": "Селекциялық жетістіктер",
        "ru_nmpt-patent-info": "Наименование места происхождения товаров",
        "kz_nmpt-patent-info": "Тауар шығарылған жердің атаулары",
        "ru_tz-patent-info": "Товарные знаки",
        "kz_tz-patent-info": "Тауар таңбалар",
        "ru_gu-patent-info": "Географические указания",
        "kz_gu-patent-info": "Географиялық көрсеткіштер",
        
        "ru_eksp-info": "Сведения по заявкам",
        "kz_eksp-info": "Алдын ала сараптамадан өткен өтінімдер бойынша мәліметтер",
        "ru_eksp-sd-patent-info": "Заявки на селекционное достижение",
        "kz_eksp-sd-patent-info": "Селекциялық жетістікке өтінімдер",
        "ru_eksp-nmpt-patent-info": "Заявка на наименование места происхождения товаров",
        "kz_eksp-nmpt-patent-info": "Тауар шығарылған жердің атауларына өтінім",
        "ru_eksp-tz-patent-info": "Заявки на товарные знаки",
        "kz_eksp-tz-patent-info": "Тауар таңбаларына өтінімдер",
        "ru_eksp-gu-patent-info": "Заявка на географические указания",
        "kz_eksp-gu-patent-info": "Географиялық көрсеткіштерге өтінім",

        "ru_nmpt-info": "Указание места производства товара (границы географического объекта)",
        "kz_nmpt-info": "Тауарларды өндіру орнын көрсету (географиялық объектінің шекарасы)",
        "ru_feature-info": "Описание особых свойств",
        "kz_feature-info": "Арнайы қасиеттерге сипаттама",

        "ru_izv-info": "Извещения",
        "kz_izv-info": "Хабарламалар",

        "ru_izv_12": "PC4Y/PC4A/QB1A/QB4A «Зарегистрированные договоры об уступке права на охранные документы и права на использование объектов промышленной собственности»",
        "kz_izv_12": "PC4Y/PC4A/QB1A/QB4A «Қорғау құжаттарына және өнеркәсіптік меншік объектілерін пайдалану құқығына құқықты беру туралы тіркелген шарттар»",

        "ru_izv_prodl_iz": "ND4A Продление срока действия патентов на изобретения",
        "kz_izv_prodl_iz": "ND4A Өнертабыстарға патенттердің қолданылу мерзімін ұзарту",
        "ru_izv_prodl_pm": "ND4K Продление срока действия патентов на полезные модели",
        "kz_izv_prodl_pm": "ND4K Пайдалы модельдерге патенттердің қолданылу мерзімін ұзарту",
        "ru_izv_prodl_po": "ND4L Продление срока действия патента на промышленный образец",
        "kz_izv_prodl_po": "ND4L  Өнеркәсіптік үлгіге патенттің қолданылу мерзімін ұзарту ",
        "ru_izv_prodl_sd": "Продление срока действия регистрации патентов на селекционные достижения",
        "kz_izv_prodl_sd": " Селекциялық жетістіктерге патенттерді тіркеудің қолданылу мерзімін ұзарту ",
        "ru_izv_prodl_nmpt": "Продление срока действия регистрации наименования мест происхождения товаров",
        "kz_izv_prodl_nmpt": " Тауарлар шығарылған жерлер атауларын тіркеу мерзімін ұзарту ",
        "ru_izv_prodl_tz": "Продление срока действия регистрации товарного знака",
        "kz_izv_prodl_tz": "Тауар таңбасын тіркеудің қолданылу мерзімін ұзарту ",
        "ru_izv_prodl_tz_mktu": "Продление срока действия регистрации товарного знака в части товаров",
        "kz_izv_prodl_tz_mktu": "Тауарлар бөлігінде тауар таңбасын тіркеудің қолданылу мерзімін ұзарту",

        "ru_izv_prekr_iz": "MM4A Досрочное прекращение действия патентов на изобретения",
        "kz_izv_prekr_iz": "MM4A Өнертабыстарға патенттердің қолданылуын мерзімінен бұрын тоқтату",
        "ru_izv_prekr_pm": "MM4К Досрочное прекращение действия патентов на полезные модели",
        "kz_izv_prekr_pm": "MM4K  Пайдалы модельдерге патенттердің қолданылуын мерзімінен бұрын тоқтату ",
        "ru_izv_prekr_po": "MM4L Досрочное прекращение действия патентов на промышленный образец",
        "kz_izv_prekr_po": "MM4L Өнеркәсіптік үлгідегі патенттердің қолданылуын мерзімінен бұрын тоқтату ",
        "ru_izv_prekr_sd": "Досрочное прекращение действия патентов на селекционные достижения",
        "kz_izv_prekr_sd": "Селекциялық жетістіктерге патенттердің қолданылуын мерзімінен бұрын тоқтату",

        "kz_izv_vost_iz": "NF4A Өнертабыстарға патенттердің қолданылуын қалпына келтіру",
        "ru_izv_vost_iz": "NF4A Восстановление действия патентов на изобретения",
        "kz_izv_vost_pm": "NF4K Пайдалы модельге патенттің қолданылуын қалпына келтіру",
        "ru_izv_vost_pm": "NF4K Восстановление действия патента на полезную модель",
        "kz_izv_vost_po": "NF4L Өнеркәсіптік үлгіге патенттің қолданылуын қалпына келтіру",
        "ru_izv_vost_po": "NF4L Восстановление действия патента на промышленный образец",
        "kz_izv_vost_sd": "NF4L Селекциялық жетістіктерге патенттердің қолданылуын қалпына келтіру",
        "ru_izv_vost_sd": "NF4L Восстановление действия патентов на селекционные достижения",

        "kz_izv_dub_iz": "NB1A Өнертабысқа қорғау құжатының телнұсқасын беру",
        "ru_izv_dub_iz": "NB1A Выдача дубликата охранного документа на изобретение",
        "kz_izv_dub_pm": "NB1K Пайдалы модельге қорғау құжатының телнұсқасын беру",
        "ru_izv_dub_pm": "NB1K Выдача дубликата охранного документа на полезную модель",
        "kz_izv_dub_po": "NB1L Өнеркәсіптік үлгіге қорғау құжатының телнұсқасын беру",
        "ru_izv_dub_po": "NB1L Выдача дубликата охранного документа на промышленный образец",
        "kz_izv_dub_sd": "Селекциялық жетістікке қорғау құжатының телнұсқасын беру",
        "ru_izv_dub_sd": "Выдача дубликата охранного документа на селекционное достижение",
        "kz_izv_dub_nmpt": "Тауарлар шығарылған жердің атаулары қорғау құжатының телнұсқасын беру",
        "ru_izv_dub_nmpt": "Выдача дубликата охранного документа на наименование мест происхождения товаров",
        "kz_izv_dub_tz": "Тауар таңбасына қорғау құжатының телнұсқасын беру",
        "ru_izv_dub_tz": "Выдача дубликата охранного документа на товарный знак",

        "kz_izv_obl_iz": "PD4A Өнертабыстың патент иеленушісіне өзгерістер енгізу",
        "ru_izv_obl_iz": "PD4A Внесение изменений в патентообладателя на изобретения",
        "kz_izv_obl_pm": "PD4K Пайдалы модельдің патент иеленушісіне өзгерістер енгізу",
        "ru_izv_obl_pm": "PD4K Внесение изменений в патентообладателя на полезную модель",
        "kz_izv_obl_po": "PD4L Өнеркәсіптік үлгінің патент иеленушісіне өзгерістер енгізу",
        "ru_izv_obl_po": "PD4L Внесение изменений в патентообладателя на промышленный образец",
        "kz_izv_obl_sd": "Селекциялық жетістіктің патент иеленушісіне өзгерістер енгізу",
        "ru_izv_obl_sd": "Внесение изменений в патентообладателя на селекционное достижение",

        "ru_izv_izm_name_address": "Внесение изменений в наименование и адрес владельца",
        "kz_izv_izm_name_address": " Иесінің атауына және мекенжайына өзгерістер енгізу ",
        "ru_izv_izm_address": "Внесение изменений в адрес владельца",
        "kz_izv_izm_address": "Иесінің мекенжайына өзгерістер енгізу",
        "ru_izv_izm_name": "Внесение изменений в наименование владельца",
        "kz_izv_izm_name": "Иесінің атауына өзгерістер енгізу",
        "ru_izv_izm_mktu": "Внесение изменений в МКТУ",
        "kz_izv_izm_mktu": "ТҚХС түзетулер енгізу",
        
        "ru_izv_tk": "TK Поправки",
        "kz_izv_tk": "TK Түзетулер",

        "ru_izv_rl": "Сведения о прекращении регистрации товарного знака. RL  Решение суда",
        "kz_izv_rl": "Сот шешімімен тауар таңбасының тіркеуін тоқтату туралы мәліметтер",

        "ru_izv_cancel": "Аннулирование",
        "kz_izv_cancel": "Күшін жою",

        "ru_izv_dk": "Изменения в договорах",
        "kz_izv_dk": "Шарттардағы өзгерістер",


        "ru_uk-info": "Указатели",
        "kz_uk-info": "Көрсеткіштер",
        
        "ru_uk-iz": "FG4A Нумерационный указатель заявок на изобретения (патенты)",
        "kz_uk-iz": "FG4A  Өнертабыстарға (патенттер) өтінімдердің нөмірлік көрсеткіші",
        "ru_uk-iz-pat": "FG4A Нумерационный указатель патентов на изобретения",
        "kz_uk-iz-pat": "FG4A Өнертабыстар патенттерінің нөмірлік көрсеткіші",
        "ru_uk-iz-sys": "FG4A Систематический указатель патентов на изобретения",
        "kz_uk-iz-sys": "FG4A Өнертабыстар патенттерінің жүйелік көрсеткіші",

        "ru_uk-iz-auth": "FG4A Указатель авторов изобретений (патенты)",
        "kz_uk-iz-auth": "FG4A Өнертабыстар (патенттер) авторларының көрсеткіші",
        
        "ru_uk-iz-obl": "FG4A Указатель обладателей патентов на изобретения",
        "kz_uk-iz-obl": "FG4A Өнертабысқа патент иеленушілерінің көрсеткіші",


        "ru_uk-pm-num": "FG4K Нумерационный указатель заявок на полезные модели (патенты)",
        "kz_uk-pm-num": "FG4K Пайдалы модельдерге (патенттерге) өтінімдердің нөмірлік көрсеткіші",

        "ru_uk-pm-num-pat": "FG4L Нумерационный указатель патентов на полезные модели (патенты)",
        "kz_uk-pm-num-pat": "FG4L Пайдалы модельдерге (патенттерге) патенттердің нөмірлік көрсеткіші",

        "ru_uk-pm-sys": "FG4K Систематический указатель патентов на полезные модели",
        "kz_uk-pm-sys": "FG4K Пайдалы модель патенттерінің жүйелік көрсеткіші",

        "ru_uk-pm-auth": "FG4K Указатель авторов полезных моделей (патенты)",
        "kz_uk-pm-auth": "FG4K Пайдалы модельдер (патенттер) авторларының көрсеткіші",

        "ru_uk-pm-obl": "FG4K Указатель обладателей патентов на полезные модели",
        "kz_uk-pm-obl": "FG4K Пайдалы модельдерге патент иеленушілерінің көрсеткіші",

        "ru_uk-po-num": "FG1L Нумерационный указатель заявок на промышленные образцы (патенты)",
        "kz_uk-po-num": "FG1L Өнеркәсіптік үлгілерге (патенттер) өтінімдердің нөмірлік көрсеткіші",

        "ru_uk-po-num-pat": "FG4L Нумерационный указатель патентов на промышленные образцы",
        "kz_uk-po-num-pat": "FG4L Өнеркәсіптік үлгілерге патенттердің нөмірлік көрсеткіші",

        "ru_uk-po-sys": "FG4L Систематический указатель патентов на промышленные образцы",
        "kz_uk-po-sys": "FG4L Өнеркәсіптік үлгілерге патенттердің жүйелік көрсеткіші",
        
        "ru_uk-po-auth": "FG4L Указатель авторов промышленных образцов (патенты)",
        "kz_uk-po-auth": "FG4L Өнеркәсіптік үлгілер (патенттер) авторларының көрсеткіші",

        "ru_uk-po-obl": "FG4L Указатель обладателей патентов на промышленные образцы",
        "kz_uk-po-obl": "FG4L Өнеркәсіптік үлгілерге патент иеленушілерінің көрсеткіші",

        "ru_uk-tz-sys": "Систематический указатель заявок на товарные знаки",
        "kz_uk-tz-sys": "Тауар таңбасына өтінімдердің жүйелік көрсеткіші",

        
        "ru_uk-tz-num": "Нумерационный указатель заявок на товарные знаки",
        "kz_uk-tz-num": "Тауар таңбасына өтінімдердің нөмірлік көрсеткіші",
        

        "ru_download_formula": "Скачать формулу",
        "kz_download_formula": "Формуланы жүктеп алу",
        "ru_download": "Скачать формулу",
        "kz_download": "Формуланы жүктеп алу",
        "ru_download_in_progress": " идет загрузк ",
        "kz_download_in_progress": " жүктелуде ",
        "ru_526": "Неохраноспособные элементы",
        "kz_526": "Қорғалу қабілеттігі жоқ элементтер",
        "ru_591": "Указание цветов",
        "kz_591": "Түстерді көрсету",
        "ru_shortly": "Подробнее",
        "kz_shortly": "Толығырақ",
        "ru_hide": "Скрыть",
        "kz_hide": "Жасыру",

        "ru_tbl-num": "Номер",
        "kz_tbl-num": "Нөмір",
        "ru_tbl-gos-num": "Номер охранного документа",
        "kz_tbl-gos-num": "Қорғау құжатының нөмірі",
        "ru_tbl-cert-num": "Номер свидетельства",
        "kz_tbl-cert-num": "Куәлік нөмірі",
        "ru_tbl-req-num": "Регистрационный номер",
        "kz_tbl-req-num": "Тіркеу нөмірі",
        "ru_tbl-req-num2": "Номер регистрации",
        "kz_tbl-req-num2": "Тіркеу нөмірі",
        "ru_tbl-doc-num": "Номер договора",
        "kz_tbl-doc-num": "Келісімшарт нөмірі",
        "ru_tbl-req-num-app": "Регистрационный номер заявки",
        "kz_tbl-req-num-app": "Өтінімнің тіркеу нөмірі",
        "ru_tbl-exp-date": "Дата, до которой продлен срок действия",
        "kz_tbl-exp-date": "Қолданылу мерзімі",
        "ru_tbl-tz-exp-date": "Срок продления действия товарного знака",
        "kz_tbl-tz-exp-date": "Тауар таңбасын қолданылу мерзімі",
        "ru_tbl-finish-date": "Дата досрочного прекращения",
        "kz_tbl-finish-date": "Мерзімінен бұрын тоқтату күні",
        "ru_tbl-restore-date": "Дата восстановления срока действия",
        "kz_tbl-restore-date": "Қалпына келтіру күні",
        "ru_tbl-pat-uid": "Номер заявки",
        "kz_tbl-pat-uid": "Өтінім нөмірі",
        "ru_tbl-pat-num": "Номер свидетельства",
        "kz_tbl-pat-num": "Куәлік нөмірі",
        "ru_tbl-pat-obl": "Патентообладатель",
        "kz_tbl-pat-obl": "Патент иеленуші",
        "ru_tbl-pat-obl-name-address": "Измененное наименование и адрес владельца",
        "kz_tbl-pat-obl-name-address": "Иесінің өзгертілген атауы және мекенжайы",
        "ru_tbl-pat-obl-name": "Измененное наименование владельца",
        "kz_tbl-pat-obl-name": "Иесінің өзгертілген атауы",
        "ru_tbl-pat-obl-address": "Измененный адрес владельца",
        "kz_tbl-pat-obl-address": "Иесінің өзгертілген мекенжайы",
        "ru_tbl-description": "Описание",
        "kz_tbl-description": "Сипаттама",
        "ru_tbl-type": "Тип",
        "kz_tbl-type": "Түрі",
        "ru_tbl-changes": "Правки",
        "kz_tbl-changes": "Өңдеулер",
        "ru_tbl-mpk": "МПК",
        "kz_tbl-mpk": "ХПЖ",
        "ru_tbl-mkpo": "МПКО",
        "kz_tbl-mkpo": "МПКО",
        "ru_tbl-class": "Класс",
        "kz_tbl-class": "Класс",
        "ru_tbl-mktu": "МКТУ",
        "kz_tbl-mktu": "МКТУ",
        "ru_tbl-auththor-fio": "ФИО автора патента",
        "kz_tbl-auththor-fio": "Патент авторының ТАӘ",
        "ru_tbl-owner-fio": "Наименование/ФИО патентообладателя",
        "kz_tbl-owner-fio": "Патент иеленушінің атауы/ТАӘ",    
        "ru_tbl-tz-owner-fio": "Владелец товарного знака",
        "kz_tbl-tz-owner-fio": "Тауар таңбасының иесі",    

        "ru_product-type": "Вид товара или конкретный товар:",
        "kz_product-type": "Өнім түрі немесе нақты өнім:",
        "ru_tbl-reason": "Причина",
        "kz_tbl-reason": "Себеп",
        
    }
    return words_dictionary[`${language}_${word_sequence}`]
}

