<template>
  <div class="not-found-page">
    <div class="side-bar">
      <Menu />
    </div>
    <div class="workspace">
      <div class="section-name selectable" @click="toggleAddUserSection">Добавить пользователя</div>
      <div :class="addUserSection">
        <div class="user-info">
          <div class="user-info-header">
            Имя
          </div>
          <div class="user-info-field">
            <input class="w100" type="text" @input="event => first_name = event.target.value">
          </div>
        </div>
        <div class="user-info">
          <div>
            Фамилия
          </div>
          <div>
            <input class="w100" type="text" @input="event => last_name = event.target.value">
          </div>
        </div>
        <div class="user-info">
          <div>
            Аккаунт
          </div>
          <div>
            <input class="w100" type="text" @input="event => username = event.target.value">
          </div>
        </div>
        <div class="user-info">
          <div>
            Пароль
          </div>
          <div>
            <input class="w100" type="password" @input="event => password = event.target.value">
          </div>        
        </div>
        <div class="user-info">
          <div>
            Права
          </div>
          <div>
            <input class="m4rem" id="isGuest" type="checkbox" v-model="isGuest">
            <label for="isGuest">{{getTranslatePermission('GUEST')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isAdmin" type="checkbox" v-model="isAdmin">
            <label for="isAdmin">{{getTranslatePermission('ADMIN')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isInvansionPublisher" type="checkbox" v-model="isInvansionPublisher">
            <label for="isInvansionPublisher">{{getTranslatePermission('INVANSIONS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isUtilityModelPublisher" type="checkbox" v-model="isUtilityModelPublisher">
            <label for="isUtilityModelPublisher">{{getTranslatePermission('UTILITY_MODEL_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isIndustrialDesignPublisher" type="checkbox" v-model="isIndustrialDesignPublisher">
            <label for="isIndustrialDesignPublisher">{{getTranslatePermission('INDUSTRIAL_DESIGNS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isSelectionAchievementsPublisher" type="checkbox" v-model="isSelectionAchievementsPublisher">
            <label for="isSelectionAchievementsPublisher">{{getTranslatePermission('SELECTION_ACHIEVEMENTS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isTrademarksPublisher" type="checkbox" v-model="isTrademarksPublisher">
            <label for="isTrademarksPublisher">{{getTranslatePermission('TRADEMARKS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isNMPTPublisher" type="checkbox" v-model="isNMPTPublisher">
            <label for="isNMPTPublisher">{{getTranslatePermission('NMPT_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isGUPublisher" type="checkbox" v-model="isGUPublisher">
            <label for="isGUPublisher">{{getTranslatePermission('GU_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isNoticePublisher" type="checkbox" v-model="isNoticePublisher">
            <label for="isNoticePublisher">{{getTranslatePermission('NOTICES_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isPointersPublisher" type="checkbox" v-model="isPointersPublisher">
            <label for="isPointersPublisher">{{getTranslatePermission('POINTERS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isTrademarkApplicationsPublisher" type="checkbox" v-model="isTrademarkApplicationsPublisher">
            <label for="isTrademarkApplicationsPublisher">{{getTranslatePermission('TRADEMARK_APPLICATIONS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isNMPTApplicationsPublisher" type="checkbox" v-model="isNMPTApplicationsPublisher">
            <label for="isNMPTApplicationsPublisher">{{getTranslatePermission('NMPT_APPLICATIONS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isGUApplicationsPublisher" type="checkbox" v-model="isGUApplicationsPublisher">
            <label for="isGUApplicationsPublisher">{{getTranslatePermission('GU_APPLICATIONS_PUBLISHER')}}</label>
          </div>        
          <div>
            <input class="m4rem" id="isSelectionAchievementApplicationsPublisher" type="checkbox" v-model="isSelectionAchievementApplicationsPublisher">
            <label for="isSelectionAchievementApplicationsPublisher">{{getTranslatePermission('SELECTION_ACHIEVEMENT_APPLICATIONIS_PUBLISHER')}}</label>
          </div>        
        </div>
        <div class="user-info tcentered">
          <button class="add-user-action" @click="addUser">Добавить</button>
        </div>
      </div>
      <div class="section-name">Список пользователей</div>
      <div v-if="!loading">
        <table class="users">
          <tr>
            <th class="centered">Номер</th>
            <th class="centered w300">ФИО</th>
            <th class="centered">Аккаунт</th>
            <th class="centered">Права</th>
          </tr>
          <tr class="selectable" v-for="(user, index) in users" @click="openPage(user.id)" :key="index">
            <td class="centered">{{ index+1 }}</td>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
            <td>{{ user.username }}</td>
            <td>
              <div v-for="(permission, index) in user.permissions" :key="index">
                {{ getTranslatePermission(permission) }}
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="loading">
        Загрузка
      </div>
    </div>

  </div>

</template>


<script>
import { defineComponent } from 'vue';
import Menu from '../components/Menu.vue';
import { getUsers } from '../services/bulletin/requests';
import { getCreateUser } from '../services/bulletin/requests';
import { translatePermission } from '../helpers/utils';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "UsersView",

  components: {
    Menu,
  },

  data(){
    return {
      loading: true,
      users: [],
      username: "",
      first_name: "",
      last_name: "",
      password: "",
      permissions: [],
      addUserSectionOn: false,
      isAdmin: false,
      isGuest: false,
      isInvansionPublisher: false,
      isUtilityModelPublisher: false,
      isIndustrialDesignPublisher: false,
      isSelectionAchievementsPublisher: false,
      isTrademarksPublisher: false,
      isNMPTPublisher: false,
      isGUPublisher: false,
      isNoticePublisher: false,
      isPointersPublisher: false,
      isTrademarkApplicationsPublisher: false,
      isNMPTApplicationsPublisher: false,
      isGUApplicationsPublisher: false,
      isSelectionAchievementApplicationsPublisher: false,
    }
  },

  computed:{
    ...mapGetters(["hasToken"]),
    addUserSection(){
      return this.addUserSectionOn ? 'add-user' : 'hide'
    }

  },

  methods: {
    toggleAddUserSection(){    
      this.addUserSectionOn = !this.addUserSectionOn
    },
    openPage(userId){
      this.$router.push(
      {
        name: 'user_change',
        query: { userId: userId }
      })    
    },
    getTranslatePermission(permission_name){
      return translatePermission(permission_name)
    },
    convertPermissions(){
      let userPermissions = [];
      if (this.isAdmin){
        userPermissions.push('ADMIN')
      }
      if (this.isGuest){
        userPermissions.push('GUEST')
      }
      if (this.isInvansionPublisher){
        userPermissions.push('INVANSIONS_PUBLISHER')
      }
      if (this.isUtilityModelPublisher){
        userPermissions.push('UTILITY_MODEL_PUBLISHER')
      }
      if (this.isIndustrialDesignPublisher){
        userPermissions.push('INDUSTRIAL_DESIGNS_PUBLISHER')
      }
      if (this.isSelectionAchievementsPublisher){
        userPermissions.push('SELECTION_ACHIEVEMENTS_PUBLISHER')
      }
      if (this.isTrademarksPublisher){
        userPermissions.push('TRADEMARKS_PUBLISHER')
      }
      if (this.isNMPTPublisher){
        userPermissions.push('NMPT_PUBLISHER')
      }
      if (this.isGUPublisher){
        userPermissions.push('GU_PUBLISHER')
      }
      if (this.isNoticePublisher){
        userPermissions.push('NOTICES_PUBLISHER')
      }
      if (this.isPointersPublisher){
        userPermissions.push('POINTERS_PUBLISHER')
      }
      if (this.isTrademarkApplicationsPublisher){
        userPermissions.push('TRADEMARK_APPLICATIONS_PUBLISHER')
      }
      if (this.isSelectionAchievementApplicationsPublisher){
        userPermissions.push('SELECTION_ACHIEVEMENT_APPLICATIONIS_PUBLISHER')
      }
      if (this.isNMPTApplicationsPublisher){
        userPermissions.push('NMPT_APPLICATIONS_PUBLISHER')
      }
      if (this.isGUApplicationsPublisher){
        userPermissions.push('GU_APPLICATIONS_PUBLISHER')
      }
      return userPermissions
    },

    addUser(){
      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        username: this.username,
        password:this.password,
        permissions: this.convertPermissions(),
      }
      getCreateUser(data).then(()=>{
        this.$router.go()
      })
    }
  },

  mounted () {
    if (!this.hasToken){
      this.$router.push("/login");
    }

    getUsers().then((data) => {      
      this.users = data
      this.loading = false
    })
  }
})
</script>


<style scoped>

.not-found-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.users {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.add-user  {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.centered {
  text-align: center;
}


.side-bar {
  min-height: 100vh;
  width: 300px;
  border-right: rgb(211, 205, 205) solid;
}

.workspace {
  min-height: 100vh;
  width: 800px;
}

.not-found-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 52px;
}

.w300 {
  min-width: 300px;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#8b9a9b;
  color: white;
}

.selectable {
  cursor: pointer;
}

.user-info {
  width: 50%;
  margin-top: 10px;
}

.user-info-header {
  width: 100%;
}

.user-info-field {
  width: 100%;
}

.w100 {
  width: 100%;
}

.tcentered {
  text-align: center;
}

.section-name {
  margin-left: 20px;
  margin-top: 10px;
  background-color: #009da4;
  color: white;
  width: 100%;
  padding: 10px;
}

.m4rem {
    margin: .4rem;
}
.hide {
  display: none;
}

.add-user-action {
  width: 220px;
}

.loading {
  text-align: center;
  margin: 10px;
}

</style>
