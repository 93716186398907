<template>
  <div class="not-found-page">
    <div class="side-bar">
      <Menu />
    </div>
    <div class="workspace">
      <div class="section-name">Патент на товарные знаки</div>
      <div class="bull-row">
        <div class="bull-item">
          <div class="user-info-header">
            Дата бюллетня
          </div>
          <div class="user-info-field">
            <div v-if="'pat_dby' in tzPatentData && tzPatentData.pat_dby.length>0">{{ tzPatentData.pat_dby }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Номер бюллетня
          </div>
          <div class="user-info-field">
            <div v-if="'pat_nby' in tzPatentData && tzPatentData.pat_nby.length>0">{{ tzPatentData.pat_nby }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Идентификатор патента
          </div>
          <div class="user-info-field">
            <div v-if="'pat_uid' in tzPatentData && tzPatentData.pat_uid>0">{{ tzPatentData.pat_uid }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Тип патента
          </div>
          <div class="user-info-field">
            <div v-if="'pat_type_id' in tzPatentData && tzPatentData.pat_type_id.length>0">{{ tzPatentData.pat_type_id }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Номер ОД
          </div>
          <div class="user-info-field">
            <div v-if="'gos_number_11' in tzPatentData && tzPatentData.gos_number_11.length>0">{{ tzPatentData.gos_number_11 }}</div>
            <div v-else>значение отсутствует</div>

          </div>        
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(req_number_21)">
          (21) Регистрационный номер заявки
        </div>
        <div class="user-info-field">
          <div v-if="'req_number_21' in tzPatentData && tzPatentData.req_number_21 && tzPatentData.req_number_21.length>0">{{ tzPatentData.req_number_21 }}</div>
          <div v-else>значение отсутствует</div>

        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredNull(req_date_22)" >
          (22) Дата подачи заявки        </div>
        <div class="user-info-field">
          <div v-if="'req_date_22' in tzPatentData && tzPatentData.req_date_22 && tzPatentData.req_date_22.length>0">{{ tzPatentData.req_date_22 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          Срок действия 
        </div>
        <div class="user-info-field">
          <div v-if="'field_181' in tzPatentData && tzPatentData.field_181 && tzPatentData.field_181.length>0">{{ tzPatentData.field_181 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (31)
        </div>
        <div class="user-info-field">
          <div v-if="'field_31' in tzPatentData && tzPatentData.field_31 && tzPatentData.field_31.length>0">{{ tzPatentData.field_31 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (32)
        </div>
        <div class="user-info-field">
          <div v-if="'field_32' in tzPatentData && tzPatentData.field_32 && tzPatentData.field_32.length>0">{{ tzPatentData.field_32 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (33)
        </div>
        <div class="user-info-field">
          <div v-if="'field_33' in tzPatentData && tzPatentData.field_33 && tzPatentData.field_33.length>0">{{ tzPatentData.field_33 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (730) [ru]
        </div>
        <div class="user-info-field">
          <div v-if="'field_730_ru' in tzPatentData && tzPatentData.field_730_ru && tzPatentData.field_730_ru.length>0">{{ tzPatentData.field_730_ru }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (730) [kz]
        </div>
        <div class="user-info-field">
          <div v-if="'field_730_kz' in tzPatentData && tzPatentData.field_730_kz && tzPatentData.field_730_kz.length>0">{{ tzPatentData.field_730_kz }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (526) Дискламация [ru]
        </div>
        <div class="user-info-field">
          <div v-if="'field_526_ru' in tzPatentData && tzPatentData.field_526_ru && tzPatentData.field_526_ru.length>0">{{ tzPatentData.field_526_ru }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (526) Дискламация [kz]
        </div>
        <div class="user-info-field">
          <div v-if="'field_526_kz' in tzPatentData && tzPatentData.field_526_kz && tzPatentData.field_526_kz.length>0">{{ tzPatentData.field_526_kz }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (591)
        </div>
        <div class="user-info-field">
          <div v-if="'field_591' in tzPatentData && tzPatentData.field_591 && tzPatentData.field_591.length>0">{{ tzPatentData.field_591 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          Изображение
        </div>
        <div class="user-info-field">
          <img class="image-container" :src="tzImage" alt="изображения отсутствует">
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          Классы
        </div>
        <div class="user-info-field">
          <div v-if="'field_510_511_short' in tzPatentData && tzPatentData.field_510_511_short && tzPatentData.field_510_511_short.length>0">{{ tzPatentData.field_510_511_short }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          Классы и описания
        </div>
        <div class="user-info-field">
          <div v-if="'field_510_511' in tzPatentData && tzPatentData.field_510_511 && tzPatentData.field_510_511.length>0">{{ tzPatentData.field_510_511 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>


      <br>
      <br>

    </div>
  </div>

</template>


<script>
import { defineComponent } from 'vue';
import Menu from '../../components/Menu.vue';
import { abortAllRequests } from '../../services/bulletin/requests';
import { getTZPatentData } from '../../services/bulletin/requests';
import { getTZPatentImage } from '../../services/bulletin/requests';
import { isPublished } from '../../services/bulletin/requests';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "TzPatentView",

  components: {
    Menu,
  },

  data(){
    return {
      loading: true,
      bull_num: 0,
      timestamp: '',
      data_source: '',
      tzPatentData: {},
      tzImage: '',
      gosnum: '',
      pmPatentLoading: true,
      pmPatentPublished: false,
    }
  },

  computed:{
    ...mapGetters(
      [
        "hasToken",
        'hasAdminPermission',
        'hasTrademarksPublisherPermission',
      ]
    ),


  },


  mounted () {},
  created() {
    if (!this.hasToken){
      this.$router.push("/login");
    }
    this.timestamp = this.$route.query.timestamp;
    this.bull_num = this.$route.query.bull_num;
    this.data_source = this.$route.query.data_source;
    this.gosnum = this.$route.query.gosnum;

    if (this.hasAdminPermission || this.hasTrademarksPublisherPermission){
      isPublished(this.timestamp, this.bull_num, 'TZ_PATENTS').then((data) => {
        this.pmPatentPublished = data
        console.log("this.pmPatentPublished", this.pmPatentPublished)
      })      
      getTZPatentData(this.timestamp, this.bull_num, this.gosnum, this.data_source).then((data) => {
        
        this.tzPatentData = data;
        this.pmPatentLoading = false;
        getTZPatentImage(this.tzPatentData.pat_uid, this.data_source).then((data) => {
          this.tzImage = `data:image/png;base64, ${data}`
        })
      });
    }
  },
  beforeRouteLeave(to, from, next){
    abortAllRequests()
    next();
  },  
})
</script>


<style scoped>

.not-found-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.users {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.add-user  {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.centered {
  text-align: center;
}


.side-bar {
  min-height: 100vh;
  width: 300px;
  border-right: rgb(211, 205, 205) solid;
}

.workspace {
  min-height: 100vh;
  width: 800px;
}

.not-found-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 52px;
}

.w300 {
  min-width: 300px;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#8b9a9b;
  color: white;
}

.selectable {
  cursor: pointer;
}

.user-info {
  width: 100%;
  margin-left: 20px;
  margin-top: 15px;
}

.user-info-header {
  font-size: 11px;
  width: 100%;
}

.user-info-field {
  width: 100%;
  text-align: justify;
}

.w100 {
  width: 100%;
}

.tcentered {
  text-align: center;
}

.section-name {
  margin-left: 20px;
  margin-top: 10px;
  background-color: #009da4;
  color: white;
  width: 100%;
  padding: 10px;
}

.m4rem {
    margin: .4rem;
}
.hide {
  display: none;
}

.add-user-action {
  width: 220px;
}

.loading {
  text-align: center;
  margin: 10px;
}

.download-formula {
  cursor: pointer;
}

.download-formula:hover {
  font-weight: 600;
}

.bull-row{
  display: flex;
  width: 100%;
  margin-left: 20px;
  margin-top: 15px;
  padding-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: rgb(205 205 205) solid 1px;
}

.bull-item{
  text-align: justify;
}

.image-container {
  text-align: center;
  width: 400px;
  padding: 20px;
}

</style>
