import axios from 'axios';

// export const apiUrl = 'http://127.0.0.1:6001';
// export const apiUrl = 'http://192.168.42.7:6001';
export const apiUrl = 'https://ebulletin.kazpatent.kz:6002';


const api = axios.create({
    baseURL: apiUrl,
})

export { api };
