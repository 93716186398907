<template>
  <div class="not-found-page">
    <div class="side-bar">
      <Menu />
    </div>
    <div class="workspace">
      <div class="section-name">Патент на полезную модель</div>
      <div class="bull-row">
        <div class="bull-item">
          <div class="user-info-header">
            Дата бюллетня
          </div>
          <div class="user-info-field">
            <div v-if="'pat_dby' in pmPatentData && pmPatentData.pat_dby.length>0">{{ pmPatentData.pat_dby }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Номер бюллетня
          </div>
          <div class="user-info-field">
            <div v-if="'pat_nby' in pmPatentData && pmPatentData.pat_nby.length>0">{{ pmPatentData.pat_nby }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Идентификатор патента
          </div>
          <div class="user-info-field">
            <div v-if="'pat_uid' in pmPatentData && pmPatentData.pat_uid>0">{{ pmPatentData.pat_uid }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Тип патента
          </div>
          <div class="user-info-field">
            <div v-if="'pat_type_id' in pmPatentData && pmPatentData.pat_type_id.length>0">{{ pmPatentData.pat_type_id }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            (13) Код
          </div>
          <div class="user-info-field">
            <div v-if="'code_13' in pmPatentData && pmPatentData.code_13.length>0">{{ pmPatentData.code_13 }}</div>
            <div v-else>значение отсутствует</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            (11) Номер ОД
          </div>
          <div class="user-info-field">
            <div v-if="'gos_number_11' in pmPatentData && pmPatentData.gos_number_11.length>0">{{ pmPatentData.gos_number_11 }}</div>
            <div v-else>значение отсутствует</div>

          </div>        
        </div>
      </div>


      <div class="user-info">
        <div class="user-info-header">
          (51)
        </div>
        <div class="user-info-field">
          <div v-if="'icp_51' in pmPatentData && pmPatentData.icp_51 && pmPatentData.icp_51.length>0">{{ pmPatentData.icp_51 }}</div>
          <div v-else>значение отсутствует</div>

        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(req_number_21)">
          (21) Регистрационный номер заявки
        </div>
        <div class="user-info-field">
          <div v-if="'req_number_21' in pmPatentData && pmPatentData.req_number_21 && pmPatentData.req_number_21.length>0">{{ pmPatentData.req_number_21 }}</div>
          <div v-else>значение отсутствует</div>

        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredNull(req_date_22)" >
          (22) Дата подачи заявки        </div>
        <div class="user-info-field">
          <div v-if="'req_date_22' in pmPatentData && pmPatentData.req_date_22 && pmPatentData.req_date_22.length>0">{{ pmPatentData.req_date_22 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (31)
        </div>
        <div class="user-info-field">
          <div v-if="'field_31' in pmPatentData && pmPatentData.field_31 && pmPatentData.field_31.length>0">{{ pmPatentData.field_31 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (32)
        </div>
        <div class="user-info-field">
          <div v-if="'field_32' in pmPatentData && pmPatentData.field_32 && pmPatentData.field_32.length>0">{{ pmPatentData.field_32 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (33)
        </div>
        <div class="user-info-field">
          <div v-if="'field_33' in pmPatentData && pmPatentData.field_33 && pmPatentData.field_33.length>0">{{ pmPatentData.field_33 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (85) Дата перевода международной заявки  на национальную фазу
        </div>
        <div class="user-info-field">
          <div v-if="'date_85' in pmPatentData && pmPatentData.date_85 && pmPatentData.date_85.length>0">{{ pmPatentData.date_85 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (86)
        </div>
        <div class="user-info-field">
          <div v-if="'field_86' in pmPatentData && pmPatentData.field_86 && pmPatentData.field_86.length>0">{{ pmPatentData.field_86 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(author_72_ru)">
          (72)  Автор(ы) [ru]
        </div>
        <div class="user-info-field">
          <div v-if="'author_72_ru' in pmPatentData && pmPatentData.author_72_ru && pmPatentData.author_72_ru.length>0">{{ pmPatentData.author_72_ru }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(author_72_kz)">
          (72)  Автор(ы) [kz]
        </div>
        <div class="user-info-field">
          <div v-if="'author_72_kz' in pmPatentData && pmPatentData.author_72_kz && pmPatentData.author_72_kz.length>0">{{ pmPatentData.author_72_kz }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(owner_73_ru)">
          (73) [ru]
        </div>
        <div class="user-info-field">
          <div v-if="'owner_73_ru' in pmPatentData && pmPatentData.owner_73_ru && pmPatentData.owner_73_ru.length>0">{{ pmPatentData.owner_73_ru }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(owner_73_kz)">
          (73) [kz]
        </div>
        <div class="user-info-field">
          <div v-if="'owner_73_kz' in pmPatentData && pmPatentData.owner_73_kz && pmPatentData.owner_73_kz.length>0">{{ pmPatentData.owner_73_kz }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(name_540_ru)">
          (540) Название [ru]
        </div>
        <div class="user-info-field">
          <div v-if="'name_540_ru' in pmPatentData && pmPatentData.name_540_ru && pmPatentData.name_540_ru.length>0">{{ pmPatentData.name_540_ru }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(name_540_kz)">
          (540) Название [kz]        </div>
        <div class="user-info-field">
          <div v-if="'name_540_kz' in pmPatentData && pmPatentData.name_540_kz && pmPatentData.name_540_kz.length>0">{{ pmPatentData.name_540_kz }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedNullStr(patentFile, ref_57)">
          ref_57
        </div>
        <div class="user-info-field">
          <div v-if="'ref_57' in pmPatentData && pmPatentData.ref_57 && pmPatentData.ref_57.length>0">{{ pmPatentData.ref_57 }}</div>
          <div v-else>значение отсутствует</div>
        </div>
      </div>
      <br>
      <br>

    </div>
  </div>

</template>


<script>
import { defineComponent } from 'vue';
import Menu from '../../components/Menu.vue';
import { getPMPatentData } from '../../services/bulletin/requests';
import { isPublished } from '../../services/bulletin/requests';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "PmPatentView",

  components: {
    Menu,
  },

  data(){
    return {
      loading: true,
      bull_num: 0,
      timestamp: '',
      data_source: '',
      pmPatentData: {},
      gosnum: '',
      pmPatentLoading: true,
      pmPatentPublished: false,
    }
  },

  computed:{
    ...mapGetters(
      [
        "hasToken",
        'hasAdminPermission',
        'hasUtilityModelPublisherPermission',
      ]
    ),


  },


  mounted () {},
  created() {
    if (!this.hasToken){
      this.$router.push("/login");
    }
    this.timestamp = this.$route.query.timestamp;
    this.bull_num = this.$route.query.bull_num;
    this.data_source = this.$route.query.data_source;
    this.gosnum = this.$route.query.gosnum;

    if (this.hasAdminPermission || this.hasUtilityModelPublisherPermission){
      isPublished(this.timestamp, this.bull_num, 'PM_PATENTS').then((data) => {
        this.pmPatentPublished = data
        console.log("this.pmPatentPublished", this.pmPatentPublished)
      })      
      getPMPatentData(this.timestamp, this.bull_num, this.gosnum, this.data_source).then((data) => {
        console.log("data", data)
        this.pmPatentData = data;
        this.pmPatentLoading = false;
      });
    }
  }
})
</script>


<style scoped>

.not-found-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.users {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.add-user  {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.centered {
  text-align: center;
}


.side-bar {
  min-height: 100vh;
  width: 300px;
  border-right: rgb(211, 205, 205) solid;
}

.workspace {
  min-height: 100vh;
  width: 800px;
}

.not-found-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 52px;
}

.w300 {
  min-width: 300px;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#8b9a9b;
  color: white;
}

.selectable {
  cursor: pointer;
}

.user-info {
  width: 100%;
  margin-left: 20px;
  margin-top: 15px;
}

.user-info-header {
  font-size: 11px;
  width: 100%;
}

.user-info-field {
  width: 100%;
  text-align: justify;
}

.w100 {
  width: 100%;
}

.tcentered {
  text-align: center;
}

.section-name {
  margin-left: 20px;
  margin-top: 10px;
  background-color: #009da4;
  color: white;
  width: 100%;
  padding: 10px;
}

.m4rem {
    margin: .4rem;
}
.hide {
  display: none;
}

.add-user-action {
  width: 220px;
}

.loading {
  text-align: center;
  margin: 10px;
}

.download-formula {
  cursor: pointer;
}

.download-formula:hover {
  font-weight: 600;
}

.bull-row{
  display: flex;
  width: 100%;
  margin-left: 20px;
  margin-top: 15px;
  padding-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: rgb(205 205 205) solid 1px;
}

.bull-item{
  text-align: justify;
}
</style>
