<template>
  <div class="login-page">
    <div class="page-content">
      <div class="information">
        <div class="name">
          <span>eБЮЛЛЕТЕНЬ</span>
        </div>
        <div class="description">
          <span>система электронный бюллетень</span>
        </div>
      </div>
      <div class="login">
        <login 
          @access-token-retrieved="applyToken" 
        />
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from 'vue';
import { mapActions, mapGetters } from "vuex";
import Login from '../components/Login.vue';


export default defineComponent({
  name: 'LoginView',

  components: {
    Login,
  },
    

  computed: {
    ...mapGetters([
      "hasAuthToken"
    ])
  },

  methods: {
    ...mapActions([
      "setUserData"
    ]),
    applyToken(userDate){
      this.setUserData(userDate);
      this.$router.push(
      {
        name: 'home',
        query: { targetYear: new Date().getFullYear() }
      })    
    }
  }

})

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

.login-page {
  height: 100vh;
}
 

.page-content {
  display: flex;
  flex-direction: row;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.information {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.name {
  font-size: 80px;
  font-family: 'Russo One', sans-serif;
  color: #62a393;
}

.description {
  font-size: 18px;
  font-family: 'Russo One', sans-serif;
  color: #62a393;
}


.login {
  flex-grow: 1;
  display: flex;
  justify-content: left;
  align-items: center;
}

</style>

