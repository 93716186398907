<template>
  <div class="menu">
    <div class="current-bulletin">
      Сведения, помещенные в данном бюллетене,
      считаются опубликованными 2011.01.17      
    </div>
    <hr>
    <div 
        v-for="(archiveYear, index) in publishedYears" 
        :key="index" 
        @click="this.openBullList(archiveYear)"
      >
      <div v-for="(propertyValue, propertyName) in bullBullsData" :key="propertyValue" :class="menuClass(archiveYear)">
        {{propertyValue}} [{{propertyName}}`{{archiveYear}}] 
      </div>
    </div>
  </div>

</template>

<script>

import { defineComponent } from '@vue/runtime-core';
import { getPublishedBullsYears } from '../services/bulletin/requests';
import { getPublishedBullsList } from '../services/bulletin/requests';
import { translatePermission } from '../helpers/utils';



export default defineComponent({
  name: 'Menu',

  data(){
    return {
      menuName: "",
      publishedYears: [],
      bullBullsData: {},
      bullLoading : true
    }
  },

  computed: {

    archiveYears(){
      let firstYear = 2018;
      let lastYear = new Date().getFullYear();
      let arrYears = [];
      while (firstYear != lastYear){
        lastYear --
        arrYears.push(lastYear)
      }
      return arrYears
    },
  },

  methods: {
    getTranslatePermission(permission_name){
      return translatePermission(permission_name)
    },

    menuClassByPath(path){
      return this.$route.path == path ? "menu-item menu-active-item" : "menu-item"
    },
    
    menuClass(){
      // if ("targetYear" in this.$route.query && this.$route.query.targetYear == targetYear){
      //   return "menu-item menu-active-item"
      // }
      // if ("timestamp" in this.$route.query && this.$route.query.timestamp.substring(0, 4) == targetYear){
      //   return "menu-item menu-active-item"
      // }

      return "menu-item"
    },
    openPage(page){
      this.$router.push(page)
    },

    openBullList(targetYear){
      let tmp_targetYear = this.$route.query.targetYear;
      if (tmp_targetYear == targetYear){
        this.$router.go()
      }
      this.$router.push(
      {
        name: 'home',
        query: { targetYear: targetYear }
      })    
    },
    loadBulls(){
      // clearInterval(this.niisRefreshIntervalId);
      // this.niisRefreshIntervalId = setInterval(this.setLoadingText, 1000);

      clearInterval(this.bullRefreshIntervalId);
      this.bullRefreshIntervalId = setInterval(this.setLoadingText, 1000);
      
      getPublishedBullsList(this.$route.query.targetYear).then((data) => {
        this.bullBullsData = data;
        clearInterval(this.bullRefreshIntervalId);
        this.bullLoading = false;
      })
    }
  },

mounted () {
  getPublishedBullsYears().then((data)=> {
    this.publishedYears = data
  }) 
  this.loadBulls() 
  }  

})

</script>

<style scoped>
.menu-item{
  padding: 5px;
}

.menu-item:hover{
  font-weight: 500;
  cursor: pointer;
}


.menu-active-item{
  border-right: rgb(212, 108, 10) solid;
  background-color: rgb(238, 238, 238);
  font-weight: 600;
}

.menu-group .menu-item {
  margin-left: 10px;
}
.menu-group-header {
  text-transform: uppercase;
  color: gray;
  padding: 5px;
}

.profile {
  padding: 10px;
}

.signout{
  padding-left: 10px
}
.signout:hover {
  cursor: pointer;
  font-weight: 600;
}

.user-permissions{
  font-size: 11px;

}

.current-bulletin {
  text-align: justify;
  margin-top: 10px;
  padding: 10px;
  font-size: 18px;
}
</style>
