<template>

<div class="home-page">
  <div class="workspace">
    <div class="content-place">


      <div class="header-content title-row">
        <div class="bulletin-content">
          <div>
            <div class="margin-bottom-less">
              Қазақстан Республикасы Әділет министрлігі
            </div>
            <div class="margin-bottom-less">
              "Ұлттық зияткерлік меншік институты"   
            </div>
            <div class="margin-bottom-less">
              Республикалық мемлекеттік кәсіпорны
            </div>
          </div>
          <div class="official-bulletin">
            Ресми бюллетень
          </div>
        </div>
        <div class="pdf-content img-container">
          <div class="img-container">
            <div class="gerb-container">
              <img class="gerb-img" src="../assets/logo-kaz.png" alt="">
            </div>
          </div>
        </div>
        <div class="bulletin-content">
          <div>
            <div class="margin-bottom-less">
              Республиканское государственное предприятие
            </div>
            <div class="margin-bottom-less">
              "Национальный институт интеллектуальной собственности"
            </div>
            <div class="margin-bottom-less">
              Министерства юстиции Республики Казахстан
            </div>
          </div>
          <div class="official-bulletin">
            Официальный бюллетень
          </div>
        </div>
      </div>

      <div class="content-row" 
        v-for="[bull_num, bull_date] of Object.entries(bullBullsData)" 
        :key="bull_num" 
      >
        <div class="bulletin-content align-right onhover" @click="openBull(bull_date, bull_num, 'bulletin', 'kz')">
          <div>
            <div class="margin-bottom-less">
              Осы бюллетеньге орналастырылған мәліметтер
            </div>
            <div class="margin-bottom-less">
              {{bull_date}} жарияланған болып есептеледі
            </div>
          </div>
        </div>
        <div class="pdf-content align-center">
          <div>[{{bull_date}}]</div>
          <div class="download-file">
            <div class="download-icon" @click="downloadPdf(bull_date, bull_num,'KZ')">[KZ]</div>
            <div class="download-icon" @click="downloadPdf(bull_date, bull_num,'RU')">[RU]</div>
          </div>
        </div>
        <div class="bulletin-content align-left onhover" @click="openBull(bull_date, bull_num, 'bulletin', 'ru')">
          <div>
            <div class="margin-bottom-less">
              Сведения, помещенные в настоящем бюллетене,
            </div>
            <div class="margin-bottom-less">
              считаются опубликованными {{bull_date}}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="footer-place">

      <div class="pre-footer-content title-row">
        <div class="bulletin-content img-container">
          <div>
            <div class="margin-bottom-less">
              © ҚР ӘМ "ҰЗМИ" РМК
          </div>
        </div>
      </div>
      <div class="pdf-content img-container">
        АСТАНА
      </div>
      <div class="bulletin-content img-container">
        <div>
          © РГП "НИИС" МЮ РК
        </div>
      </div>
    </div>    

      <div class="footer-content title-row">
        <div class="bulletin-content img-container">
          <div>
            <div class="margin-bottom-less">
              010000, Қазақстан Республикасы, Астана қ., 
            </div>
            <div class="margin-bottom-less">
              Мәңгілік Ел даңғылы, 57А
            </div>
            <div class="margin-bottom-less">
              Тел.: 8 7172 621515
            </div>
          </div>
        </div>
        <div class="pdf-content img-container">
          <div class="img-container">
              <img class="logo" src="../assets/logo2.png" alt="">
          </div>
        </div>
        <div class="bulletin-content img-container">
          <div>
            <div class="margin-bottom-less">
              010000, Республика Казахстан, г. Астана, 
            </div>
            <div class="margin-bottom-less">
              проспект Мәңгілік Ел, 57А
            </div>
            <div class="margin-bottom-less">
              Тел.: 8 7172 621515
            </div>
          </div>
          </div>
        </div>      

    </div>
    <DynamicModal 
      :modalId="modal" 
      :ref="modal" 
    />    

  </div>
</div>

</template>


<script>
import { defineComponent } from '@vue/runtime-core';

// import { bullIsPublished } from '../services/bulletin/requests';
// import { getBullsInfo } from '../services/bulletin/requests';
import { getPublishedBullsList } from '../services/bulletin/requests';
import { getBulletinPDF } from '../services/bulletin/requests';
import { izHasFormula } from '../services/bulletin/requests';
import { izFormulaDownload } from '../services/bulletin/requests';
import { v4 as uuidv4 } from 'uuid';
import DynamicModal from '../components/DynamicModal.vue';

export default defineComponent({
  name: "BulletinsListView",

  data(){
    return {
      modal: uuidv4(),
      dot_counter: 0,
      // niisLoading: true,
      bullLoading: true,
      loadingText: '',
      // niisBullsData: [],
      bullBullsData: {},
      published: {},
      // niisRefreshIntervalId: 0,
      bullRefreshIntervalId: 1,
      showBuletinsOn: false,
      // showNiisOn: false,
    }
  },

  components: {
    DynamicModal
  },

  watch: {
      '$route.query.targetYear'() {
          this.loadBulls();
      }
  },

  computed: {
   
    getYear(){
      if (this.bullBullsData && 1 in this.bullBullsData){
        return this.bullBullsData[1].substring(0, 4) 
      }
      return ''
    },

  },


  methods: {
    isPublishedClass(bull_num, bull_date){
      return this.isPublished(bull_num, bull_date) ? 'published' : ''
    },

    isPublished(bull_num, bull_date){
      return `${bull_num}_${bull_date}` in this.published 
    },

    scrollMeTo(elementId) {
      var element = document.getElementById(elementId);
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },


    tzToggleClasses(data_source){
      if(data_source=='bulletin'){
        this.showBuletinsOn = !this.showBuletinsOn
        console.log("data_source", this.showBuletinsOn)
      }
        
    },

    openBull(timestamp, bull_num, data_source, language){
      this.$router.push(
      {
        name: 'bulletin',
        query: {timestamp, bull_num, data_source, language}

      })
    },

    firePDF404(bull_date, bull_num, language){
      this.$refs[this.modal].fireModal(
        {
          header: `Файл не найден!`, 
          text: `Файл pdf не сформирован для бюллетня номер ${bull_num} с датой публикации ${bull_date} на ${language} языке.`,
          isDanger: true,
          noAction: true,
        }        
      )
    },

    downloadFormula(pat_id, gos_number_11){
      izHasFormula(pat_id).then((hasFormula) => {
        if (hasFormula){
          izFormulaDownload(pat_id, this.data_source).then(resp => {
            var blob = new Blob([resp.data], { type: resp.data.type });
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            console.log("AA resp.data.type", resp.data.type)
            // set the name of the file
            let fileFormat = 'doc'
            if (resp.data.type=='application/msword'){
              fileFormat = 'doc'
            }
            if (resp.data.type=='application/vnd.oasis.opendocument.text'){
              fileFormat = 'odt'
            }
            if (resp.data.type=='application/pdf'){
              fileFormat = 'pdf'
            }

            link.download =  `${gos_number_11}.${fileFormat}`;
            // clicking the anchor element will download the file
            link.click();    
          });
        } else {
          this.$refs[this.modal].fireModal(
            {
              header: `Ошибка!`, 
              text: `Файл для скачивания не найден! Этот объект не имеет вложения!`,
              isDanger: true,
              noAction: true,
            }        
          )
        }
      })
    },


    downloadPdf(bull_date, bull_num, language){
      getBulletinPDF(bull_date, bull_num, language).then(resp => {
            var blob = new Blob([resp.data], { type: resp.data.type });
            var link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            // set the name of the file
            let fileFormat = 'pdf'
            link.download =  `${bull_date}_${bull_num}_${language.toLowerCase()}.${fileFormat}`;
            link.click();    
        }).catch((error) => {
          if (error.response.status == 404){
            this.firePDF404(bull_date, bull_num, language)
          }        
        
      })
    },
    setLoadingText(){
      this.dot_counter = this.dot_counter + 1;

      if (this.dot_counter>3){
        this.dot_counter = 0;
      }

      this.loadingText = `Загрузка ${ '.'.repeat(this.dot_counter) }`
    },


    loadBulls(){
      // clearInterval(this.niisRefreshIntervalId);
      // this.niisRefreshIntervalId = setInterval(this.setLoadingText, 1000);

      clearInterval(this.bullRefreshIntervalId);
      this.bullRefreshIntervalId = setInterval(this.setLoadingText, 1000);
      console.log("this.$route.query.targetYear", this.$route.query.targetYear)
      getPublishedBullsList(this.$route.query.targetYear).then((data) => {
        this.bullBullsData = data;
        console.log("this.bullBullsData", this.bullBullsData)
        console.log("this.bullBullsData", )
        clearInterval(this.bullRefreshIntervalId);
        this.bullLoading = false;
      })
    },    
  },

  mounted () {

    this.loadBulls()
  }
})
</script>


<style scoped>

.home-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: rgb(237 240 242); */
}

.workspace {
  display: flex;
  flex-direction: column;

}
.content-place {
  min-height: 85vh;
  padding-top: 10px;
  width: 1100px;
  /* padding: 15px; */
  /* border-left: rgb(211, 205, 205) solid; */
}

.title-row {
  display: flex;
  flex-direction: row;
}
.content-row {
  display: flex;
  flex-direction: row;
  margin-top:10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #017179;

}

.bulletin-content {
  width: 475px;
}

.onhover:hover {
  cursor:pointer;
  color: #017179
  /* background-color: #017179; */
}

.align-left{
  text-align: left;
}

.align-right{
  text-align: right;
}

.align-center{
  text-align: center;
}

.pdf-content {
  width: 150px;
  
}


.header-content {
  background-color: #017179;
  color: white;
  /* font-weight: 600; */
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  padding: 10px;
  text-align: center;
  /* -webkit-text-stroke: black 0.1px; */

}

.pre-footer-content {
  background-color: #017179;
  font-size: 12px;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  padding: 10px;
  text-align: center;
  
}

.footer-content {
  font-size: 14px;
  font-family: 'Times New Roman', Times, serif;
  /* padding: 10px; */
  text-align: center;
  margin-top:auto;
}

.img-container {
  margin: auto;
}


.margin-bottom-less {
  margin: -5px
}

.logo {
  width: 100px;
  height: auto;
  padding: 5px;
  border-radius: 50px
}


.official-bulletin {
  border-top: white solid 1px;
  margin-top: 10px;
}

.border-bottom{
  border-bottom: 10px solid #017179  ;
}










.year-info {
  font-weight: 600;

}

.bulls-data {
  margin-bottom: 10px;
}

.date-info {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  /* text-indent: 30px; */
  
}

.published {
  padding-left: 10px;
  border-left: rgb(181 209 218) 5px solid;
  width: 100%;
}

.published:hover {
  padding-left: 10px;
  border-left: white 5px solid;
  width: 100%;
}

.date-info:hover {
  cursor: pointer;
  background-color: rgb(181, 209, 218);
  /* border-left: 1px solid rgb(1, 113, 121); */
}

.action-container {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.action {
}

.action:hover {
  font-weight: 600;
  color: white;
  /* text-transform: uppercase; */
}

.menu-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 300px;
  /* text-align: right; */
}

.page-header {
  text-align: center;
}

.page-header:hover {
  cursor: pointer;
}


.hiden {
  display: none;
}

@media (max-width:1280px) { 
.menu-fixed {
  width: 150px;
}
 }

.display-row{
  display: flex;
  background-color: rgb(1, 113, 121);
  color: white;
  padding: 10px;
  border-radius: 5px;

}

.company-name {
  margin: auto;
  font-size: 18px;
}

.download-file {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding: 20px; */
}

.download-icon{
  margin: 5px;
}

.download-icon:hover{
  cursor: pointer;
  font-weight: 600;
}

.gerb-container {
  background-color: #7aabaf38;
  border-radius: 50px;
  height: auto;
  width: 100px;
  margin: auto;
  padding: 1px;  
}

.gerb-img {
  width: auto;
  height: 100px;
  padding: 1px;
  border-radius: 50px;  
}
</style>
