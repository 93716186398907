<template>
  <div>
    <div class="login-form">
      <div class="login-content">
        <div class="login-input">
          <div class="mb-3">
            <input :value="username" name="username" @input="username = $event.target.value" type="text" class="form-control form-control-sm" placeholder="Username">
          </div>        
        </div>
        <div class="login-input">
          <div class="mb-3">
            <input :value="password" @input="password = $event.target.value" type="password" class="form-control form-control-sm" placeholder="password">
          </div>        
        </div>
        <div class="login-input">
          <div class="d-grid gap-2">
            <button @click="login" type="button" class="btn btn-primary btn-sm">войти</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="getErrorMessage!=''" class="login-error">
      <div class="login-content">{{ getErrorMessage }}</div>
    </div>

  </div>

</template>

<script>

import { getToken } from '../services/bulletin/requests';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  name: 'Login',

  data(){
    return {
      username: "",
      password: "",
      errorMessage: "",
    }
  },

  computed: {
    getErrorMessage(){
      return this.errorMessage;
    },

  },

  methods: {
    login(){
      //eslint-disable-next-line
      const format = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/;
      this.errorMessage = ""

      if (this.username === "" || format.test(this.username)){
        this.errorMessage = "Имя пользователя содержет пустой символ и/или недопустимый!"
        return
      } else {
        this.errorMessage = ""
      }

      if (this.password === "" || this.password.indexOf(" ")>0){
        this.errorMessage = "Пароль содержет пустой символ!"
        return
      } else {
        this.errorMessage = ""
      }
      getToken(this.username, this.password).then((response) => {
        this.$emit("access-token-retrieved", response.data);
      }).catch((error) => {
        if (error.response.status == 401){
          this.errorMessage = 'Логин или пароль содержит ошибку!'
        }        
        
      })
    }
  }

})

</script>

<style scoped>


.login-content {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
.login-form {
  position: relative;
  width: 400px;
  height: 180px;
  margin: 0;
  padding: 0;
  background-color: white;
  box-shadow: rgb(200, 205, 221) 0px 0px 20px;
  border-radius: 5px;
  
}

.login-error {
  position: relative;
  width: 400px;
  height: 50px;
  margin-top: 10px;
  padding: 0;
  background-color: white;
  box-shadow: rgb(200, 205, 221) 0px 0px 20px;
  border-radius: 5px;
  color: red;
  
}

.login-header {
  margin: 30px;
  text-align: center;
  font-family: 'Russo One', sans-serif;
  color: #62a393;
}


.login-input {
  margin: 0px 20px 0px 20px;
}

</style>
