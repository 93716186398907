<template>
  <div class="not-found-page">
    <div class="side-bar">
      <Menu />
    </div>
    <div class="workspace">
      <div id="top" class="section-name">Патент на товарные знаки</div>
      <div class="bull-row">
        <div class="bull-item">
          <div class="user-info-header">
            Дата бюллетня
          </div>
          <div class="user-info-field">
            <div>{{ timestamp }}</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Номер бюллетня
          </div>
          <div class="user-info-field">
            <div>{{ bull_num }}</div>
          </div>
        </div>


        <div class="bull-item">
          <div class="user-info-header">
            Тип патента
          </div>
          <div class="user-info-field">
            <div>4</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            (13) Код
          </div>
          <div class="user-info-field">
            <div>TM</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            (21) Регистрационный номер заявки
          </div>
          <div class="user-info-field">
            <div>{{ req_number_21 }}</div>
          </div>
        </div>

        
      </div>

      <div class="user-info">
        <div :class="checkRequiredInt(pat_uid)">
          Идентификатор патента
        </div>
        <div class="user-info-field">
          <input type="text" v-model="pat_uid"/>
        </div>
      </div>


      <div class="user-info">
        <div class="user-info-header">
          (151) 
        </div>
        <div class="user-info-field">
          <Datepicker :locale="lang" inputFormat="yyyy-MM-dd" v-model="gos_date_11" />
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredNull(req_date_22)" >
          (22) Дата подачи заявки        </div>
        <div class="user-info-field">
          <Datepicker :locale="lang" inputFormat="yyyy-MM-dd" v-model="req_date_22" />
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (31)
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="field_31"
          ></textarea>        
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (32)
        </div>
          <textarea
            rows="1" cols="50"
            v-model="field_32"
          ></textarea>        
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedStr(field_31, field_32, field_33)">
          (33)
        </div>
          <textarea
            rows="1" cols="50"
            v-model="field_33"
          ></textarea>        
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(field_731_ru)">
          (731) [ru]
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="field_731_ru"
          ></textarea>        
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(field_731_kz)">
          (731) [kz]
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="field_731_kz"
          ></textarea>        
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (526) [ru]
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="field_526_ru"
          ></textarea>        
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (526) [kz]
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="field_526_kz"
          ></textarea>        
        </div>
      </div>

      <div class="user-info">
        <div class="user-info-header">
          (591)
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="field_591"
          ></textarea>        
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(field_510_511_short)">
          (510)(511) кратко
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="field_510_511_short"
          ></textarea>        
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(field_510_511)">
          (510)(511)
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="field_510_511"
          ></textarea>        
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredRelatedNulls(newPatentFile, currentPatentFile)">
          Изображение
        </div>
        <div v-if="newPatentFile!=null || currentPatentFile!=null" class="user-info-field">
          <div class="reset-file">
            <div @click="resetFile">[x]</div>
            <div class="file-name">{{ filename }}</div>
          </div>
        </div>
        <img v-if="newPatentFile" class="image-container" :src="getImage" :alt="filename" />
        <img v-if="currentPatentFile" class="image-container" :src="currentPatentFile" :alt="filename" />
        <div v-if="newPatentFile==null && currentPatentFile==null" class="user-info-field">
          <label for="files" class="add-file">Добавить файл</label>
          <input id="files" accept="image/*" @change="fileUploader" class="fileuploader" type="file">
        </div>
      </div>
      
      <br>

      <div class="add-item" @click="fireUpdate()">Изменить</div>      
      <div class="delete-item" @click="fireDelete()">Удалить</div>     

      <br>
    </div>
    <DynamicModal 
      :modalId="modal" 
      :ref="modal" 
    />   </div>

</template>


<script>
import { defineComponent } from 'vue';
import Menu from '../../../components/Menu.vue';
import DynamicModal from '../../../components/DynamicModal.vue';
import { updateEKSPTZPatent } from '../../../services/bulletin/requests';
import { ekspTzImageUpload } from '../../../services/bulletin/requests';
import { deleteEkspTZImage } from '../../../services/bulletin/requests';
import { deleteEkspTZPatent } from '../../../services/bulletin/requests';
import { getEkspTZPatentData } from '../../../services/bulletin/requests';
import { getTZPatentImage } from '../../../services/bulletin/requests';
import { isEmptyStr } from '../../../helpers/utils';
import { isPositiveInteger } from '../../../helpers/utils';
import { dateToStr } from '../../../helpers/utils';
import { getDate } from '../../../helpers/utils';
import { mapGetters } from 'vuex';
import Datepicker from 'vue3-datepicker'
import { ru } from 'date-fns/locale'
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  name: "EkspTzPatentView",

  components: {
    Menu,
    DynamicModal,
    Datepicker,
  },

  data(){
    return {
      modal: uuidv4(),
      id: null,
      loading: true,
      bull_num: 0,
      timestamp: '',
      data_source: '',
      izPatentData: {},
      izPatentLoading: true,

			pat_uid : null,
			pat_type_id : 4,

      publication_date : null,
      gos_date_11 : null,
			req_number_21 : null,
			req_date_22 : null,
			field_31 : null,

			field_32 : null,
			field_33 : null,
			field_731_ru : null,
			field_731_kz : null,
			field_526_ru : null,

			field_526_kz : null,
      field_591 : null,
			field_510_511_short : null,
			field_510_511 : null,

      newPatentFile : null,
      currentPatentFile : null,
      filename : null,            
    }
  },

  computed:{
    ...mapGetters(
      [
        "hasToken",
        'hasAdminPermission',
        'hasTrademarkApplicationsPublisherPermission',
      ]
    ),
    lang(){
      return ru
    },
    getImage(){
      return URL.createObjectURL(this.newPatentFile)
    }
  },

  methods: {
    checkRequiredNull(param){
      if (param === null){
        return 'required user-info-header'
      } else {
        return 'user-info-header'
      }
    },
    checkRequiredInt(param){
      if (param === null || !isPositiveInteger(param)){
        return 'required user-info-header'
      } else {
        return 'user-info-header'
      }
    },
    checkRequiredStr(param){
      if (param === null || param.trim() == ''){
        return 'required user-info-header'
      } else {
        return 'user-info-header'
      }
    },
    checkRequiredRelatedNulls(_params){
      const args = Array.prototype.slice.call(arguments);
      for (const item of args) {
        if (item !== null){
          
          return 'user-info-header'
        }
      }    
      
      return 'required user-info-header'
    },
    checkRequiredRelatedStr(_params){
      let notNullCounter = 0
      const args = Array.prototype.slice.call(arguments);
      for (const item of args) {
        if (item !== null && item.trim() !== ''){
          
          notNullCounter ++
        }
      }    
      
      if (notNullCounter == 0 || notNullCounter==args.length){
        return 'user-info-header'
      } 
      if (notNullCounter>0) {
        return 'required user-info-header'
      }
    },
    checkRequiredRelatedNullStr(paramsNull, paramStr){
      if (paramsNull === null && (paramStr === null || paramStr.trim()=='')){
        return 'required user-info-header'
      }
      return 'user-info-header'
    },    
    checkData(){
        if(!isPositiveInteger(this.pat_uid))  return false

        if (this.req_date_22 === null)
          return false

        if (this.newPatentFile === null && this.currentPatentFile === null)
          return false

        if (this.field_731_kz === null || this.field_731_kz.trim() == '')
          return false

        if (this.field_731_ru === null || this.field_731_ru.trim() == '')
          return false
        
        if (this.field_510_511 === null || this.field_510_511.trim() == '')
          return false

        if (this.field_510_511_short === null || this.field_510_511_short.trim() == '')
          return false

        if (!isEmptyStr(this.field_31) || !isEmptyStr(this.field_32) || !isEmptyStr(this.field_33)){
          if (this.field_31 === null || 
              this.field_32 === null || 
              this.field_33 === null || 
              this.field_31.trim() == '' || 
              this.field_32.trim() == '' || 
              this.field_33.trim() == ''
            )
            return false

        }    

      return true
    },     
    resetFile(){
      this.newPatentFile = null;
      this.currentPatentFile = null;
      this.filename = null;
    },
    fileUploader(e){
      this.currentPatentFile = null
      const files = e.target.files || e.dataTransfer.files;
      this.newPatentFile = files[0];
      this.filename = files[0].name;
    },
    doDelete(){
      deleteEkspTZPatent(this.id).then(() => { 
        this.$router.push("/home");
      })
    },
    fireDelete(){
      this.$refs[this.modal].fireModal(
        {
          header: `Удалить`, 
          text: `Вы уверены, что хотите удалить весь объект из бюллетня?`,
          isDanger: true,
          afterApprove: this.doDelete,
        }        
      )
    },
    doUpdate() {
      let payload = {
        pat_uid: this.pat_uid,
        pat_type_id: this.pat_type_id,

        publication_date: this.timestamp,
        gos_date_11 : this.gos_date_11!=null ? dateToStr(this.gos_date_11) : null,
        req_date_22 : this.req_date_22!=null ? dateToStr(this.req_date_22) : null,
        field_31 : this.field_31,

        field_32 : this.field_32,
        field_33 : this.field_33,        
        field_731_ru: this.field_731_ru,
        field_731_kz: this.field_731_kz,
        field_526_ru: this.field_526_ru,

        field_526_kz:this.field_526_kz,
        field_591: this.field_591,
        field_510_511_short: this.field_510_511_short,
        field_510_511: this.field_510_511,
      }
      console.log("add", payload)
      updateEKSPTZPatent(this.id, payload).then(() => {
        if (this.currentPatentFile==null){
          deleteEkspTZImage(this.id).then(()=> {
            ekspTzImageUpload(this.timestamp, this.pat_uid, this.req_number_21, this.newPatentFile).then(() => { this.$router.go() })
          })
          
        } else {
          this.$router.go()
        }
      }).catch((error) => {
        if (error.response.status == 400){
          console.log("error", error.response.data)
          this.$refs[this.modal].fireModal(
            {
              header: `Ошибка`, 
              text: `Вы допустили ошибку при заполнении объекта! ${error.message}`,
              isDanger: true,
              noAction: true
            }        
          )
        }        
        
      })

    },    
    fireUpdate(){
      if (!this.checkData()){
        this.$refs[this.modal].fireModal(
          {
            header: `Ошибка!`, 
            text: `Не все обязательные поля, помеченые красным цветом, заполнены правильно!`,
            isDanger: true,
            noAction: true,
          }        
        )
        return
      }
      this.$refs[this.modal].fireModal(
        {
          header: `Изменить патент в бюллетень за ${this.timestamp} под номером ${this.bull_num}`, 
          text: `Вы уверены, что хотите изменить этот объект?`,
          isDanger: false,
          afterApprove: this.doUpdate,
        }        
      )
    },
  },
  mounted () {
      var element = document.getElementById("top");
      var top = element.offsetTop;
      window.scrollTo(0, top);    
  },
  created() {
    if (!this.hasToken){
      this.$router.push("/login");
    }
    this.timestamp = this.$route.query.timestamp;
    this.bull_num = this.$route.query.bull_num;
    this.data_source = this.$route.query.data_source;
    this.req_number_21 = this.$route.query.req_number_21;

    if (!(this.hasAdminPermission || this.hasTrademarkApplicationsPublisherPermission)){
      this.$router.push("/home");
    }

    getEkspTZPatentData(this.$route.query.itemId, this.data_source).then((data) => {
      this.id = data.id

      this.pat_dby = this.timestamp
      this.pat_nby = this.bull_num

      this.pat_uid = data.pat_uid
      this.publication_date = this.timestamp

      this.gos_date_11  = data.gos_date_11!=null ? getDate(data.gos_date_11) : null
      this.field_181  = data.field_181!=null ? getDate(data.field_181) : null
      this.req_number_21 = data.req_number_21
      this.req_date_22  = data.req_date_22!=null ? getDate(data.req_date_22) : null
      this.field_31  = data.field_31

      this.field_32  = data.field_32
      this.field_33  = data.field_33        
      this.field_731_ru = data.field_731_ru
      this.field_731_kz = data.field_731_kz
      this.field_526_ru = data.field_526_ru

      this.field_526_kz = data.field_526_kz
      this.field_591 = data.field_591
      this.field_510_511_short = data.field_510_511_short
      this.field_510_511 = data.field_510_511
      getTZPatentImage(this.$route.query.itemId, this.data_source, true).then((data) => {
        this.currentPatentFile = `data:image/png;base64, ${data}`
      })

    })
  }
})
</script>


<style scoped>

.not-found-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.users {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.add-user  {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.centered {
  text-align: center;
}


.side-bar {
  min-height: 100vh;
  width: 300px;
  border-right: rgb(211, 205, 205) solid;
}

.workspace {
  min-height: 100vh;
  width: 800px;
}

.not-found-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 52px;
}

.w300 {
  min-width: 300px;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#8b9a9b;
  color: white;
}

.selectable {
  cursor: pointer;
}

.user-info {
  width: 100%;
  margin-left: 20px;
  margin-top: 15px;
}

.user-info-header {
  font-size: 11px;
  width: 100%;
}

.user-info-field {
  width: 100%;
  text-align: justify;
}

.w100 {
  width: 100%;
}

.tcentered {
  text-align: center;
}

.section-name {
  margin-left: 20px;
  margin-top: 10px;
  background-color: #009da4;
  color: white;
  width: 100%;
  padding: 10px;
}

.m4rem {
    margin: .4rem;
}
.hide {
  display: none;
}

.add-user-action {
  width: 220px;
}

.loading {
  text-align: center;
  margin: 10px;
}

.download-formula {
  cursor: pointer;
}

.download-formula:hover {
  font-weight: 600;
}

.bull-row{
  display: flex;
  width: 100%;
  margin-left: 20px;
  margin-top: 15px;
  padding-bottom: 10px;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: rgb(205 205 205) solid 1px;
}

.bull-item{
  text-align: justify;
}

.add-file {
  border: solid 1px gray;
  width: 220px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 2px;
  border-radius: 5px;
  background-color: white;
}

.add-file:hover {
  background-color: whitesmoke;
  cursor: pointer;
}

.add-item{
  margin: 20px;
  border: solid 1px white;
  color: white;
  width: 520px;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  background-color: #009da4;
}


.add-item:hover {
  cursor: pointer;
}

.delete-item {
  margin: 20px;
  border: solid 1px white;
  color: white;
  width: 520px;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  background-color: red;
}


.delete-item:hover {
  cursor: pointer;
}


.fileuploader {
  visibility:hidden;
}

.reset-file:hover{
  cursor: pointer;
}
.reset-file {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.file-name {
  margin-left: 20px;
}

.reset-date {
  display: flex;
}

.reset-date-action {
  margin-left: 5px;
  margin-top: 2px
}

.reset-date-action:hover {
  cursor: pointer;
}
.required {
  color: red;
}

.image-container {
  text-align: center;
  width: 400px;
  padding: 20px;
}
</style>
