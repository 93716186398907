<template>
      <div class="toc">
        <div>
          <div class="pub-info">
            {{ translate("published", language) }}: {{timestamp}} 
            <br>
            #{{bull_num}}
          </div>
          <div v-if='hasPatents' class="lvl1-links" @click="scrollMeTo('lvl1')">
            {{ translate("patent-info", language) }} 
          </div>
          <div class="lvl2-links">
            <div v-if='izPatentPublished' :class="menuClass('/access')"  @click="scrollMeTo('izo')">
              {{ izPatentPublished ? '' : ''}} {{ translate("iz-patent-info", language) }} 
            </div>      

            <div v-if='pmPatentPublished' :class="menuClass('/access')"  @click="scrollMeTo('pm')">
              {{ pmPatentPublished ? '' : ''}} {{ translate("pm-patent-info", language) }}
            </div>      

            <div v-if='poPatentPublished' :class="menuClass('/access')"  @click="scrollMeTo('po')">
              {{ poPatentPublished ? '' : ''}} {{ translate("po-patent-info", language) }}
            </div>      

            <div v-if='sdPatentPublished' :class="menuClass('/access')"  @click="scrollMeTo('sd-sr')">
              {{ sdPatentPublished ? '' : ''}} {{ translate("sd-patent-info", language) }}
            </div>      

            <div v-if='tzPatentPublished' :class="menuClass('/access')"  @click="scrollMeTo('tz')">
              {{ tzPatentPublished ? '' : ''}} {{ translate("tz-patent-info", language) }}
            </div>      

            <div v-if='nmptPatentPublished' :class="menuClass('/access')"  @click="scrollMeTo('nmpt')">
              {{ nmptPatentPublished ? '' : ''}} {{ translate("nmpt-patent-info", language) }}
            </div>      
            <div v-if='guPatentPublished' :class="menuClass('/access')"  @click="scrollMeTo('gu')">
              {{ guPatentPublished ? '' : ''}} {{ translate("gu-patent-info", language) }}
            </div>      

            <div v-if='izvPublished'>
              <div class="mainlink toc-item" @click="toggleSublinks('izv')">
                {{ izvPublished ? '' : ''}} {{ translate("izv-info", language) }}
              </div> 
              <div :class="izvSublinks">
                <div v-if="izv1_2" class="sublink toc-item" @click="scrollMeTo('pc4y')">
                  &#x25cf;&ensp;{{ translate("izv_12", language) }}
                </div>
                <div v-if="izvProdlIZ" class="sublink toc-item" @click="scrollMeTo('nd4a')">
                  &#x25cf;&ensp;{{ translate("izv_prodl_iz", language) }}
                </div>
                <div v-if="izvProdlPM" class="sublink toc-item" @click="scrollMeTo('nd4k')">
                  &#x25cf;&ensp;{{ translate("izv_prodl_pm", language) }}
                </div>
                <div v-if="izvProdlPO" class="sublink toc-item" @click="scrollMeTo('prodl-po')">
                  &#x25cf;&ensp;{{ translate("izv_prodl_po", language) }}
                </div>
                <div v-if="izvProdlSD" class="sublink toc-item" @click="scrollMeTo('prodl-sd')">
                  &#x25cf;&ensp;{{ translate("izv_prodl_sd", language) }}
                </div>
                <div v-if="izvProdlTZ" class="sublink toc-item" @click="scrollMeTo('prodl-tz')">
                  &#x25cf;&ensp;{{ translate("izv_prodl_tz", language) }}
                </div>
                <div v-if="izvProdlTZ" class="sublink toc-item" @click="scrollMeTo('prodl-tz-mktu')">
                  &#x25cf;&ensp;{{ translate("izv_prodl_tz_mktu", language) }}
                </div>
                <div v-if="izvProdlNmpt" class="sublink toc-item" @click="scrollMeTo('prodl-nmpt')">
                  &#x25cf;&ensp;{{ translate("izv_prodl_nmpt", language) }}
                </div>
                <div v-if="izvPrekIZ" class="sublink toc-item" @click="scrollMeTo('mm4a')">
                  &#x25cf;&ensp;{{ translate("izv_prekr_iz", language) }}
                </div>
                <div v-if="izvPrekPM" class="sublink toc-item" @click="scrollMeTo('mm4k')">
                  &#x25cf;&ensp;{{ translate("izv_prekr_pm", language) }}
                </div>
                <div v-if="izvPrekPO" class="sublink toc-item" @click="scrollMeTo('prekr-po')">
                  &#x25cf;&ensp;{{ translate("izv_prekr_po", language) }}
                </div>
                <div v-if="izvPrekSD" class="sublink toc-item" @click="scrollMeTo('prekr-sd')">
                  &#x25cf;&ensp;{{ translate("izv_prekr_sd", language) }}
                </div>
                <div v-if="izvVostIZ" class="sublink toc-item" @click="scrollMeTo('vost-iz')">
                  &#x25cf;&ensp;{{ translate("izv_vost_iz", language) }}
                </div>
                <div v-if="izvVostPM" class="sublink toc-item" @click="scrollMeTo('vost-pm')">
                  &#x25cf;&ensp;{{ translate("izv_vost_pm", language) }}
                </div>
                <div v-if="izvVostPO" class="sublink toc-item" @click="scrollMeTo('vost-po')">
                  &#x25cf;&ensp;{{ translate("izv_vost_po", language) }}
                </div>
                <div v-if="izvVostSD" class="sublink toc-item" @click="scrollMeTo('vost-sd')">
                  &#x25cf;&ensp;{{ translate("izv_vost_sd", language) }}
                </div>
                <div v-if="izvDubIZ" class="sublink toc-item" @click="scrollMeTo('dub-iz')">
                  &#x25cf;&ensp;{{ translate("izv_dub_iz", language) }}
                </div>
                <div v-if="izvDubPM" class="sublink toc-item" @click="scrollMeTo('dub-pm')">
                  &#x25cf;&ensp;{{ translate("izv_dub_pm", language) }}
                </div>
                <div v-if="izvDubPO" class="sublink toc-item" @click="scrollMeTo('dub-po')">
                  &#x25cf;&ensp;{{ translate("izv_dub_po", language) }}
                </div>
                <div v-if="izvDubNMPT" class="sublink toc-item" @click="scrollMeTo('dub-nmpt')">
                  &#x25cf;&ensp;{{ translate("izv_dub_nmpt", language) }}
                </div>
                <div v-if="izvDubSD" class="sublink toc-item" @click="scrollMeTo('dub-sd')">
                  &#x25cf;&ensp;{{ translate("izv_dub_sd", language) }}
                </div>
                <div v-if="izvDubTZ" class="sublink toc-item" @click="scrollMeTo('dub-tz')">
                  &#x25cf;&ensp;{{ translate("izv_dub_tz", language) }}
                </div>
                <div v-if="izvIZMOblIZ" class="sublink toc-item" @click="scrollMeTo('izv-izm-iz')">
                  &#x25cf;&ensp;{{ translate("izv_obl_iz", language) }}
                </div>
                <div v-if="izvIZMOblPM" class="sublink toc-item" @click="scrollMeTo('izv-izm-pm')">
                  &#x25cf;&ensp;{{ translate("izv_obl_pm", language) }}
                </div>
                <div v-if="izvIZMOblPO" class="sublink toc-item" @click="scrollMeTo('izv-izm-po')">
                  &#x25cf;&ensp;{{ translate("izv_obl_po", language) }}
                </div>
                <div v-if="izvIZMOblSD" class="sublink toc-item" @click="scrollMeTo('izv-izm-sd')">
                  &#x25cf;&ensp;{{ translate("izv_obl_sd", language) }}
                </div>
                <div v-if="izvIZMTZNameAddress" class="sublink toc-item" @click="scrollMeTo('izv-tz-na')">
                  &#x25cf;&ensp;{{ translate("izv_izm_name_address", language) }}
                </div>
                <div v-if="izvIZMTZAddress" class="sublink toc-item" @click="scrollMeTo('izv-tz-a')">
                  &#x25cf;&ensp;{{ translate("izv_izm_address", language) }}
                </div>
                <div v-if="izvIZMTZName" class="sublink toc-item" @click="scrollMeTo('izv-tz-n')">
                  &#x25cf;&ensp;{{ translate("izv_izm_name", language) }}
                </div>
                <div v-if="izvIZMMKTU" class="sublink toc-item" @click="scrollMeTo('izv-tz-mktu')">
                  &#x25cf;&ensp;{{ translate("izv_izm_mktu", language) }}
                </div>
                <div v-if="izvTK" class="sublink toc-item" @click="scrollMeTo('izv-tk')">
                  &#x25cf;&ensp;{{ translate("izv_tk", language) }}
                </div>
                <div v-if="izvRL" class="sublink toc-item" @click="scrollMeTo('izv-rl')">
                  &#x25cf;&ensp;{{ translate("izv_rl", language) }}
                </div>
                <div v-if="izvCancellation" class="sublink toc-item" @click="scrollMeTo('izv-cancellation')">
                  &#x25cf;&ensp;{{ translate("izv_cancel", language) }}
                </div>
                <div v-if="izvIZMDK" class="sublink toc-item" @click="scrollMeTo('izv-izm-dk')">
                  &#x25cf;&ensp;{{ translate("izv_dk", language) }}
                </div>
              </div>
            </div>      

            <div v-if='ukPublished'>
              <div class="mainlink toc-item"  @click="toggleSublinks('uk')">
                {{ ukPublished ? '' : ''}} {{ translate("uk-info", language) }}
              </div> 
              <div :class="ukSublinks">
                <div v-if="ptNumApplInvention" class="sublink toc-item" @click="scrollMeTo('uk-iz-z')">
                  &#x25cf;&ensp;{{ translate("uk-iz", language) }}
                </div>
                <div v-if="ptNumPatInvention" class="sublink toc-item" @click="scrollMeTo('uk-iz-pat')">
                  &#x25cf;&ensp;{{ translate("uk-iz-pat", language) }}
                </div>

                <div v-if="ptSysPatInvention" class="sublink toc-item" @click="scrollMeTo('uk-iz-mpk')">
                  &#x25cf;&ensp;{{ translate("uk-iz-sys", language) }}
                </div>
                <div v-if="ptAuthInvention" class="sublink toc-item" @click="scrollMeTo('uk-author-iz')">
                  &#x25cf;&ensp;{{ translate("uk-iz-auth", language) }}
                </div>
                <div v-if="ptOwnerInvention" class="sublink toc-item" @click="scrollMeTo('uk-obl-iz')">
                  &#x25cf;&ensp;{{ translate("uk-iz-obl", language) }}
                </div>

                <div v-if="ptNumApplUM" class="sublink toc-item" @click="scrollMeTo('uk-pm-z')">
                  &#x25cf;&ensp;{{ translate("uk-pm-num", language) }}
                </div>
                <div v-if="ptNumPatUM" class="sublink toc-item" @click="scrollMeTo('uk-pm-pat')">
                  &#x25cf;&ensp;{{ translate("uk-pm-num-pat", language) }}
                </div>
                <div v-if="ptSysPatUM" class="sublink toc-item" @click="scrollMeTo('uk-pm-mpk')">
                  &#x25cf;&ensp;{{ translate("uk-pm-sys", language) }}
                </div>
                <div v-if="ptAuthUM" class="sublink toc-item" @click="scrollMeTo('uk-author-pm')">
                  &#x25cf;&ensp;{{ translate("uk-pm-auth", language) }}
                </div>
                <div v-if="ptOwnerUM" class="sublink toc-item" @click="scrollMeTo('uk-obl-pm')">
                  &#x25cf;&ensp;{{ translate("uk-pm-obl", language) }}
                </div>

                <div v-if="ptNumApplID" class="sublink toc-item" @click="scrollMeTo('uk-po-z')">
                  &#x25cf;&ensp;{{ translate("uk-po-num", language) }}
                </div>
                <div v-if="ptNumPatID" class="sublink toc-item" @click="scrollMeTo('uk-po-pat')">
                  &#x25cf;&ensp;{{ translate("uk-po-num-pat", language) }}
                </div>
                <div v-if="ptSysPatID" class="sublink toc-item" @click="scrollMeTo('uk-po-mpk')">
                  &#x25cf;&ensp;{{ translate("uk-po-sys", language) }}
                </div>
                <div v-if="ptAuthID" class="sublink toc-item" @click="scrollMeTo('uk-author-po')">
                  &#x25cf;&ensp;{{ translate("uk-po-auth", language) }}
                </div>
                <div v-if="ptOwnerID" class="sublink toc-item" @click="scrollMeTo('uk-obl-po')">
                  &#x25cf;&ensp;{{ translate("uk-po-obl", language) }}
                </div>
                
                <div v-if="ptSysPatTM" class="sublink toc-item" @click="scrollMeTo('uk-tz-sys')">
                  &#x25cf;&ensp;{{ translate("uk-tz-sys", language) }}
                </div>
                <div v-if="ptNumApplTM" class="sublink toc-item" @click="scrollMeTo('uk-num-ztz')">
                  &#x25cf;&ensp;{{ translate("uk-tz-num", language) }}
                </div>
              </div>     

            </div>      
          </div>          
        </div>
        <div>
          <div v-if='hasEKSP' class="lvl1-links" @click="scrollMeTo('lvl2')">
            {{ translate("eksp-info", language) }}
          </div>
          <div class="lvl2-links">
            <div v-if='ekspTZPublished' :class="menuClass('/access')"  @click="scrollMeTo('eksp-tz')">
              {{ ekspTZPublished ? '' : ''}} {{ translate("eksp-tz-patent-info", language) }}
            </div>      
            <div v-if='ekspSDPublished' :class="menuClass('/access')"  @click="scrollMeTo('eksp-sd-sr')">
              {{ ekspSDPublished ? '' : ''}} {{ translate("eksp-sd-patent-info", language) }}
            </div>      
            <div v-if='ekspNMPTPublished' :class="menuClass('/access')"  @click="scrollMeTo('eksp-nmpt')">
              {{ ekspNMPTPublished ? '' : ''}} {{ translate("eksp-nmpt-patent-info", language) }}
            </div>      
            <div v-if='ekspGUPublished' :class="menuClass('/access')"  @click="scrollMeTo('eksp-gu')">
              {{ ekspGUPublished ? '' : ''}} {{ translate("eksp-gu-patent-info", language) }}
            </div>      


          </div>
        </div>
      </div>

</template>

<script>

import { defineComponent } from '@vue/runtime-core';
import { translate } from '../helpers/translator'



export default defineComponent({
  name: 'TableOfContents',

  data(){
    return {
      menuName: "",
      ukSublinksOn: false,
      izvSublinksOn: false,
    }
  },

  props: { 
    language:{
          type: String,
          default: 'ru',
        },   
    timestamp: {
          type: String,
          default: ""
        },  
    bull_num: {
          type: String,
          default: ""
        },  
    pmPatentPublished: {
          type: Boolean,
          default: false
        }, 
    poPatentPublished: {
          type: Boolean,
          default: false
        }, 
    sdPatentPublished: {
          type: Boolean,
          default: false
        }, 
    izPatentPublished: {
          type: Boolean,
          default: false
        }, 
    tzPatentPublished: {
          type: Boolean,
          default: false
        }, 
    izvPublished: {
          type: Boolean,
          default: false
        }, 
    ukPublished: {
          type: Boolean,
          default: false
        }, 
    ekspTZPublished: {
          type: Boolean,
          default: false
        }, 
    ekspSDPublished: {
          type: Boolean,
          default: false
        }, 
    ekspNMPTPublished: {
          type: Boolean,
          default: false
        }, 
    ekspGUPublished: {
          type: Boolean,
          default: false
        }, 
    nmptPatentPublished: {
          type: Boolean,
          default: false
        },           
    guPatentPublished: {
      type: Boolean,
      default: false
    },    

    ptNumApplInvention: {
        type: Boolean,
        default: false
      },  
    ptNumPatInvention: {
        type: Boolean,
        default: false
      },  
    ptSysPatInvention: {
        type: Boolean,
        default: false
      },  
    ptAuthInvention: {
        type: Boolean,
        default: false
      },  
    ptOwnerInvention: {
        type: Boolean,
        default: false
      },  
      ptNumApplUM: {
        type: Boolean,
        default: false
      },  
    ptNumPatUM: {
        type: Boolean,
        default: false
      },  
    ptSysPatUM: {
        type: Boolean,
        default: false
      },  
    ptAuthUM: {
        type: Boolean,
        default: false
      },  
    ptOwnerUM: {
        type: Boolean,
        default: false
      },  
      ptNumApplID: {
        type: Boolean,
        default: false
      },  
    ptNumPatID: {
        type: Boolean,
        default: false
      },  
    ptSysPatID: {
        type: Boolean,
        default: false
      },  
    ptAuthID: {
        type: Boolean,
        default: false
      },  
    ptOwnerID: {
        type: Boolean,
        default: false
      },        
      ptNumApplTM: {
        type: Boolean,
        default: false
      },  
    ptSysPatTM: {
        type: Boolean,
        default: false
      },  
      

    izv1_2: {
        type: Boolean,
        default: false
      },  
    izvProdlIZ: {
        type: Boolean,
        default: false
      },  
    izvProdlPO: {
        type: Boolean,
        default: false
      },  
    izvProdlPM: {
        type: Boolean,
        default: false
      },  
    izvProdlTZ: {
        type: Boolean,
        default: false
      },  
    izvProdlSD: {
        type: Boolean,
        default: false
      },  
    izvProdlNmpt: {
        type: Boolean,
        default: false
      },  
    izvPrekIZ: {
        type: Boolean,
        default: false
      },  
    izvPrekSD: {
        type: Boolean,
        default: false
      },  
    izvPrekPM: {
        type: Boolean,
        default: false
      },  
    izvPrekPO: {
        type: Boolean,
        default: false
      },  
    izvVostPO: {
        type: Boolean,
        default: false
      },  
    izvVostPM: {
        type: Boolean,
        default: false
      },  
    izvVostSD: {
        type: Boolean,
        default: false
      },  
    izvVostIZ: {
        type: Boolean,
        default: false
      },  
    izvDubIZ: {
        type: Boolean,
        default: false
      },  
    izvDubPO: {
        type: Boolean,
        default: false
      },  
    izvDubPM: {
        type: Boolean,
        default: false
      },  
    izvDubTZ: {
        type: Boolean,
        default: false
      },  
    izvDubSD: {
        type: Boolean,
        default: false
      },  
    izvIZMTZNameAddress: {
        type: Boolean,
        default: false
      },  
    izvIZMTZName: {
        type: Boolean,
        default: false
      },  
    izvIZMTZAddress: {
        type: Boolean,
        default: false
      },  
    izvIZMOblIZ: {
        type: Boolean,
        default: false
      },  
    izvIZMOblPM: {
        type: Boolean,
        default: false
      },  
    izvIZMOblPO: {
        type: Boolean,
        default: false
      },  
    izvIZMOblSD: {
        type: Boolean,
        default: false
      },  
    izvTK: {
        type: Boolean,
        default: false
      },  
    izvCancellation: {
        type: Boolean,
        default: false
      },  
    izvRL: {
        type: Boolean,
        default: false
      },  
    izvDubNMPT: {
        type: Boolean,
        default: false
      },  
    izvIZMMKTU: {
        type: Boolean,
        default: false
      },  
    izvIZMDK: {
        type: Boolean,
        default: false
      },             
  },

  computed: {
    hasPatents(){
      return this.izPatentPublished || this.pmPatentPublished || this.poPatentPublished || this.sdPatentPublished || this.tzPatentPublished || this.nmptPatentPublished || this.guPatentPublished
    },
    hasEKSP(){
      return this.ekspTZPublished || this.ekspGUPublished || this.ekspNMPTPublished || this.ekspSDPublished
    },
    ukSublinks(){
      return this.ukSublinksOn ? '' : 'hide'
    },
    izvSublinks(){
      return this.izvSublinksOn ? '' : 'hide'
    }

  },

  methods: {
    translate,
    toggleSublinks(target){
      if (target=='uk') {
        this.ukSublinksOn = !this.ukSublinksOn;
      }
      if (target=='izv') {
        this.izvSublinksOn = !this.izvSublinksOn;
      }
      this.scrollMeTo(target)

  },

    scrollMeTo(elementId) {
      var element = document.getElementById(elementId);
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    menuClass(routerPath){
      return this.$route.path === routerPath ? "toc-item toc-active-item" : "toc-item"
    }
  },

  mounted () {

  }  

})

</script>

<style scoped>

.toc {
  font-size: 12px;
}

.toc-item{
  padding: 5px;
}

.toc-item:hover{
  /* font-weight: 500; */
  background-color: rgb(181, 209, 218);
  cursor: pointer;
}


.toc-active-item{
  border-right: rgb(212, 108, 10) solid;
  background-color: rgb(238, 238, 238);
  font-weight: 500;
}

.toc-group .toc-item {
  margin-left: 10px;
}
.toc-group-header {
  text-transform: uppercase;
  color: gray;
  padding: 5px;
}

.sublink {
  font-size: 12px;
  text-align: justify;
  margin: 5px;
}

.mainlink {
  color: black;
}

.hide {
  display: none;
}

.lvl1-links {
  font-weight: 600;
  color: rgb(1, 113, 121);
  text-align: justify;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.lvl2-links {
  padding-left: 5px;
}

.pub-info {
  background-color: #009da4;
  color: white;
  padding: 10px;
  margin-right: 5px;
}
</style>
