<template>
  <div class="not-found-page">
    <div class="side-bar">
      <Menu />
    </div>
    <div class="workspace">
      <div id="top" class="section-name">MM4L Досрочное прекращение действия патентов на промышленный образец</div>
      <div class="bull-row">
        <div class="bull-item">
          <div class="user-info-header">
            Дата бюллетня
          </div>
          <div class="user-info-field">
            <div>{{ timestamp }}</div>
          </div>
        </div>

        <div class="bull-item">
          <div class="user-info-header">
            Номер бюллетня
          </div>
          <div class="user-info-field">
            <div>{{ bull_num }}</div>
          </div>
        </div>
        
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(gos_number_11)">
          (11) Номер ОД
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="23"
            v-model="gos_number_11"
          ></textarea>
        </div>        
      </div>

      <div class="user-info">
        <div :class="checkRequiredInt(pat_uid)">
          Идентификатор патента
        </div>
        <div class="user-info-field">
          <input type="text" v-model="pat_uid"/>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredStr(req_number_21)">
          (21)
        </div>
        <div class="user-info-field">
          <textarea
            rows="1" cols="50"
            v-model="req_number_21"
          ></textarea>
        </div>
      </div>

      <div class="user-info">
        <div :class="checkRequiredNull(datpdpat)">
          Дата досрочного прекращения
        </div>
        <div class="user-info-field">
          <Datepicker :locale="lang" inputFormat="yyyy-MM-dd" v-model="datpdpat" />
        </div>
      </div>

      <br>
      <div class="add-item" @click="fireAdd()">Добавить</div>      
      <br>

    </div>
    <DynamicModal 
      :modalId="modal" 
      :ref="modal" 
    />   </div>

</template>


<script>
import { defineComponent } from 'vue';
import Menu from '../../../../components/Menu.vue';
import DynamicModal from '../../../../components/DynamicModal.vue';
import { addIZVPrekPO } from '../../../../services/bulletin/requests';
// import { isEmptyStr } from '../../../helpers/utils';
import { isPositiveInteger } from '../../../../helpers/utils';
import { dateToStr } from '../../../../helpers/utils';
// import { getDate } from '../../../helpers/utils';

import { mapGetters } from 'vuex';
import Datepicker from 'vue3-datepicker'
import { ru } from 'date-fns/locale'
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  name: "IzvPrekPoView",

  components: {
    Menu,
    DynamicModal,
    Datepicker,
  },

  data(){
    return {
      modal: uuidv4(),
      loading: true,
      bull_num: 0,
      timestamp: '',
      data_source: '',
      gosnum: '',
      izPatentLoading: true,

			pat_uid : null,
			gos_number_11 : null,
			datpdpat : null,
      req_number_21: null,
    }
  },

  computed:{
    ...mapGetters(
      [
        "hasToken",
        'hasAdminPermission',
        'hasNoticePublisherPermission',
      ]
    ),
    lang(){
      return ru
    }


  },

  methods: {
    checkRequiredNull(param){
      if (param === null){
        return 'required user-info-header'
      } else {
        return 'user-info-header'
      }
    },
    checkRequiredInt(param){
      if (param === null || !isPositiveInteger(param)){
        return 'required user-info-header'
      } else {
        return 'user-info-header'
      }
    },
    checkRequiredStr(param){
      if (param === null || param.trim() == ''){
        return 'required user-info-header'
      } else {
        return 'user-info-header'
      }
    },
    checkRequiredRelatedStr(_params){
      let notNullCounter = 0
      const args = Array.prototype.slice.call(arguments);
      for (const item of args) {
        if (item !== null && item.trim() !== ''){
          
          notNullCounter ++
        }
      }    
      
      if (notNullCounter == 0 || notNullCounter==args.length){
        return 'user-info-header'
      } 
      if (notNullCounter>0) {
        return 'required user-info-header'
      }
    },
    checkRequiredRelatedNullStr(paramsNull, paramStr){
      if (paramsNull === null && (paramStr === null || paramStr.trim()=='')){
        return 'required user-info-header'
      }
      return 'user-info-header'
    },    
    checkData(){
        if(!isPositiveInteger(this.pat_uid))  return false

        if (this.gos_number_11 === null || this.gos_number_11.trim() == '')
          return false

        if (this.req_number_21 === null || this.req_number_21.trim() == '')
          return false

        if (this.datpdpat === null)
          return false
        
      return true
    },      

    doAdd() {
      let payload = {
        srw_dby: this.timestamp,
        srw_nby: this.bull_num,

        pat_uid: this.pat_uid,
        gos_number_11: this.gos_number_11,
        datpdpat : this.datpdpat!=null ? dateToStr(this.datpdpat) : null,
        req_number_21: this.req_number_21,
      }
      console.log("add", payload)
      addIZVPrekPO(payload).then(() => {
          this.$router.go()
      }).catch((error) => {
        if (error.response.status == 400){
          console.log("error", error.response.data)
          this.$refs[this.modal].fireModal(
            {
              header: `Ошибка`, 
              text: `Вы допустили ошибку при заполнении объекта! ${error.message}`,
              isDanger: true,
              noAction: true
            }        
          )
        }        
        
      })

    },    
    fireAdd(){
      if (!this.checkData()){
        this.$refs[this.modal].fireModal(
          {
            header: `Ошибка!`, 
            text: `Не все обязательные поля, помеченые красным цветом, заполнены правильно!`,
            isDanger: true,
            noAction: true,
          }        
        )
        return
      }

      this.$refs[this.modal].fireModal(
        {
          header: `Добавить патент в бюллетень за ${this.timestamp} под номером ${this.bull_num}`, 
          text: `Вы уверены, что хотите добавить этот объект?`,
          isDanger: false,
          afterApprove: this.doAdd,
        }        
      )
    },

  },

  mounted () {
      var element = document.getElementById("top");
      var top = element.offsetTop;
      window.scrollTo(0, top);    
  },
  created() {
    if (!this.hasToken){
      this.$router.push("/login");
    }
    this.timestamp = this.$route.query.timestamp;
    this.bull_num = this.$route.query.bull_num;
    this.data_source = this.$route.query.data_source;
    this.gosnum = this.$route.query.gosnum;

    if (!(this.hasAdminPermission || this.hasNoticePublisherPermission)){
      this.$router.push("/home");
    }
  }
})
</script>


<style scoped>

.not-found-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(237 240 242);
}

.users {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.add-user  {
  margin: 20px;
  text-align: justify;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.centered {
  text-align: center;
}


.side-bar {
  min-height: 100vh;
  width: 300px;
  border-right: rgb(211, 205, 205) solid;
}

.workspace {
  min-height: 100vh;
  width: 800px;
}

.not-found-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 52px;
}

.w300 {
  min-width: 300px;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even){background-color: #f2f2f2;}

tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color:#8b9a9b;
  color: white;
}

.selectable {
  cursor: pointer;
}

.user-info {
  width: 100%;
  margin-left: 20px;
  margin-top: 15px;
}

.user-info-header {
  font-size: 11px;
  width: 100%;
}

.user-info-field {
  width: 100%;
  text-align: justify;
}

.w100 {
  width: 100%;
}

.tcentered {
  text-align: center;
}

.section-name {
  margin-left: 20px;
  margin-top: 10px;
  background-color: #009da4;
  color: white;
  width: 100%;
  padding: 10px;
}

.m4rem {
    margin: .4rem;
}
.hide {
  display: none;
}

.add-user-action {
  width: 220px;
}

.loading {
  text-align: center;
  margin: 10px;
}

.download-formula {
  cursor: pointer;
}

.download-formula:hover {
  font-weight: 600;
}

.bull-row{
  display: flex;
  width: 100%;
  margin-left: 20px;
  margin-top: 15px;
  padding-bottom: 10px;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: rgb(205 205 205) solid 1px;
}

.bull-item{
  text-align: justify;
}


.add-file {
  border: solid 1px gray;
  width: 220px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 2px;
  border-radius: 5px;
  background-color: white;
}
.add-file:hover {
  background-color: whitesmoke;
  cursor: pointer;
}

.add-item{
  margin: 20px;
  border: solid 1px white;
  color: white;
  width: 520px;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
  background-color: #009da4;
}


.add-item:hover {
  cursor: pointer;
}

.fileuploader {
  visibility:hidden;
}

.reset-file:hover{
  cursor: pointer;
}
.reset-file {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.file-name {
  margin-left: 20px;
}

.reset-date {
  display: flex;
}

.reset-date-action {
  margin-left: 5px;
  margin-top: 2px
}

.reset-date-action:hover {
  cursor: pointer;
}
.required {
  color: red;
}

.image-container {
  text-align: center;
  width: 400px;
  padding: 20px;
}

</style>
