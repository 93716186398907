<template>
  <div>
    <router-view />
    <div id="modals"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  component: "App",
})
</script>

<style>

body {
  width: 100%;
  height: 100%;
}

</style>
