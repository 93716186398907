export function hasIntersection(first, second)
{
    var s = new Set(second)
    return first.filter(item => s.has(item)).length>0
}

export function translatePermission(permission_code)
{
    if (permission_code=="ADMIN"){
        return 'Администратор'
    }
    if (permission_code=="GUEST"){
        return 'Гость'
    } 
    if (permission_code=="INVANSIONS_PUBLISHER"){
        return 'Аналитик патентов на изобретения'
    }
    if (permission_code=="UTILITY_MODEL_PUBLISHER"){
        return 'Аналитик патентов на полезные модели'
    }
    if (permission_code=="INDUSTRIAL_DESIGNS_PUBLISHER"){
        return 'Аналитик патентов на промышленные образцы'
    }
    if (permission_code=="SELECTION_ACHIEVEMENTS_PUBLISHER"){
        return 'Аналитик патентов на селекционные достижения'
    }
    if (permission_code=="TRADEMARKS_PUBLISHER"){
        return 'Аналитик патентов на товарные знаки'
    }
    if (permission_code=="NMPT_PUBLISHER"){
        return 'Аналитик патентов на НМПТ'
    }
    if (permission_code=="GU_PUBLISHER"){
        return 'Аналитик патентов на ГУ'
    }
    if (permission_code=="NOTICES_PUBLISHER"){
        return 'Аналитик извещений'
    }
    if (permission_code=="POINTERS_PUBLISHER"){
        return 'Аналитик указателей'
    }
    if (permission_code=="TRADEMARK_APPLICATIONS_PUBLISHER"){
        return 'Аналитик заявок на товарные знаки'
    }
    if (permission_code=="SELECTION_ACHIEVEMENT_APPLICATIONIS_PUBLISHER"){
        return 'Аналитик заявок на селекционные достижения'
    }
    if (permission_code=="NMPT_APPLICATIONS_PUBLISHER"){
        return 'Аналитик заявок на НМПТ'
    }
    if (permission_code=="GU_APPLICATIONS_PUBLISHER"){
        return 'Аналитик заявок на ГУ'
    }

    return ''
}

export function dateToStr(dateValue){
    const offset = dateValue.getTimezoneOffset()
    dateValue = new Date(dateValue.getTime() - (offset*60*1000))
    return dateValue.toISOString().split('T')[0]
  }

export function isEmptyStr(str_value){
    return str_value == null || str_value.trim() == '' 
  }  

export function getDate(str_date){
    let dd = str_date.split('.')
    return new Date(dd[2], dd[1]-1, dd[0])
}

export function isPositiveInteger(paramValue) {
    if (paramValue == null){
        return false
    }
    
    if (paramValue.toString().includes('.')==true){
        return false
    }

    if (typeof paramValue == 'string') {
        const num = Number(paramValue);
  
        if (Number.isInteger(num) && num > 0) {
          return true;
        }
        return false;
    }

    if (typeof paramValue == 'number') {
        if (Number.isInteger(paramValue) && paramValue > 0) {
          return true;
        }
    }
    
  
    return false;
  }